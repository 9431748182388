import {
  SortableContext,
  SortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

type DNDSortableContextProps = {
  items: any[];
  strategy?: SortingStrategy;
  children?: React.ReactNode;
};

export const DNDSortableContext: React.FC<DNDSortableContextProps> = ({
  items,
  strategy = verticalListSortingStrategy,
  children,
}) => {
  return (
    <SortableContext items={items} strategy={strategy}>
      {children}
    </SortableContext>
  );
};
