import { DragOverlay } from '@dnd-kit/core';
import { TableProps } from 'antd';

import { DragIndexState } from './types/DragIndexState';

interface EntiresTableWithDNDOverlayProps {
  columns: TableProps['columns'];
  dragIndex: DragIndexState;
}

export const EntiresTableWithDNDOverlay: React.FC<
  EntiresTableWithDNDOverlayProps
> = ({ columns, dragIndex }) => {
  return (
    <DragOverlay>
      <th style={{ backgroundColor: 'gray', padding: 16 }}>
        {
          columns?.[columns.findIndex((i) => i.key === dragIndex.active)]
            ?.title as React.ReactNode
        }
      </th>
    </DragOverlay>
  );
};
