import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { Text } from '../../components/Text';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';

const Container = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${Spacing.m};

  & > div:nth-child(odd) {
    margin-inline-end: ${Spacing.s};
  }
`;

const WidgetWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  min-width: 40%;
  max-width: 49%;
  flex-grow: 1;
`;

const Widget = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  height: 100%;
  flex-grow: 1;
  max-height: 14rem;
  padding: 1rem;
  box-shadow: ${Shadow.SM};
  margin-bottom: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

const StyledText = styled(Text)`
  font-size: 1.2rem;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    font-size: 1.5rem;
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.darkerSub};
`;

export const AdminRoute: React.FC = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const stats = useAppSelector(adminSliceSelectors.selectAdminStats);

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  useEffect(() => {
    dispatch(adminActions.getAdminStats({ userId: user._id }));
  }, [dispatch, user]);

  return (
    <Flex flexDirection="column" flex={1}>
      <SubHeader title={t('admin.header.title')} />

      <Container>
        <WidgetWrapper>
          <Flex justifyContent="space-between" alignItems="center">
            <Title fontSize="l">{t('common.users')}</Title>

            <Link to={'/admin/users'}>
              <Flex alignItems="center" gap="s">
                <AtiraIcon icon={faSearch} color="main" />
                <Text color="main">{t('admin.users.btn')}</Text>
              </Flex>
            </Link>
          </Flex>

          <Widget>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.users.total')} `}
                <StyledSpan>{stats?.users.total}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.users.last_week')} `}
                <StyledSpan>{stats?.users.lastWeek}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />
              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.users.last_month')} `}
                <StyledSpan>{stats?.users.lastMonth}</StyledSpan>
              </StyledText>
            </Flex>
          </Widget>
        </WidgetWrapper>

        <WidgetWrapper>
          <Title fontSize="l">{t('common.forms')}</Title>
          <Widget>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.form.total')} `}
                <StyledSpan>{stats?.form.total}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.form.last_week')} `}
                <StyledSpan>{stats?.form.lastWeek}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />
              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.form.last_month')} `}
                <StyledSpan>{stats?.form.lastMonth}</StyledSpan>
              </StyledText>
            </Flex>
          </Widget>
        </WidgetWrapper>

        <WidgetWrapper>
          <Title fontSize="l">{t('common.entries')}</Title>
          <Widget>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.entry.total')} `}
                <StyledSpan>{stats?.entry.total}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />

              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.entry.last_week')} `}
                <StyledSpan>{stats?.entry.lastWeek}</StyledSpan>
              </StyledText>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" size="sm" />
              <StyledText color="main" wordBreak="break-word">
                {`${t('admin.entry.last_month')} `}
                <StyledSpan>{stats?.entry.lastMonth}</StyledSpan>
              </StyledText>
            </Flex>
          </Widget>
        </WidgetWrapper>
      </Container>
    </Flex>
  );
};
