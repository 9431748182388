import { Tooltip } from 'antd';
import React from 'react';

import { truncateString } from '../utils/String';
import { ParagraphProps, Text } from './Text';

type TextWithTruncatedProps = {
  children: string;
  textProps?: ParagraphProps;
  length?: number;
};

export const TextWithTruncated: React.FC<TextWithTruncatedProps> = ({
  children,
  textProps,
  length = 50,
}) => {
  if (children && children.length > length) {
    return (
      <Tooltip title={children}>
        <Text {...textProps}>{truncateString(children, 50)}</Text>
      </Tooltip>
    );
  }

  return <Text {...textProps}>{children}</Text>;
};
