import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { Text } from '../../components/Text';
import { ProductsCreateModal } from './components/ProductsCreateModal';

export const ProductsRoute: React.FC = () => {
  const { t } = useTranslation();

  const [productsCreateModalVisible, setProductsCreateModalVisible] =
    useState(false);
  return (
    <Flex flexDirection="column">
      <SubHeader
        onClick={() => {
          setProductsCreateModalVisible(true);
        }}
        title={t('products.header.title')}
        buttonTitle={t('common.create')}
        icon={faPlus}
      />
      <Flex justifyContent="center">
        <Text fontSize="xl" color="main">
          {t('products.header.des')}
        </Text>
      </Flex>

      <ProductsCreateModal
        isOpen={productsCreateModalVisible}
        onClose={() => setProductsCreateModalVisible(false)}
      />
    </Flex>
  );
};
