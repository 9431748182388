import { CollapseProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../ThemeContext';
import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { EntryKind } from '../../../../model/entry/types/EntryKind.enum';
import { LeadTask } from '../../../../model/task/LeadTask';
import { useEntriesContext } from '../../entries-context';
import { LeadTaskDetails } from './LeadTaskDetails';
import { Flex } from '../../../../components/Flex';
import { AtiraIcon } from '../../../../components/AtiraIcon';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../../../../components/Text';

interface LeadTasksReadTabProps {
  tasks: LeadTask[] | undefined;
  entryKind?: EntryKind;
}
export const LeadTasksReadTab: React.FC<LeadTasksReadTabProps> = ({
  tasks,
  entryKind,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const {
    setTask,
    setTasksReadVisible,
    setDeleteTaskVisible,
    setLeadTaskUpdateVisible,
    setEntry,
    setTaskDoneVisible
  } = useEntriesContext();

  const items: CollapseProps['items'] = useMemo(() => {
    if (tasks?.length) {
      const panelStyle: React.CSSProperties = {
        borderBottom: '1px solid black',
        backgroundColor: theme.sub,
        border: '0',
      };

      return tasks.map((task, index) => ({
        key: `${index + 1}`,
        label: <Flex justifyContent='space-between' alignItems='center'>
          {task.name}

          {task.done === true ?
            <Flex alignItems='center' gap='s'>
            <AtiraIcon icon={faCheck} size='lg' color='green' />
            <Text color='green'>{t('common.done')}</Text>
          </Flex>
            : null
          }
        </Flex>,
        children: task ? (
          <LeadTaskDetails
            setTaskDoneVisible={setTaskDoneVisible}
            key={task._id}
            setEntry={setEntry}
            leadTask={task}
            setTask={setTask}
            setLeadTaskReadVisible={setTasksReadVisible}
            setLeadTaskUpdateVisible={setLeadTaskUpdateVisible}
            setDeleteVisible={setDeleteTaskVisible}
          />
        ) : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [
    t,
    setTaskDoneVisible,
    setDeleteTaskVisible,
    setEntry,
    setLeadTaskUpdateVisible,
    setTasksReadVisible,
    setTask,
    tasks,
    theme,
  ]);

  if (!tasks) {
    return null;
  }

  return items.length ? (
    <AtiraCollapse items={items} accordion />
  ) : (
    <AtiraEmpty description={t('tasks.lead.empty')} />
  );
};
