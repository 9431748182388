export const standardDate = (
  createdAt: string | Date | number,
  showHour?: boolean,
) => {
  if (!createdAt) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  if (showHour) {
    options.hour = 'numeric';
    options.minute = '2-digit';
  }

  return new Date(createdAt).toLocaleDateString('en-GB', options);
};
