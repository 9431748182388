import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import i18n, { AppLangs } from '../i18n';

type DrawerProps = AntdDrawerProps;

const StyledAntdDrawer = styled(AntdDrawer)`
  .ant-drawer-close {
    color: ${({ theme }) => theme.textColor};
  }

  .ant-drawer-close:hover {
    color: ${({ theme }) => theme.gray};
  }
`;

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const lang = i18n.language as AppLangs;
  const { theme } = useTheme();

  return (
    <StyledAntdDrawer
      placement={lang === AppLangs.AR ? 'left' : 'right'}
      {...props}
      style={{ backgroundColor: theme.white, color: theme.textColor }}
    >
      {children}
    </StyledAntdDrawer>
  );
};
