import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { WarningModal } from '../../../components/WarningModal';
import { permissionActions } from '../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { userActions } from '../../../redux/user/user.slice';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { standardDate } from '../../../utils/Date';

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  cursor: pointer;
`;

const ActionsWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.l};
  margin-inline-end: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  font-size: 1.5rem;
`;

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

export const PermissionOrganizationMemberTab: React.FC = () => {
  const [leaveLoading, setLeaveLoading] = useState(false);
  const [leaveModalVisible, setLeaveModalVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onLeaveOrganization = async () => {
    try {
      setLeaveLoading(true);
      await dispatch(
        permissionActions.leaveOrganization({
          organizationId: user.organizationId!,
          userId,
        }),
      ).unwrap();
      await dispatch(userActions.getLoggedInUser({ userId })).unwrap();
      setLeaveModalVisible(false);
      AtiraToast.success(t('permission.member.leave.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLeaveLoading(false);
    }
  };

  return (
    <Container>
      <Flex flexDirection="column" gap="m">
        <Flex justifyContent="space-between">
          <Text>
            {t('permission.member.since', {
              date: standardDate(user.inviteInfo?.respondedToAt!),
              name: user.organization?.name,
            })}
          </Text>

          <ActionsWrapper>
            <Tooltip title={t('common.send_email')}>
              <StyledButton disabled icon={faEnvelope} />
            </Tooltip>

            <Tooltip title={t('common.leave')}>
              <StyledButton
                onClick={() => setLeaveModalVisible(true)}
                icon={faDoorOpen}
              />
            </Tooltip>
          </ActionsWrapper>
        </Flex>
        <Flex alignItems="center" gap="s">
          {user.organization?.picture ? (
            <UserProfileWrapper>
              <AtiraImage src={user.organization?.picture} />
            </UserProfileWrapper>
          ) : (
            <AtiraIcon icon={faCircleUser} size="3x" color="sub" />
          )}
          <Text fontWeight="bold">{user.organization?.name}</Text>
        </Flex>
      </Flex>
      <WarningModal
        isOpen={leaveModalVisible}
        title={t('premission.member.leave.modal_title', {
          name: user?.organization?.name,
        })}
        description={t('premission.member.leave.modal_description')}
        onConfirm={onLeaveOrganization}
        onClose={() => setLeaveModalVisible(false)}
        loading={leaveLoading}
        confirmButtonText={t('common.leave')}
        denyButtonText={t('common.cancel')}
      />
    </Container>
  );
};
