/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useMultiLangNavigate } from '../../../hooks/useMultiLangNavigate';
import { GenericTokenDto } from '../../../model/user/dto/GenericTokenDto';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { Spacing } from '../../../theme/Spacing';
import { RegisterWrapper } from '../RegisterWrapper';
import { SignupEmail } from './SignupEmail';
import { SignupToken } from './SignupToken';

const TogglePageButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  margin: 0;
  color: ${(props) => props.theme.main};
  padding: 0;
  font-size: 1rem;
`;

const ResponsiveFlex = styled(Flex)`
  @media (min-width: 360px) and (max-width: 990px) {
    padding: ${Spacing.m};
  }
`;

enum SIGNUP_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
}

export const Signup: React.FC = () => {
  const [step, setStep] = useState<SIGNUP_STEPS>(SIGNUP_STEPS.EMAIL);
  const formMethods = useForm<SignupDto & GenericTokenDto>({
    defaultValues: { email: '', token: '' },
  });

  const navigate = useMultiLangNavigate();
  const { t } = useTranslation();
  useDocumentTitle(`${t('app.name').toUpperCase()} - ${t('common.signup')}`);

  return (
    <RegisterWrapper>
      <ResponsiveFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <FormProvider {...formMethods}>
          {step === SIGNUP_STEPS.EMAIL ? (
            <SignupEmail updateStep={() => setStep(SIGNUP_STEPS.TOKEN)} />
          ) : (
            <SignupToken />
          )}
        </FormProvider>
      </ResponsiveFlex>

      <ResponsiveFlex gap="s" alignItems="center">
        <Text>{t('sign_in.has_account')}</Text>
        <TogglePageButton
          onClick={() => navigate('/login')}
          title={t('common.login')}
        />
      </ResponsiveFlex>
    </RegisterWrapper>
  );
};
