import React, { useEffect } from 'react';
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';

import { Text } from '../../../components/Text';
import { permissionActions } from '../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { InviteLandingPageModal } from '../components/modals/InviteLandingPageModal';

export const InviteLandingPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const inviteMode = searchParams.get('inviteMode');
  const inviteCode = searchParams.get('inviteCode');

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && inviteCode) {
      dispatch(permissionActions.getInviteByCode({ inviteCode, userId }));
      navigate('/dashboard', { replace: true });
    }
  }, [userId, inviteCode, dispatch, navigate]);

  if (!userId) {
    return (
      <InviteLandingPageModal inviteCode={inviteCode} inviteMode={inviteMode} />
    );
  }

  return null;
};
