import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const userState = (state: RootState) => state.user;

const selectLoggedInUserId = createSelector(
  userState,
  (state) => state.loggedInUserId,
);

const selectLoggedInUser = createSelector(
  userState,
  (state) => state.loggedInUser,
);

export const userSliceSelectors = { selectLoggedInUserId, selectLoggedInUser };
