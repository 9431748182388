import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { SearchUsersUserDto } from '../../../model/admin/dto/SearchUsersUserDto';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  padding: 0;
  margin: 0;
`;

type AdminSearchUserRowProps = {
  user: SearchUsersUserDto;
  setUser: ATVoidFunction<SearchUsersUserDto>;
  setDetailsModalVisible: ATVoidFunction<boolean>;
};

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  padding: ${Spacing.s};
  box-shadow: ${Shadow.MD};
  border-radius: ${Rounded.md};
`;

export const AdminSearchUserRow: React.FC<AdminSearchUserRowProps> = ({
  user,
  setUser,
  setDetailsModalVisible,
}) => {
  const onShowDetails = () => {
    setDetailsModalVisible(true);
    setUser(user);
  };
  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between" width={'100%'}>
        <Flex flexDirection="column" gap="s">
          <Text fontWeight={'bold'}>{user.user?.name}</Text>
          <Text>{user.user?.email}</Text>
        </Flex>

        <StyledButton
          icon={faCircleInfo}
          iconWidth="2xl"
          onClick={onShowDetails}
        />
      </Flex>
    </Container>
  );
};
