import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const appState = (state: RootState) => state.app;

const selectSideMenuCollapsed = createSelector(
  appState,
  (state) => state.sideMenuCollapsed,
);

export const appSliceSelectors = {
  selectSideMenuCollapsed,
};
