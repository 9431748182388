import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

import { Drawer } from '../../../components/Drawer';
import { WarningModal } from '../../../components/WarningModal';
import { KanbanTask } from '../../../model/kanban/KanbanTask';
import { PageMeta } from '../../../model/meta/PageMeta';
import { ContactTask } from '../../../model/task/ContactTask';
import { LeadTask } from '../../../model/task/LeadTask';
import { TaskKind } from '../../../model/task/types/TaskKind.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { taskSliceSelectors } from '../../../redux/task/task.selector';
import { taskActions } from '../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { truncateString } from '../../../utils/String';
import { ContactTaskDetails } from '../../entries/components/contact-tasks/ContactTaskDetails';
import { ContactTaskUpdateDrawer } from '../../entries/components/contact-tasks/ContactTaskUpdateDrawer';
import { LeadTaskDetails } from '../../entries/components/lead-tasks/LeadTaskDetails';
import { LeadTaskUpdateDrawer } from '../../entries/components/lead-tasks/LeadTaskUpdateDrawer';
import { KanbanTaskDetails } from '../../kanban/components/kanban-task/KanbanTaskDetails';
import { KanbanTaskUpdateDrawer } from '../../kanban/components/kanban-task/KanbanTaskUpdateDrawer';
import { useTaskContext } from '../task-context';

const StyledTasksReadDrawer = styled(Drawer)`
  .ant-drawer-mask {
    z-index: 999 !important;
  }
`;

const StyledWarningModal = styled(WarningModal)`
  z-index: 10000000 !important;
`;

export const TasksDrawers: React.FC = () => {
  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);
  const [taskDoneLoading, setTaskDoneLoading] = useState(false);

  const {
    task,
    setTask,
    //
    leadTasksReadVisibile,
    setLeadTasksReadVisible,
    //
    setDeleteTaskVisible,
    deleteTaskVisible,
    //
    leadTaskUpdateVisibile,
    setLeadTaskUpdateVisible,
    //
    contactTasksReadVisible,
    setContactTasksReadVisible,
    contactTaskUpdateVisible,
    setContactTaskUpdateVisible,
    //
    kanbanTasksReadVisible,
    setKanbanTasksReadVisible,
    kanbanTaskUpdateVisible,
    setKanbanTaskUpdateVisible,
    //
    taskDoneVisible,
    setTaskDoneVisible,
  } = useTaskContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const taskPage = useAppSelector(taskSliceSelectors.selectTasksPage);
  const taskPageSize = useAppSelector(taskSliceSelectors.selectTasksPageSize);

  const onCloseLeadTaskUpdateReadDrawer = async () => {
    dispatch(taskActions.getLeadTasks({ userId, meta: PageMeta.create() }));
    setLeadTaskUpdateVisible(false);
  };

  const onCloseContactTaskUpdateReadDrawer = async () => {
    dispatch(taskActions.getContactTasks({ userId, meta: PageMeta.create() }));
    setContactTaskUpdateVisible(false);
  };

  const onCloseKanbanTaskUpdateReadDrawer = async () => {
    try {
      await dispatch(
        taskActions.getKanbanTasks({ userId, meta: PageMeta.create() }),
      ).unwrap();
      setKanbanTaskUpdateVisible(false);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const deleteTask = async () => {
    try {
      setDeleteTaskLoading(true);

      if (task?.kind === TaskKind.LEAD) {
        await dispatch(
          entryActions.deleteLeadTask({ taskId: task?._id!, userId }),
        ).unwrap();
      } else if (task?.kind === TaskKind.CONTACT) {
        await dispatch(
          entryActions.deleteContactTask({ taskId: task?._id!, userId }),
        ).unwrap();
      } else if (task?.kind === TaskKind.KANBAN) {
        await dispatch(
          kanbanActions.deleteKanbanTask({ userId, taskId: task?._id! }),
        ).unwrap();
      }

      await dispatch(
        taskActions.getAllTasks({
          userId,
          meta: { page: taskPage - 1, count: taskPageSize },
        }),
      ).unwrap();

      setDeleteTaskVisible(false);

      AtiraToast.success(t('tasks.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDeleteTaskLoading(false);
    }
  };

  const onTaskDone = async () => {
    try {
      setTaskDoneLoading(true);

      if (task?.kind === TaskKind.LEAD) {
        await dispatch(
          entryActions.updateLeadTask({
            userId,
            ownerId: userId,
            taskId: task._id,
            done: true,
          }),
        ).unwrap();
      } else if (task?.kind === TaskKind.CONTACT) {
        await dispatch(
          entryActions.updateContactTask({
            userId,
            ownerId: userId,
            taskId: task!._id,
            done: true,
          }),
        ).unwrap();
      } else {
        await dispatch(
          kanbanActions.updateKanbanTask({
            userId,
            taskId: task!._id,
            done: true,
          }),
        ).unwrap();
      }

      if (task?.kind === TaskKind.LEAD || task?.kind === TaskKind.CONTACT) {
        const updatedEntry = await dispatch(
          entryActions.getEntryById({
            userId,
            entryId: (task as LeadTask | ContactTask).entryId,
          }),
        ).unwrap();

        if (task?.kind === TaskKind.LEAD) {
          setTask(updatedEntry.leadTasks?.find((x) => x._id === task._id)!);
        } else {
          setTask(updatedEntry.contactTasks?.find((x) => x._id === task._id)!);
        }
      } else {
        const updatedKanbanCard = await dispatch(
          kanbanActions.getKanbanCardById({
            cardId: (task as KanbanTask).kanbanCardId,
            userId,
          }),
        ).unwrap();

        setTask(
          updatedKanbanCard.kanbanTasks?.find((x) => x._id === task!._id)!,
        );
      }

      setTaskDoneVisible(false);

      AtiraToast.success(t('tasks.done.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setTaskDoneLoading(false);
    }
  };

  return (
    <Fragment>
      <StyledTasksReadDrawer
        title={t('common.task_preview')}
        open={leadTasksReadVisibile}
        onClose={() => setLeadTasksReadVisible(false)}
      >
        <LeadTaskDetails
          setTaskDoneVisible={setTaskDoneVisible}
          leadTask={task as LeadTask}
          setTask={setTask}
          setLeadTaskReadVisible={setLeadTasksReadVisible}
          setLeadTaskUpdateVisible={setLeadTaskUpdateVisible}
          setDeleteVisible={setDeleteTaskVisible}
        />
      </StyledTasksReadDrawer>

      <LeadTaskUpdateDrawer
        leadTask={task as LeadTask}
        isOpen={leadTaskUpdateVisibile}
        onClose={onCloseLeadTaskUpdateReadDrawer}
      />

      <StyledTasksReadDrawer
        zIndex={1000}
        title={t('common.task_preview')}
        open={contactTasksReadVisible}
        onClose={() => setContactTasksReadVisible(false)}
      >
        <ContactTaskDetails
          setTaskDoneVisible={setTaskDoneVisible}
          contactTask={task as ContactTask}
          setTask={setTask}
          setContactTaskReadVisible={setContactTasksReadVisible}
          setContactTaskUpdateVisible={setContactTaskUpdateVisible}
          setDeleteVisible={setDeleteTaskVisible}
        />
      </StyledTasksReadDrawer>

      <ContactTaskUpdateDrawer
        contactTask={task as ContactTask}
        isOpen={contactTaskUpdateVisible}
        onClose={onCloseContactTaskUpdateReadDrawer}
      />

      <StyledTasksReadDrawer
        title={t('common.task_preview')}
        open={kanbanTasksReadVisible}
        onClose={() => setKanbanTasksReadVisible(false)}
      >
        <KanbanTaskDetails
          setTaskDoneVisible={setTaskDoneVisible}
          kanbanTask={task as KanbanTask}
          setTask={setTask}
          setKanbanTaskReadVisible={setKanbanTasksReadVisible}
          setKanbanTaskUpdateVisible={setKanbanTaskUpdateVisible}
          setDeleteVisible={setDeleteTaskVisible}
        />
      </StyledTasksReadDrawer>

      <KanbanTaskUpdateDrawer
        kanbanTask={task as KanbanTask}
        isOpen={kanbanTaskUpdateVisible}
        onClose={onCloseKanbanTaskUpdateReadDrawer}
      />

      <StyledWarningModal
        isOpen={deleteTaskVisible}
        loading={deleteTaskLoading}
        onConfirm={deleteTask}
        onClose={() => setDeleteTaskVisible(false)}
        title={t('tasks.delete.warning.title', {
          task_name: truncateString(task?.name || '', 26),
        })}
        description={t('tasks.delete.warning.description')}
      />

      <StyledWarningModal
        isOpen={taskDoneVisible}
        onClose={() => setTaskDoneVisible(false)}
        title={t('tasks.done.modal.title')}
        description={t('tasks.done.modal.description')}
        onConfirm={onTaskDone}
        loading={taskDoneLoading}
      />
    </Fragment>
  );
};
