import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { ThemeType } from '../theme/Theme';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Spinner } from './Spinner';

interface StyledButtonProps {
  color?: keyof ThemeType;
  backgroundColor?: keyof ThemeType;
  border?: string;
  align?: string;
  fontSize?: string;
  width?: string;
  textAlign?: 'start' | 'center' | 'end';
  padding?: string;
  borderRadius?: keyof typeof Rounded;
  height?: string;
  margin?: string;
  hover?: ButtonProps['hover'];
  hoverColor?: ButtonProps['hoverColor'];
  hoverBackgroundColor?: ButtonProps['hoverBackgroundColor'];
}

const StyledButton = styled.button<StyledButtonProps>`
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme['white']};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme[props.backgroundColor]
      : props.theme['main']};
  padding: ${(props) => props.padding || `${Spacing.s} ${Spacing.l}`};
  border-radius: ${(props) => props.borderRadius || Rounded.md};
  border: ${(props) => props.border || 'none'};
  margin: ${(props) => props.margin || `${Spacing.m} ${Spacing.o}`};
  text-align: ${(props) => props.align};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: ${(props) => props.textAlign};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: ${(props) => props.height};
  transition: all 0.3s;
  font-family: inherit;
  user-select: none;

  ${({ hover, theme, hoverColor, hoverBackgroundColor }) =>
    hover &&
    `&:hover { background-color: ${theme[hoverBackgroundColor || 'lightMain']};
      color: ${theme[hoverColor || 'white']}
    }`};
`;

export interface ButtonProps {
  icon?: IconProp;
  color?: keyof ThemeType;
  backgroundColor?: keyof ThemeType;
  onClick?: (e?: React.MouseEvent) => void;
  children?: React.ReactNode;
  title?: string;
  border?: string;
  align?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  textAlign?: StyledButtonProps['textAlign'];
  padding?: string;
  borderRadius?: keyof typeof Rounded;
  disabled?: boolean;
  loading?: boolean;
  margin?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  hoverColor?: keyof ThemeType;
  iconWidth?: SizeProp;
  hoverBackgroundColor?: keyof ThemeType;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  ...props
}) => {
  const localOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.disabled || loading) {
      return;
    }

    props.onClick?.(e);
  };

  if (loading) {
    return (
      <StyledButton {...props} onClick={localOnClick}>
        <Spinner />
      </StyledButton>
    );
  }

  if (props.title) {
    return (
      <StyledButton {...props} onClick={localOnClick}>
        {props.title}
      </StyledButton>
    );
  }

  if (props.icon) {
    return (
      <StyledButton {...props} onClick={localOnClick}>
        <Flex
          gap={children ? 's' : 'o'}
          justifyContent="center"
          alignItems="center"
        >
          <AtiraIcon icon={props.icon} size={props.iconWidth} />

          {children || props.title}
        </Flex>
      </StyledButton>
    );
  }

  return (
    <StyledButton {...props} onClick={localOnClick}>
      {children}
    </StyledButton>
  );
};
