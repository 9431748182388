import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { Dropdown, MenuProps } from 'antd';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { SortableChild } from '../../../components/dnd/SortableChild';
import i18n, { AppLangs } from '../../../i18n';
import { KanbanColumn as KanbanColumnModel } from '../../../model/kanban/KanbanColumn';
import { UpdateKanbanColumnDto } from '../../../model/kanban/dto/UpdateKanbanColumnDto';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { useKanbanContext } from '../kanban-context';
import { KanbanCard } from './KanbanCard';

const StyledKanbanColumn = styled(Flex)`
  flex-direction: column;
  gap: 0.75rem;
  width: 18rem;
  height: 100%;
  margin-inline-end: ${Spacing.m};
`;

const ColumnHeader = styled(Flex)<{ isDefault: boolean }>`
  border: 2px solid
    ${({ isDefault, theme }) => (isDefault ? theme.gray : theme.main)};
  flex-direction: column;
  gap: 0.1rem;
  padding: ${Spacing.s};
  border-radius: ${Rounded.xl};
  background-color: ${(props) => props.theme.white};
  cursor: grab;
`;

const KanbanCardsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  padding: ${Spacing.s} ${Spacing.s} 0 ${Spacing.s};
  background-color: ${(props) => props.theme.lightgray};
  border: 2px solid ${(props) => props.theme.lightgray};
  border-radius: ${Rounded.xl};
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const KanbanCradsInnerWrapper = styled(Flex)<{ canAddCard: boolean }>`
  height: ${({ canAddCard }) => (canAddCard ? 'calc(100% - 3rem)' : '100%')};
  flex-direction: column;
  gap: ${Spacing.s};
  overflow-y: scroll;
  border-radius: ${Rounded.xl} ${Rounded.xl} ${Rounded.none} ${Rounded.none};

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AddKanbanCardButton = styled(Button)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  border-radius: ${Rounded.none};
  margin: 0;
  background-color: ${(props) => props.theme.lightgray};
  text-align: start;
  display: flex;
  align-items: center;
  gap: ${Spacing.m};
`;

const ColumnHeaderTitleInput = styled(Input)`
  font-size: 1.2rem;
  font-weight: bold;
  border: ${({ readOnly, theme }) =>
    readOnly ? '0' : `1px solid ${theme.main}`} !important;
  transition: all 0.2s;
  padding: ${({ readOnly }) => (readOnly ? '0' : Spacing.s)} !important;
  cursor: ${({ readOnly }) => (readOnly ? 'grab' : 'normal')};
`;

const ColumnNameEditButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const MenuButton = styled(Button)`
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  padding: 0;
`;

const MenuActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  justify-content: space-between;
  margin: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  padding: 0.2rem;
  width: 100%;
`;

const CancelRenameColumnButton = styled(ColumnNameEditButton)``;

type KanbanColumnProps = {
  column: KanbanColumnModel;
  onMoveRight?: VoidFunction;
  onMoveLeft?: VoidFunction;
  isLast?: boolean;
};

export const KanbanColumn: React.FC<KanbanColumnProps> = ({
  column,
  onMoveLeft,
  onMoveRight,
  isLast,
}) => {
  const [canUpdateColName, setCanUpdateColName] = useState(false);
  const [updateColumnNameLoading, setUpdateColumnNameLoading] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const defaultKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateKanbanColumnDto>({
    mode: 'all',
    defaultValues: {
      name: column.name,
      userId,
      columnId: column._id,
      kanbanId: defaultKanban?._id,
    },
  });

  const { setCreateDrawerVisible } = useKanbanContext();

  const dispatch = useAppDispatch();

  const onStartEditName = () => {
    setCanUpdateColName(true);
    if (ref.current) {
      ref.current.focus();
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: 'rename',
          label: (
            <MenuActionButton onClick={onStartEditName}>
              {t('common.rename')}
              <AtiraIcon icon={faPencil} />
            </MenuActionButton>
          ),
        },
      ],
    },
    {
      key: 'sperator',
      type: 'divider',
    },
    {
      key: 'g2',
      type: 'group',
      children: [
        {
          key: '1',
          disabled: isLast === true,
          label: (
            <MenuActionButton onClick={onMoveRight}>
              {t('deals.column.move_right')}
              <AtiraIcon
                icon={
                  i18n.language === AppLangs.AR ? faArrowLeft : faArrowRight
                }
              />
            </MenuActionButton>
          ),
        },
        {
          key: '2',
          disabled: column.order === 0,
          label: (
            <MenuActionButton onClick={onMoveLeft}>
              {t('deals.column.move_left')}
              <AtiraIcon
                icon={
                  i18n.language === AppLangs.AR ? faArrowRight : faArrowLeft
                }
              />
            </MenuActionButton>
          ),
        },
      ].filter((item) => !item.disabled),
    },
  ];

  const canAddCard = column.default !== true;

  const totalAmount = column.cards
    ?.map((card) => Number(card.amount))
    .reduce((total, acc) => total + acc, 0);

  const onCancelEditName = () => {
    setCanUpdateColName(false);
    setValue('name', column.name);
    if (ref.current) {
      ref.current.blur();
    }
  };

  const onEditColumnName = async () => {
    try {
      const dto = getValues();

      if (dto.name !== column.name) {
        setUpdateColumnNameLoading(true);
        await dispatch(kanbanActions.updateKanbanColumnById(dto)).unwrap();
      }

      AtiraToast.success(t('kanban.column.title.update.onsucess'));

      setCanUpdateColName(false);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateColumnNameLoading(false);
    }
  };

  return (
    <StyledKanbanColumn>
      <SortableChild id={column._id} key={column._id}>
        <ColumnHeader isDefault={column.default === true}>
          <Flex alignItems="center" width={'100%'} gap="s">
            <Controller
              control={control}
              name="name"
              rules={{ required: true, minLength: 3, maxLength: Lengths.NAME }}
              render={({ field: { value, onChange } }) => (
                <ColumnHeaderTitleInput
                  readOnly={!canUpdateColName}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />

            {canUpdateColName ? (
              <Flex alignItems="center" gap="s">
                <ColumnNameEditButton
                  loading={updateColumnNameLoading}
                  icon={faSave}
                  iconWidth="lg"
                  onClick={handleSubmit(onEditColumnName)}
                  disabled={errors.name ? true : false}
                />

                <CancelRenameColumnButton
                  icon={faXmark}
                  iconWidth="lg"
                  onClick={onCancelEditName}
                />
              </Flex>
            ) : (
              <Dropdown menu={{ items }} trigger={['click']}>
                <MenuButton icon={faEllipsis} iconWidth="xl" />
              </Dropdown>
            )}
          </Flex>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={'100%'}
          >
            <Text align="center">
              {column.cards?.length} {t('common.deals')}
            </Text>
            <Text>{totalAmount}$</Text>
          </Flex>
        </ColumnHeader>
      </SortableChild>

      <KanbanCardsWrapper height={'100%'}>
        <KanbanCradsInnerWrapper canAddCard={canAddCard}>
          {column.cards?.map((c) => (
            <KanbanCard card={c} column={column} key={c._id} />
          ))}
        </KanbanCradsInnerWrapper>

        {canAddCard ? (
          <AddKanbanCardButton onClick={() => setCreateDrawerVisible(true)}>
            <AtiraIcon icon={faPlus} color="darkerSub" size="2x" />
            <Text color="darkerSub" fontSize="m">
              {t('common.add')}
            </Text>
          </AddKanbanCardButton>
        ) : null}
      </KanbanCardsWrapper>
    </StyledKanbanColumn>
  );
};
