import { createSlice } from '@reduxjs/toolkit';

import { TemplateAxios } from '../../axios/template/template.axios';
import { Template } from '../../model/dto/TemplateDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from './../user/user.slice';

interface TemplateReducer {
  // TODO Migrate to adapter
  templates: Template[];
}

const initialState = Object.freeze<TemplateReducer>({ templates: [] });

const getTemplates = AtiraThunk<Template[], void>(`/template/get`, () =>
  TemplateAxios.getTemplate(),
);

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getTemplates.fulfilled, (state, action) => {
      const data = action.payload;
      state.templates = data;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const templateActions = {
  getTemplates,
};

export default templateSlice.reducer;
