import { Select } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { CreateEntryDto } from '../../../model/entry/dto/CreateNewEntryDto';
import { FormInput } from '../../../model/form/FormInput';
import { InputTypes } from '../../../model/form/types/InputTypes.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { formActions } from '../../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const SaveButton = styled(Button)`
  width: 7rem;
  height: 3rem;
  margin: 1rem auto;
  font-size: 1.3rem;
`;

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: ${Spacing.m};
  width: 90%;
`;

type EntryCreateModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  showSelect?: boolean;
};

export const EntryCreateModal: React.FC<EntryCreateModalProps> = ({
  isOpen,
  onClose,
  showSelect = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const currentForm =
    useAppSelector(formSliceSelectors.selectCurrentForm) ||
    forms.find((form) => form.orphan);

  const [createEntryLoading, setCreateEntryLoading] = useState(false);

  const formMethods = useForm<CreateEntryDto>({
    defaultValues: {},
  });
  const { control, getValues, handleSubmit, reset } = formMethods;

  const onFormSelectChange = (formId: string) => {
    const _form = forms.find((f) => f._id === formId)!;

    if (_form) {
      dispatch(formActions.setCurrentForm(_form));
    }
  };

  const getInputPattern = (inputName?: string) => {
    switch (inputName) {
      case 'name':
        return Regexes.NAME_REGEX;
      case 'email':
        return Regexes.EMAIL_REGEX;
      case 'phone':
        return Regexes.PHONE_REGEX;
      default:
        return /.*?/;
    }
  };

  const getPatternErrorMessage = (inputName?: string) => {
    switch (inputName) {
      case 'name':
        return t('common.invalid.name');
      case 'email':
        return t('common.invalid.email');
      case 'phone':
        return t('common.invalid.phone');
      default:
        return '';
    }
  };

  const onEntrySubmit = async () => {
    try {
      setCreateEntryLoading(true);

      const dto = getValues();

      currentForm?.inputs?.forEach((input) => {
        if (
          input.type.toLowerCase() === InputTypes.DATE.toLowerCase() &&
          dto[input.name!]
        ) {
          dto[input.name!] = new Date(dto[input.name!]);
        }
      });

      await dispatch(
        entryActions.createNewEntry({
          ...dto,
          formId: currentForm?._id!,
          orphan: currentForm?.orphan === true,
          ownerId: userId,
        } as CreateEntryDto),
      ).unwrap();

      await Promise.all([
        dispatch(
          entryActions.getLatestEntries({
            userId,
            meta: { count: 10, page: 0 },
          }),
        ).unwrap(),
        dispatch(formActions.getMyForms({ userId })).unwrap(),
      ]);

      onClose();
      reset({});

      AtiraToast.success(t('entries.entry.create.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateEntryLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('entries.new_entry')}
      content={{ maxHeight: '80vh' }}
      key={currentForm?._id}
    >
      <Container>
        {currentForm?.orphan ? (
          <Text color="gray" align="center">
            {t('entries.create.entry_modal.without_form_note')}
          </Text>
        ) : null}

        {currentForm?.title && currentForm.orphan !== true ? (
          <Text align="center" color="gray">
            {t('entries.create_entry.modal.form_title')}
            <strong style={{ padding: '0 0.3rem' }}>{currentForm.title}</strong>
          </Text>
        ) : null}

        {showSelect ? (
          <Flex flexDirection="column" gap="s">
            <Select
              defaultValue={
                currentForm?.orphan
                  ? t('forms.manual_entry')
                  : currentForm?.title
              }
              placeholder={t('common.dropdown.select')}
              options={forms.map((f) => ({
                value: f._id,
                label: f.orphan ? t('forms.manual_entry') : f.title,
              }))}
              onChange={onFormSelectChange}
              style={{ height: '3rem', width: '100%' }}
            />
          </Flex>
        ) : null}

        {currentForm?.inputs?.length ? (
          <Flex flexDirection="column" gap="m">
            {currentForm.inputs?.map((input: Partial<FormInput>) => (
              <Controller
                name={input.name!}
                control={control}
                rules={{
                  required: {
                    value: input.settings?.required === true,
                    message: t('common.this_field_required'),
                  },
                  maxLength: input.settings?.maxChar,
                  pattern: {
                    value: getInputPattern(input.name || ''),
                    message: getPatternErrorMessage(input.name || ''),
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  input.type?.toLowerCase() ===
                  InputTypes.SELECT.toLowerCase() ? (
                    <DropDown
                      required={input.settings?.required === true}
                      title={input.label}
                      options={input.options?.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      onChange={onChange}
                      value={value}
                      height="2.5rem"
                      valid={!Boolean(error?.message?.length)}
                      errorMessage={error?.message || ''}
                      style={{ fontSize: '1.2rem' }}
                    />
                  ) : (
                    <Input
                      type={input.type?.toLowerCase()}
                      title={input.label}
                      name={input.name}
                      value={
                        input.type?.toLowerCase() ===
                        InputTypes.DATE.toLowerCase()
                          ? value
                          : value || ''
                      }
                      onChangeDate={onChange}
                      onChange={(e) =>
                        onChange(
                          input.type?.toLowerCase() ===
                            InputTypes.NUMBER?.toLowerCase()
                            ? e.currentTarget.value.replace(/[^\d+]/g, '')
                            : e.currentTarget.value,
                        )
                      }
                      height="2.5rem"
                      padding="0 0.5rem"
                      valid={!Boolean(error?.message?.length)}
                      errorMessage={error?.message || ''}
                      style={{ fontSize: '1.2rem' }}
                      required={input.settings?.required === true}
                      maxLength={input.settings?.maxChar}
                    />
                  )
                }
                key={input._id}
              />
            ))}
          </Flex>
        ) : null}

        <SaveButton
          onClick={handleSubmit(onEntrySubmit)}
          loading={createEntryLoading}
        >
          {t('common.save')}
        </SaveButton>
      </Container>
    </Modal>
  );
};
