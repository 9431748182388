import { PageDto } from './PageDto';
import { PageResponseDto } from './PageResponseDto';

export class PageMeta {
  // eslint-disable-next-line
  constructor(arg: PageDto) {}

  static next<T>(arg: PageResponseDto<T[]>): PageResponseDto<T[]> {
    return {
      data: arg.data,
      meta: {
        ...arg.meta,
        page: arg.meta.page + 1,
        hasNext: arg.data.length >= arg.meta.count,
      },
    };
  }

  static create(arg: PageDto = { count: 20, page: 0 }) {
    return arg;
  }
}
