/* eslint-disable */
export enum Spacing {
  o = '0',
  s = '0.5rem',
  m = '1rem',
  l = '1.5rem',
  xl = '2rem',
  xxl = '3rem',
  xxxl = '5rem',
}
