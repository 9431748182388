import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../../components/AtiraDatePicker';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { KanbanTask } from '../../../../model/kanban/KanbanTask';
import { UpdateKanbanTaskDto } from '../../../../model/kanban/dto/UpdateKanbanTaskDto';
import { kanbanActions } from '../../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { comparePayloads } from '../../../../utils/ComparePayloads';

const SubmitButton = styled(Button)`
  height: 2.3rem;
  width: 50%;
`;

interface KanbanTaskUpdateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanTask: KanbanTask | null;
}

export const KanbanTaskUpdateDrawer: React.FC<KanbanTaskUpdateDrawerProps> = ({
  isOpen,
  onClose,
  kanbanTask,
}) => {
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);

  const { t } = useTranslation();
  const { control, getValues, handleSubmit, reset, watch } =
    useForm<UpdateKanbanTaskDto>();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const taskName = watch('name');

  const onSubmit = async () => {
    try {
      setUpdateButtonLoading(true);
      const dto = getValues()

      const { taskId, ...modifiableDto } = dto;

      const payload = comparePayloads(
        kanbanTask!,
        modifiableDto,
      ) as UpdateKanbanTaskDto;

      if (!isEmpty(payload)) {
        payload.taskId = taskId;
        payload.userId = userId;

        await dispatch(kanbanActions.updateKanbanTask(payload)).unwrap();
        await dispatch(
          kanbanActions.getKanbanCardById({
            cardId: kanbanTask?.kanbanCardId!,
            userId,
          }),
        ).unwrap();
      }

      onClose();

      AtiraToast.success(t('kanban.task.update_success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateButtonLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && kanbanTask) {
      reset({
        name: kanbanTask.name || '',
        description: kanbanTask?.description || '',
        reminder: kanbanTask?.reminder ? new Date(kanbanTask?.reminder) : null,
        userId,
        taskId: kanbanTask._id || '',
      });
    }
  }, [isOpen, kanbanTask, reset, userId]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={`${t('common.task_update')}: ${kanbanTask?.name}`}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <Input
              title={t('common.task_name')}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              title={t('common.description')}
            />
          )}
        />

        <Controller
          control={control}
          name="reminder"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker
              value={value || null}
              onChange={(date) => onChange(date)}
              title={t('common.remind_date')}
            />
          )}
        />

        <SubmitButton
          disabled={!taskName || taskName.length < 3}
          title={t('common.update')}
          loading={updateButtonLoading}
          onClick={handleSubmit(onSubmit)}
        />
      </Flex>
    </Drawer>
  );
};
