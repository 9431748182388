/* eslint-disable no-nested-ternary */
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import { PermissionsInviteMembersDto } from '../../../model/permissions/dto/PermissionsInviteMembersDto';
import { permissionSliceSelectors } from '../../../redux/permission/permission.selector';
import { useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { PendingMember } from '../components/invited-members/PendingMember';
import { MemberInviteModal } from '../components/modals/MemberInviteModal';
import { usePermissionsContext } from '../permissions-context';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  margin: 0;
  padding: ${Spacing.s};
  font-size: 1rem;
`;

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `2px solid ${theme.main}`};
  margin-bottom: ${Spacing.xl};
  padding-bottom: ${Spacing.s};
`;

export const PermissionsInvitesTab: React.FC = () => {
  const { t } = useTranslation();
  const formMethods = useForm<PermissionsInviteMembersDto>();
  const { inviteMemberModalVisible, setInviteMemberModalVisible } =
    usePermissionsContext();

  const invites = useAppSelector(permissionSliceSelectors.selectInvitedMembers);
  const loading = useAppSelector(
    permissionSliceSelectors.selectedInvitedMembersLoading,
  );

  return (
    <FormProvider {...formMethods}>
      <Container>
        <Header>
          <Text fontWeight={'bold'} fontSize="xm">
            {t('permissions.total.members', { count: [].length })}
          </Text>

          <HeaderButton onClick={() => setInviteMemberModalVisible(true)}>
            <AtiraIcon icon={faPlus} color="white" />

            {t('permission.add_member')}
          </HeaderButton>
        </Header>

        <Flex flexDirection="column" gap="m">
          {loading && !invites?.length ? (
            <SpinnerFullScreen />
          ) : !invites.length ? (
            <AtiraEmpty description={t('permissions.invites.empty')} />
          ) : (
            invites.map((invite) => (
              <PendingMember invite={invite} key={invite._id} />
            ))
          )}
        </Flex>

        <MemberInviteModal
          isOpen={inviteMemberModalVisible}
          onClose={() => setInviteMemberModalVisible(false)}
        />
      </Container>
    </FormProvider>
  );
};
