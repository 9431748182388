import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowsUpDownLeftRight } from '@fortawesome/free-solid-svg-icons/faArrowsUpDownLeftRight';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../AtiraIcon';
import { Flex } from '../Flex';

const StyledContainer = styled(Flex)`
  width: 100%;
  position: relative;
`;

const StyledDraggable = styled.div`
  position: absolute;
  &:hover {
    cursor: grab;
  }
`;

type SortableChildProps = {
  id: string;
  children?: React.ReactNode;
  showDragIcon?: boolean;
  dragIconStyle?: object;
  iconSize?: SizeProp;
  disabled?: boolean;
  style?: React.CSSProperties;
};

export const SortableChild: React.FC<SortableChildProps> = ({
  id,
  children,
  showDragIcon = false,
  dragIconStyle,
  iconSize = '1x',
  disabled = false,
  style,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const localStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  if (showDragIcon) {
    return (
      <StyledContainer ref={setNodeRef} style={{ ...localStyle, ...style }}>
        <Flex flex={1} width={'100%'}>
          {children}
        </Flex>
        <StyledDraggable {...attributes} {...listeners} style={dragIconStyle}>
          <AtiraIcon
            color="main"
            icon={faArrowsUpDownLeftRight}
            size={iconSize}
          />
        </StyledDraggable>
      </StyledContainer>
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={{ ...style, ...localStyle }}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};
