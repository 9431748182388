import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { Flex } from '../Flex';
import { Text } from '../Text';

const Container = styled(Flex)`
  background-color: #eee;
  flex-direction: column;
  gap: ${Spacing.m};
  align-items: center;
  justify-content: center;

  padding-inline: ${Spacing.s};
  padding-block: ${Spacing.xl};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: calc(${Spacing.xxxl} * 1.3);
    padding-block: ${Spacing.xxl};
  }
`;

const Title = styled(Text)`
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
`;

const CallToAction = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.main};
  font-style: italic;
  font-weight: bold;
`;

export const GenericGetStartedSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('common.get_started_today')}</Title>

      <Text fontSize="xm" align="center">
        <StyledSpan>{`${t('common.signup_today')} `}</StyledSpan>

        {t('use_cases.signup.paragraph')}
      </Text>

      <CallToAction target="_blank" to="mailto:sales@atiragrup.com">
        {t('common.contact_sales')}
      </CallToAction>
    </Container>
  );
};
