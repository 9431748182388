import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { SearchUsersUserDto } from '../../../model/admin/dto/SearchUsersUserDto';

const StyledSpan = styled.span`
  font-weight: bold;
`;

type SearchUsersDetailsModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  user?: SearchUsersUserDto | null;
};

export const AdminSearchUsersDetailsModal: React.FC<
  SearchUsersDetailsModalProps
> = ({ isOpen = false, onClose, user }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('admin.user.modal.title', {
        name: user?.user.name || user?.user.email,
      })}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" gap="l" width={'100%'}>
        <Text fontSize="xm">
          <StyledSpan> {`${t('common.entries')}: `}</StyledSpan>
          {user?.entriesCount}
        </Text>

        <Text fontSize="xm">
          <StyledSpan> {`${t('common.forms')}: `}</StyledSpan>
          {user?.formsCount}
        </Text>
      </Flex>
    </Modal>
  );
};
