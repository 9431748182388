import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { GoogleButton } from '../../../components/logins/GoogleButton';
import { SignupDto } from '../../../model/user/dto/SignupDto';
import { LoginWithGoogleReturnPath } from '../../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { Regexes } from '../../../utils/String';

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2.3rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: ${Spacing.s};
  transition: border 0.2s;
  font-size: 1rem;

  &:focus {
    border: 1px solid #86b7fe !important;
  }
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledGoogleButton = styled(GoogleButton)`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  height: 2.3rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.transparent};
  transition: all 0.2s;

  &:hover {
    border: 1px solid #c6c7c8;
    background-color: ${(props) => props.theme.darkSub};
  }
`;

const StyledHr = styled.hr`
  background-color: ${(props) => props.theme.darkerSub};
  height: 1px;
  border: 0;
  opacity: 1;
  width: 100%;
`;

type SignupEmailProps = {
  updateStep: VoidFunction;
};

export const SignupEmail: React.FC<SignupEmailProps> = ({ updateStep }) => {
  const { t } = useTranslation();

  const [signupLoading, setSignupLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues } = useForm<SignupDto>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      phone: '',
      company: '',
      website: '',
    },
  });

  const onNext = async () => {
    try {
      setSignupLoading(true);
      await dispatch(userActions.signup(getValues())).unwrap();
      AtiraToast.success(t('signup.verification_sent_message'));
      updateStep();
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setSignupLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={'100%'}
      gap="s"
    >
      <Controller
        name="name"
        rules={{
          required: true,
          minLength: {
            value: 3,
            message: t('signup.error.user_name.short_message'),
          },
          maxLength: {
            value: 18,
            message: t('signup.error.user_name.long_message'),
          },
          pattern: Regexes.NAME_REGEX,
        }}
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.name')}
            type="name"
            value={value}
            placeholder={t('common.name')}
            onChange={onChange}
            id="name"
            valid={isEmpty(errors.name)}
            errorMessage={errors.name?.message || ''}
            required
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        rules={{
          required: true,
          pattern: Regexes.EMAIL_REGEX,
        }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.email')}
            type="email"
            value={value}
            placeholder={t('common.email')}
            onChange={onChange}
            id="email"
            valid={isEmpty(errors.email)}
            errorMessage={errors.email?.message || t('signup.error.email')}
            required
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.password')}
            placeholder={t('common.password')}
            type="password"
            value={value}
            onChange={onChange}
            id="password"
            valid={isEmpty(errors.password)}
            required
            errorMessage={
              errors.password?.message || t('signup.error.password')
            }
          />
        )}
      />

      <Controller
        name="phone"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.phone')}
            placeholder={t('common.phone')}
            type="phone"
            value={value}
            onChange={onChange}
            id="phone"
            valid={isEmpty(errors.phone)}
          />
        )}
      />

      <Controller
        name="company"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.company')}
            placeholder={t('common.company')}
            type="Company"
            value={value}
            onChange={onChange}
            id="Company"
            valid={isEmpty(errors.company)}
          />
        )}
      />

      <Controller
        name="website"
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.website')}
            placeholder={t('common.website')}
            type="website"
            value={value}
            onChange={onChange}
            id="website"
          />
        )}
      />
      <Flex flexDirection="column" gap="m" width={'100%'} marginTop="m">
        <StyledButton
          loading={signupLoading}
          onClick={handleSubmit(onNext, (e) => {
            console.log('invalid form submission', e);
          })}
          title={t('common.signup')}
        />

        <StyledHr />
        <StyledGoogleButton returnPath={LoginWithGoogleReturnPath.SIGNUP}>
          {t('sign_up_with_google')}
        </StyledGoogleButton>
        <StyledHr />
      </Flex>
    </Flex>
  );
};
