export enum PermissionInviteStatus {
  // When a permission invite is created and no invitee account exists
  CREATED = 'CREATED',
  // When a permission invite is pending with an invitee account existing
  PENDING = 'PENDING',
  // When a permission invite is accepted
  ACCEPTED = 'ACCEPTED',
  // When a permission invite is rejected
  REJECTED = 'REJECTED',
}
