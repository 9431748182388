import { RouteObject } from 'react-router-dom';

import i18n, { AppLangs } from '../i18n';

const currentLang = i18n.language;

export function makeMultiLangRouter(router: RouteObject[]) {
  return router.map((route) => {
    if (route.path) {
      return {
        ...route,
        path: `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${route.path}`,
      };
    }

    if (route.children) {
      return {
        ...route,
        children: route.children.map((child) => {
          if (child.path !== '/') {
            return {
              ...child,
              path: `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${child.path}`,
            };
          }

          return child;
        }),
      };
    }

    return route;
  });
}
