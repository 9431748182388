export interface ThemeType {
  purple: string;
  darkerPurple: string;
  lighterPurple: string;
  darkPink: string;
  green: string;
  white: string;
  black: string;
  lightBlack: string;
  gray: string;
  lightergray: string;
  lightgray: string;
  transparent: string;
  blackTransparent: string;
  darkBlackTransparent: string;
  blue: string;
  red: string;
  orange: string;
  //
  main: string;
  sub: string;
  darkSub: string;
  darkerSub: string;
  darkMain: string;
  lightMain: string;
  lightSub: string;
  textColor: string;
  darkTextColor: string;
  accent: string;
  lightAccent: string;
}
/* eslint-disable */
export const Theme: ThemeType = {
  purple: '#3f0d53',
  darkerPurple: '#2a073c',
  lighterPurple: '#5d1b75 ',
  darkPink: '#803588',
  green: '#4cbb17',
  white: '#fff',
  black: '#000',
  lightBlack: '#222',
  gray: 'gray',
  lightergray: '#c6c6c6',
  lightgray: '#d5d5d5',
  transparent: 'rgba(0,0,0,0)',
  blackTransparent: '#00000080',
  darkBlackTransparent: '#000000E6',
  blue: '#0000ff',
  red: '#ff0000',
  orange: '#ffa500',

  main: '#803588',
  sub: '#ebebeb',
  darkSub: '#d6d6d6',
  darkerSub: '#929292',
  darkMain: ' #e8eaea',
  lightMain: ' #934599',
  lightSub: '#f8f9fa',
  textColor: '#000',
  darkTextColor: '#000',
  accent: '#5369a9',
  lightAccent: '#697ebc',
};
/* eslint-disable */
export const DarkTheme: ThemeType = {
  purple: '#3f0d53',
  darkerPurple: '#2a073c',
  lighterPurple: '#5d1b75 ',
  darkPink: '#803588',
  green: '#4cbb17',
  white: '#000',
  black: '#fff',
  lightBlack: '#ccc',
  gray: 'gray',
  lightergray: '#c6c6c6',
  lightgray: '#d5d5d5',
  transparent: 'rgba(0,0,0,0)',
  blackTransparent: '#00000080',
  darkBlackTransparent: '#000000E6',
  blue: '#0000ff',
  red: '#ff0000',
  orange: '#ffa500',

  main: '#803588',
  sub: '#2b2b2b',
  darkSub: '#1c1c1c',
  darkerSub: '#929292',
  darkMain: '#4b0a52',
  lightMain: '#d63d8c',
  lightSub: '#1f1f1f',
  textColor: '#FFFFFF',
  darkTextColor: '#333333',
  accent: '#8a9bc5',
  lightAccent: '#9bb3d6',
};

export const YellowTheme: ThemeType = {
  purple: '#f7c43b',
  darkerPurple: '#f0a91c',
  lighterPurple: '#fef4a3',
  darkPink: '#f2a44f',
  green: '#4cbb17',
  white: '#fff',
  black: '#000',
  lightBlack: '#222',
  gray: 'gray',
  lightergray: '#c6c6c6',
  lightgray: '#d5d5d5',
  transparent: 'rgba(0,0,0,0)',
  blackTransparent: '#00000080',
  darkBlackTransparent: '#000000E6',
  blue: '#0000ff',
  red: '#ff0000',
  orange: '#ffa500',

  main: '#f7c43b',
  sub: '#f0f0f0',
  darkSub: '#d9d9d9',
  darkerSub: '#b3b3b3',
  darkMain: '#f4b528',
  lightMain: '#f9e089',
  lightSub: '#fcfcfc',
  textColor: '#000',
  darkTextColor: '#333',
  accent: '#f7c43b',
  lightAccent: '#f2b83b',
};
