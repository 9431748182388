import { Tabs, TabsProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const StyledTabs = styled(Tabs)`
  padding-inline: ${Spacing.m};

  .ant-tabs-nav-wrap {
    @media (max-width: ${Breakpoints.DESKTOP}) {
      width: 0;
    }
  }

  .ant-tabs-tab {
    color: ${({ theme }) => theme.main} !important;
    padding-inline: ${Spacing.s};
    border-radius: ${Rounded.sm};
    transition: all 0.2s !important;
    margin-inline: 0 !important;
    border-radius: ${Rounded.md};
    border: ${({ theme }) => `1px solid ${theme.main}`};
    padding: ${Spacing.s};
  }

  .ant-tabs-tab-btn {
    transition: all 0.2s !important;
    user-select: none;
    font-size: 1.25rem;
    line-height: normal !important;
  }

  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-btn:focus:not(:focus-visible) {
    color: ${({ theme }) => theme.white} !important;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    color: ${({ theme }) => theme.white} !important;
    background-color: ${({ theme }) => theme.lightMain} !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: none !important;
  }

  .ant-tabs-nav-list {
    gap: ${Spacing.m};
  }

  .ant-tabs-nav-list::after,
  .ant-tabs-nav .ant-tabs-nav-wrap::after {
    display: none !important;
  }

  .ant-tabs-nav {
    border: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.white} !important;
  }

  .ant-tabs-tab-active:hover,
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.white} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: inherit !important;
  }

  //this fixes flickering issue
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

interface AtiraTabsProps extends TabsProps {
  items: TabsProps['items'];
  className?: string;
}

export const AtiraTabs: React.FC<AtiraTabsProps> = ({
  className,
  items,
  ...props
}) => {
  return <StyledTabs className={className} items={items} {...props} animated />;
};
