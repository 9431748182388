import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DropDown } from '../../../components/DropDown';
import { TableHeader } from '../../../components/table/TableHeader';
import { GetLatestEntriesFilterDto } from '../../../model/entry/dto/GetLatestEntriesFilterDto';
import { SearchEntryDto } from '../../../model/entry/dto/SearchEntryDto';
import { ContactTaskPriority } from '../../../model/entry/types/ContactTaskPriority.enum';
import { EntryFilterRange } from '../../../model/entry/types/EntryFilterRange.enum';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { Form } from '../../../model/form/Form';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Breakpoints } from '../../../theme/Breakpoints';
import { AtiraToast } from '../../../utils/AtiraToast';
import { EntryTableSettingsDrawer } from './EntryTableSettingsDrawer';

const StyledDropDown = styled(DropDown)`
  flex: 1;
  min-width: 7.5rem;
  max-width: 7.5rem;

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    min-width: 10rem;
    max-width: 10rem;
  }

  .ant-select .ant-select-selector {
    border: 2px solid ${(props) => props.theme.transparent} !important;
  }
`;

export const EntryTableHeader: React.FC = () => {
  const [tableSettingsModalVisible, setTableSettingsModalVisible] =
    useState(false);

  const { getValues, setValue, control } = useFormContext<
    GetLatestEntriesFilterDto | SearchEntryDto
  >();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const entriesPageSize = useAppSelector(
    entrySliceSelectors.selectEntriesTablePageSize,
  );
  const entriesPage = useAppSelector(
    entrySliceSelectors.selectEntriesTablePage,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onFetchEntries = () =>
    dispatch(
      entryActions.getLatestEntries({
        userId,
        meta: {
          count: entriesPageSize,
          page: entriesPage - 1,
        },
        ...omitBy(getValues(), isNil),
      }),
    );

  const onSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue('keyword', target.value);
    debouncedSearch();
  };

  const onFilter = async () => {
    try {
      await onFetchEntries();
    } catch (e: any) {
      AtiraToast.apiError(e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async () => {
      try {
        const keyword = getValues('keyword');
        if (keyword) {
          await dispatch(
            entryActions.searchEntries({
              keyword: getValues('keyword'),
              userId,
            }),
          ).unwrap();
        } else {
          onFetchEntries();
        }
      } catch (e: any) {
        AtiraToast.apiError(e);
        console.log(e);
      }
    }, 1000),
    [dispatch, getValues, userId],
  );

  return (
    <Fragment>
      <TableHeader
        onSettings={() => setTableSettingsModalVisible(true)}
        onFetch={onFetchEntries}
        onFetchLoading={entriesLoading}
        onSearch={onSearch}
        filters={
          <Fragment>
            <Controller
              control={control}
              name="kind"
              render={({ field: { onChange, value } }) => (
                <StyledDropDown
                  options={[
                    {
                      label: capitalize(t('entries.table.entry.All')),
                      value: null,
                    },
                    {
                      label: capitalize(t('entries.table.entry.CONTACT')),
                      value: EntryKind.CONTACT,
                    },
                    {
                      label: capitalize(t('entries.table.entry.LEAD')),
                      value: EntryKind.LEAD,
                    },
                    {
                      label: capitalize(t('entries.table.entry.JUNK')),
                      value: EntryKind.JUNK,
                    },
                  ]}
                  value={{
                    label: !value
                      ? t('entries.table.entry.All')
                      : t(`entries.table.entry.${value}`),
                    value: value || t('common.all'),
                  }}
                  onChange={(e) => {
                    onChange(e);
                    onFilter();
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="formId"
              render={({ field: { onChange, value } }) => (
                <StyledDropDown
                  options={[
                    {
                      title: t('entries.table.entry.filters.forms.all'),
                      _id: null,
                    } as unknown as Form,
                    ...forms,
                  ].map((f: Form) => ({
                    label: f.title,
                    value: f._id,
                  }))}
                  value={{
                    label:
                      forms.find(({ _id }) => _id === value)?.title ||
                      t('entries.table.entry.filters.forms.all'),
                    value,
                  }}
                  onChange={(e) => {
                    onChange(e);
                    onFilter();
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="range"
              render={({ field: { onChange, value } }) => (
                <StyledDropDown
                  options={[
                    {
                      label: t('entries.table.entry.filters.time.all'),
                      value: null,
                    },
                    {
                      label: t('entries.table.entry.filters.time.today'),
                      value: EntryFilterRange.TODAY,
                    },
                    {
                      label: t('entries.table.entry.filters.time.yesterday'),
                      value: EntryFilterRange.YESTERDAY,
                    },
                    {
                      label: t('entries.table.entry.filters.time.last_week'),
                      value: EntryFilterRange.LAST_WEEK,
                    },
                    {
                      label: t('entries.table.entry.filters.time.last_month'),
                      value: EntryFilterRange.LAST_MONTH,
                    },
                  ]}
                  value={{
                    label: !value
                      ? t('entries.table.entry.filters.time.all')
                      : t(
                          `entries.table.entry.filters.time.${value.toLowerCase()}`,
                        ),
                    value: value || 'all',
                  }}
                  onChange={(e) => {
                    onChange(e);
                    onFilter();
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="priority"
              render={({ field: { onChange, value } }) => (
                <StyledDropDown
                  options={[
                    {
                      label: t('entries.table.entry.filters.priority.all'),
                      value: null,
                    },
                    {
                      label: t('common.high'),
                      value: ContactTaskPriority.HIGH,
                    },
                    {
                      label: t('common.medium'),
                      value: ContactTaskPriority.MEDIUM,
                    },
                    {
                      label: t('common.low'),
                      value: ContactTaskPriority.LOW,
                    },
                  ]}
                  value={{
                    label: !value
                      ? t('entries.table.entry.filters.priority.all')
                      : t(`common.${value.toLowerCase()}`),
                    value: value || 'all',
                  }}
                  onChange={(e) => {
                    onChange(e);
                    onFilter();
                  }}
                />
              )}
            />
          </Fragment>
        }
      />

      <EntryTableSettingsDrawer
        open={tableSettingsModalVisible}
        onClose={() => setTableSettingsModalVisible(false)}
      />
    </Fragment>
  );
};
