import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { Checkbox } from 'antd';
import type { CheckboxOptionType, GetProp } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { AtiraCheckBoxGroup } from '../../../components/AtiraCheckBoxGroup';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { EntriesTableSettings } from '../../../model/entries-table-settings/EntriesTableSettings';
import { UpdateUserEntriesTableSettingsDto } from '../../../model/entries-table-settings/dto/UpdateUserEntriesTableSettingsDto';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const ButtonWithIcon = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  margin: 0;
  padding: ${Spacing.s};
`;

const StyledInput = styled(Input)<{ isFocused?: boolean }>`
  border: ${({ isFocused, theme }) =>
    isFocused ? `1px solid ${theme.lightgray}` : '0'} !important;
  font-size: 1.25rem;
`;

const StyledButton = styled(Button)`
  width: 7rem;
  height: 2.5rem;
  padding: 0;
  font-size: 1rem;
`;

type EntryTableSettingsModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const EntryTableSettingsDrawer: React.FC<
  EntryTableSettingsModalProps
> = ({ open, onClose }) => {
  const [userOptions, setUserOptions] = useState<string[]>([]);
  const [updateColumnsLoading, setUpdateColumnsLoading] = useState(false);
  const [editingFieldIndex, setEditingFieldIndex] = useState<number | null>(
    null,
  );
  const [updateFieldNamesLoading, setUpdateFieldNamesLoading] = useState(false);

  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );

  const customFields = tableSettings?.columns.filter((x) => x.custom) || [];
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const { t } = useTranslation(undefined, {});
  const dispatch = useAppDispatch();

  const inputRefs = useRef(new Map<number, HTMLInputElement | null>());

  const { control, handleSubmit, getValues, setValue } = useForm<{
    customFields: EntriesTableSettings['columns'];
  }>({
    defaultValues: { customFields },
  });

  const allOptions: CheckboxOptionType[] = (tableSettings?.columns || [])
    .map((c) => ({
      label: c.label,
      value: c.field,
      disabled: c.toggleAble === false,
    }))
    .sort((a, b) => a.label?.localeCompare(b.label));

  const onCheckBoxChange: GetProp<typeof Checkbox.Group, 'onChange'> = (
    checkedValues,
  ) => {
    setUserOptions(checkedValues as string[]);
  };

  const getUpdateDto = (): UpdateUserEntriesTableSettingsDto => {
    return {
      tableId: tableSettings?._id!,
      userId,
      columns: tableSettings?.columns
        .filter((f) => f.custom !== true)
        .concat(getValues('customFields'))
        .map((col) => ({
          ...col,
          enabled: userOptions.includes(col.field),
        })),
    };
  };

  const onUpdateFieldsNames = async () => {
    try {
      setUpdateFieldNamesLoading(true);

      await dispatch(
        entryActions.updateUserTableSettings(getUpdateDto()),
      ).unwrap();
      await dispatch(entryActions.getUserTableSettings({ userId })).unwrap();

      AtiraToast.success(
        t('entries.table.settings.custom_fields.update.success'),
      );
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setUpdateFieldNamesLoading(false);
    }
  };

  const onSaveColumns = async () => {
    try {
      setUpdateColumnsLoading(true);

      await dispatch(
        entryActions.updateUserTableSettings(getUpdateDto()),
      ).unwrap();
      await dispatch(entryActions.getUserTableSettings({ userId })).unwrap();

      AtiraToast.success(t('entries.table.settings.columns.button.success'));
      onClose();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateColumnsLoading(false);
    }
  };

  const onStartCancelEditFieldName = (index: number) => {
    if (editingFieldIndex === null) {
      setEditingFieldIndex(index);

      const input = inputRefs.current.get(index);

      if (input) {
        input.focus();
      }

      return;
    }

    setValue(
      `customFields.${index}.label`,
      customFields.at(index)?.label || '',
    );
    setEditingFieldIndex(null);
  };

  useEffect(() => {
    const _userOptions = (tableSettings?.columns || [])
      ?.filter(({ enabled }) => enabled)
      .map(({ field }) => field);

    setUserOptions(_userOptions);
    // Do not remove `open`
    // We need to update the userOptions when the modal is opened to keep the state in sync
  }, [tableSettings?.columns, open]);

  return (
    <Drawer
      destroyOnClose
      open={open}
      onClose={onClose}
      title={t('entries.table.settings.columns.header')}
      width={'30rem'}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height={'100%'}
        gap="xl"
      >
        <Flex flexDirection="column" gap="xl">
          <Flex flexDirection="column" gap="s">
            <Text fontSize="l">{t('common.custom_fields')}:</Text>

            <Text color="gray" fontSize="m">
              {t('entries.table.settings.custom_fields.description')}
            </Text>

            <Flex flexDirection="column" gap="m" marginTop="l">
              {customFields?.map((field, i) => (
                <Controller
                  control={control}
                  rules={{
                    maxLength: {
                      value: Lengths.NAME,
                      message: t('common.errors.max_length', {
                        length: Lengths.NAME,
                      }),
                    },
                  }}
                  name={`customFields.${i}.label`}
                  render={({ field: { value, onChange } }) => (
                    <Flex alignItems="center" justifyContent="space-between">
                      <StyledInput
                        isFocused={editingFieldIndex === i}
                        ref={(el) => inputRefs.current.set(i, el)}
                        value={value}
                        onChange={onChange}
                        readOnly={editingFieldIndex !== i}
                        maxLength={Lengths.NAME}
                      />

                      <ButtonWithIcon
                        icon={editingFieldIndex !== i ? faPen : faXmark}
                        iconWidth="xl"
                        onClick={() => onStartCancelEditFieldName(i)}
                      />
                    </Flex>
                  )}
                  key={field.field}
                />
              ))}
            </Flex>

            <StyledButton
              loading={updateFieldNamesLoading}
              onClick={handleSubmit(onUpdateFieldsNames)}
            >
              {t('common.update')}
            </StyledButton>
          </Flex>
        </Flex>

        <Flex gap="xl" flexDirection="column">
          <Flex flexDirection="column" gap="s">
            <Text fontSize="l">
              {t('entries.table.settings.columns.title')}
            </Text>
            <Text color="gray" fontSize="m">
              {t('entries.table.settings.columns.description')}
            </Text>
          </Flex>

          <AtiraCheckBoxGroup
            columnGap="7rem"
            options={allOptions}
            value={userOptions}
            onChange={onCheckBoxChange}
          />

          <StyledButton loading={updateColumnsLoading} onClick={onSaveColumns}>
            {t('common.update')}
          </StyledButton>
        </Flex>
        <Flex justifyContent="center">
          <AtiraImage width="60%" src={Images.AtiraSpaceLogoBetaMain} />
        </Flex>
      </Flex>
    </Drawer>
  );
};
