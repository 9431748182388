import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Steps, StepsProps } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import i18n, { AppLangs } from '../../i18n';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';
import { AtiraIcon } from '../AtiraIcon';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { StepProvider, useStepContext } from './StepperContext';

const StyledSteps = styled(Steps)`
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  box-shadow: ${Shadow.SM};
  overflow-x: scroll;
  display: flex;
  gap: ${Spacing.m};
  scrollbar-width: none;

  .ant-steps-item {
    white-space: normal !important;
    display: flex !important;
    overflow: visible;
    position: static !important;
    flex-grow: 0;
  }

  .ant-steps-item-container {
    display: flex !important;
    align-items: center !important;
    white-space: nowrap !important;
  }

  .ant-steps-item-title::after {
    width: 1rem !important;
    background-color: ${({ theme }) => theme.main} !important;
  }

  .anticon {
    height: 100%;
  }
`;

const StepChangeButtonsWarpper = styled(Flex)`
  justify-content: flex-end;
  gap: ${Spacing.s};
  position: sticky;
  bottom: ${Spacing.l};
  z-index: 9999;
  margin-top: auto;
  background-color: ${({ theme }) => theme.white};
  padding: 0.2rem;
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
`;

const CreateButton = styled(Button)`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  width: 7rem;
`;

interface AtiraStepperProps extends StepsProps {
  components: ReactNode[];
  items: StepsProps['items'];
  style?: React.CSSProperties;
  fixed?: boolean;
  createLoading?: boolean;
  onCreate?: VoidFunction;
  createButtonTitle?: string;
}

const AtiraStepper: React.FC<AtiraStepperProps> = ({
  items,
  components,
  style,
  onCreate,
  createLoading = false,
  createButtonTitle,
}) => {
  const { t } = useTranslation();

  const { currentStep, onNext, onPrev, onGoToStep, validations } =
    useStepContext();
  const canGoNext = validations[currentStep];
  const disabled =
    currentStep === components.length - 1 || (canGoNext && !canGoNext());

  const Component = components[currentStep]!;

  return (
    <Flex flex={1} justifyContent="center" gap="l" style={style}>
      <Flex width={'100%'} flexDirection="column" padding="l" gap="m">
        <StyledSteps
          direction="horizontal"
          current={currentStep}
          initial={0}
          items={items}
          status={
            disabled && currentStep !== components.length - 1
              ? 'error'
              : 'process'
          }
        />
        {Component}
        <StepChangeButtonsWarpper>
          <Button
            icon={i18n.language === AppLangs.AR ? faArrowRight : faArrowLeft}
            onClick={onPrev}
            disabled={currentStep === 0}
            iconWidth="lg"
            margin="0"
          />

          {currentStep === components.length - 1 ? (
            <CreateButton
              loading={createLoading}
              disabled={!disabled}
              onClick={onCreate}
            >
              {createButtonTitle || t('common.create')}

              <AtiraIcon icon={faCheck} />
            </CreateButton>
          ) : (
            <Button
              margin="0"
              icon={i18n.language === AppLangs.AR ? faArrowLeft : faArrowRight}
              onClick={onNext}
              disabled={disabled}
              iconWidth="lg"
            />
          )}
        </StepChangeButtonsWarpper>
      </Flex>
    </Flex>
  );
};

type AtiraStepperWithContextProps = {
  components: ReactNode[];
  items: StepsProps['items'];
  style?: React.CSSProperties;
  fixed?: boolean;
  onSubmit?: VoidFunction;
  submitLoading?: boolean;
  submitButtonTitle?: string;
};

export const AtiraStepperWithContext: React.FC<
  AtiraStepperWithContextProps
> = ({
  components,
  items,
  style,
  fixed,
  submitLoading = false,
  onSubmit,
  submitButtonTitle,
}) => {
  return (
    <StepProvider>
      <AtiraStepper
        createButtonTitle={submitButtonTitle}
        onCreate={onSubmit}
        createLoading={submitLoading}
        components={components}
        items={items}
        style={style}
        fixed={fixed}
      />
    </StepProvider>
  );
};
