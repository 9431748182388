import { createSlice } from '@reduxjs/toolkit';

import { AdminAxios } from '../../axios/admin/admin.axios';
import { AdminStats } from '../../model/admin/AdminStats';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AdminReducer {
  stats: AdminStats;
  searchUsers: SearchUsersResponseDto;
  searchUsersLoading: boolean;
}

const initialState = Object.freeze<AdminReducer>({
  stats: {
    users: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
    entry: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
    form: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
  },
  searchUsers: { result: [] },
  searchUsersLoading: false,
});

const getAdminStats = AtiraThunk<AdminStats, GetAdminStatsDto>(
  '/admin/stats',
  (dto) => AdminAxios.getAdminStats(dto),
);

const searchUsers = AtiraThunk<SearchUsersResponseDto, SearchUsersDto>(
  '/admin/user/search',
  (dto) => AdminAxios.searchUsers(dto),
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getAdminStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    addCase(searchUsers.pending, (state) => {
      state.searchUsersLoading = true;
    });

    addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsers.result = action.payload.result || [];
      state.searchUsersLoading = false;
    });

    addCase(searchUsers.rejected, (state) => {
      state.searchUsersLoading = false;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const adminActions = {
  getAdminStats,
  searchUsers,
};

export default adminSlice.reducer;
