import { Radio } from 'antd';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { useStepContext } from '../../components/stepper/StepperContext';
import { useStepperRegisterValidation } from '../../components/stepper/useStepperRegisterValidation';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { Direction } from '../../model/shared/Direction.enum';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { Regexes } from '../../utils/String';

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding-bottom: ${Spacing.l};
  border-bottom: 2px solid ${({ theme }) => theme.darkerSub};
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    border-inline-end: ${({ theme }) => `2px solid ${theme.darkerSub}`};
    padding-inline-end: ${Spacing.xl};
    padding-bottom: 0;
    border-bottom: 0;
    width: 63%;
    justify-content: center;
    height: 100%;
  }
`;

const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 35%;
  }
`;

const StyledInput = styled(Input)<{ show?: boolean }>`
  width: 100%;
  height: ${({ show }) => (show ? '2.5rem' : '0')};
  padding: 0.5rem 1rem;
  border: ${({ theme, show }) =>
    show ? `1px solid ${theme.darkSub}` : '0'} !important;
  font-size: 1.2rem;
  transition: ease-in 0.2s;
`;

const StyledRadio = styled(Radio)`
  font-size: 1.1rem;
`;

const StyledSpan = styled.span`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textColor};
`;

const DescriptionSpan = styled.span`
  color: ${({ theme }) => theme.darkerSub};
`;

export const FormCreateEditStepPostSubmitType: React.FC = () => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<CreateFormDto>();
  const { currentStep } = useStepContext();
  const [postSubmit, postSubmitUrl] = watch(['postSubmit', 'postSubmitUrl']);

  const canGoNext = useCallback(() => {
    if (postSubmit === FormPostSubmit.redirect) {
      return errors?.postSubmitUrl || !postSubmitUrl ? false : true;
    }

    return true;
  }, [postSubmit, postSubmitUrl?.length, errors.postSubmitUrl]);

  useStepperRegisterValidation(currentStep, canGoNext);

  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" width={'100%'}>
      <Flex gap={'m'} alignItems="center" width={'100%'} flexWrap="wrap">
        <LeftSideWrapper>
          <Text fontSize="l" fontWeight={'bold'}>
            {t('forms.create.step.3.post_submit_type')}
          </Text>
          <Controller
            control={control}
            name="postSubmit"
            render={({ field: { onChange, value } }) => (
              <StyledRadio
                onChange={() => onChange(FormPostSubmit.confirm)}
                checked={value === FormPostSubmit.confirm}
                value={FormPostSubmit.confirm}
              >
                {t('forms.create.step.3.radio.confirmation')}
              </StyledRadio>
            )}
          />

          <Controller
            control={control}
            name="postSubmitMessage"
            rules={{
              required: postSubmit === FormPostSubmit.confirm,
              maxLength: Lengths.DESCRIPTION,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledInput
                show={postSubmit === FormPostSubmit.confirm}
                value={value}
                onChange={onChange}
                placeholder={`${t('common.example')}: ${t('common.thank_you')}`}
                valid={!error}
                errorMessage={error?.message}
                maxLength={Lengths.DESCRIPTION}
              />
            )}
          />

          <Controller
            control={control}
            name="postSubmit"
            render={({ field: { onChange, value } }) => (
              <StyledRadio
                checked={value === FormPostSubmit.redirect}
                onChange={() => onChange(FormPostSubmit.redirect)}
                value={FormPostSubmit.redirect}
              >
                {t('forms.create.step.3.radio.redirect')}
              </StyledRadio>
            )}
          />

          <Controller
            control={control}
            name="postSubmitUrl"
            rules={{
              required: postSubmit === FormPostSubmit.redirect,
              pattern: {
                value: Regexes.URL_REGEX,
                message: t('forms.create.step.3.error.invalid_url'),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledInput
                show={postSubmit === FormPostSubmit.redirect}
                value={value}
                onChange={onChange}
                placeholder={`${t('common.example')}: www.example.com/thanks`}
                valid={!error}
                errorMessage={error?.message}
                required
              />
            )}
          />
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text>
            <StyledSpan>
              {`${t('forms.create.step.3.radio.confirmation')}: `}
            </StyledSpan>

            <DescriptionSpan>
              {t('forms.create.step.3.radio.confirm.description')}
            </DescriptionSpan>
          </Text>

          <Text>
            <StyledSpan>{t('forms.create.step.3.radio.redirect')}: </StyledSpan>

            <DescriptionSpan>
              {t('forms.create.step.3.radio.redirect.description')}
            </DescriptionSpan>
          </Text>
        </RightSideWrapper>
      </Flex>

      <Flex gap={'m'} marginTop="xxl" alignItems="center" flexWrap="wrap">
        <LeftSideWrapper>
          <Text fontSize="l" fontWeight={'bold'}>
            {t('forms.create.step.3.button.title')}
          </Text>

          <Flex>
            <Controller
              control={control}
              name="submitButtonTitle"
              rules={{
                maxLength: {
                  value: Lengths.NAME,
                  message: t('error.length.less', {
                    name: t('forms.create.step.3.button.title'),
                    length: Lengths.NAME,
                  }),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledInput
                  show
                  value={value}
                  onChange={onChange}
                  placeholder={`${t('common.example')}: ${t('common.submit')}`}
                  valid={!error}
                  errorMessage={error?.message}
                  maxLength={Lengths.NAME}
                />
              )}
            />
          </Flex>
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text>
            <StyledSpan>{t('forms.create.step.3.button.title')}: </StyledSpan>

            <DescriptionSpan>
              {t('forms.create.step.3.submit.button.title.description')}
            </DescriptionSpan>
          </Text>
        </RightSideWrapper>
      </Flex>

      <Flex gap={'m'} marginTop="xxl" alignItems="center" flexWrap="wrap">
        <LeftSideWrapper>
          <Controller
            control={control}
            name="formDirection"
            render={({ field: { value, onChange } }) => (
              <Radio.Group onChange={onChange} value={value}>
                <Flex flexDirection="column" gap="l">
                  <Text fontWeight={'bold'} fontSize="l">
                    {t('form.direction')}
                  </Text>

                  <StyledRadio value={Direction.LTR}>
                    {t('common.ltr')}
                  </StyledRadio>
                  <StyledRadio value={Direction.RTL}>
                    {t('common.rtl')}
                  </StyledRadio>
                </Flex>
              </Radio.Group>
            )}
          />
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text>
            <StyledSpan>{t('common.ltr')}: </StyledSpan>

            <DescriptionSpan>
              {t('form.create.ltr.description')}
            </DescriptionSpan>
          </Text>

          <Text>
            <StyledSpan>{t('common.rtl')}: </StyledSpan>

            <DescriptionSpan>
              {t('form.create.rtl.description')}
            </DescriptionSpan>
          </Text>
        </RightSideWrapper>
      </Flex>
    </Flex>
  );
};
