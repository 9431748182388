import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Flex } from '../../../../components/Flex';

export const PendingMemberREJECTED: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" gap="l">
      <Flex alignItems="flex-start" gap="s">
        <Tooltip title={t('permissions.invite.member.status-rejected')}>
          <Flex cursor="pointer">
            <AtiraIcon icon={faCircleExclamation} color="gray" />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
