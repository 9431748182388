import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { UseCasesByIndustryTabs } from '../components/UseCasesByIndustryTabs';

const Container = styled(Flex)`
  padding-inline: ${Spacing.s};
  padding-block: ${Spacing.xl};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: calc(${Spacing.xxxl} * 1.3);
    padding-block: ${Spacing.xxxl};
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: ${Spacing.s};
  font-weight: bold;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2rem;
  }
`;

export const UseCasesByIndustrySection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex flexDirection="column" gap="l">
        <Title>{t('use_cases.industry.title')}</Title>

        <UseCasesByIndustryTabs />
      </Flex>
    </Container>
  );
};
