import React, { HtmlHTMLAttributes } from 'react';
import styled from 'styled-components';

type ClickableProps = HtmlHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

const ClickableComponent = styled.div`
  cursor: pointer;
`;

export const Clickable: React.FC<ClickableProps> = ({ children, ...props }) => {
  return <ClickableComponent {...props}>{children}</ClickableComponent>;
};
