import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { Button } from './Button';
import { Flex } from './Flex';
import { Text } from './Text';

const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
  margin-bottom: ${Spacing.m};
  padding: ${Spacing.m};
  width: 100%;
  height: 3.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const StyledButton = styled(Button)`
  font-size: 1.1rem;
  background: ${(props) => props.theme.lightSub};
  padding: ${Spacing.s};
  border-radius: ${Rounded.lg};
  transition: border-bottom 0.3s;
  border: 1px solid ${(props) => props.theme.transparent};
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    border: 1px solid ${(props) => props.theme.darkSub};
  }
`;

interface SubHeaderProps {
  title: string;
  icon?: IconProp;
  buttonTitle?: string;
  onClick?: VoidFunction;
  enabled?: boolean;
  showButton?: boolean;
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  title,
  icon,
  onClick,
  buttonTitle,
  enabled = true,
  showButton = true,
}) => {
  return (
    <Container>
      <Text fontWeight="bold" fontSize="l">
        {title}
      </Text>

      {showButton ? (
        <StyledButton
          onClick={onClick}
          icon={icon}
          disabled={!enabled}
          color={enabled ? 'black' : 'gray'}
        >
          {buttonTitle}
        </StyledButton>
      ) : null}
    </Container>
  );
};
