import { createGlobalStyle, css } from 'styled-components';

// @ts-ignore
import RobotFont from './fonts/roboto/roboto-regular.ttf';
// @ts-ignore
import ZainFont from './fonts/zain/zain-regular.ttf';
import { Rounded } from './theme/Rounded';

const fontFaces = css`
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotFont});
  }

  @font-face {
    font-family: 'Zain';
    src: url(${ZainFont});
  }
`;

export const GlobalStyle = createGlobalStyle`
:root {
  --atira-main: #803588;
  --atira-sub: #ebebeb;
  --atira-light-main: #934599;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;

  height: 100vh;
}

::-webkit-scrollbar {
  width: 3px; 
  -webkit-overflow-scrolling: touch;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: var(--atira-sub);
}

::-webkit-scrollbar-thumb {
  background: var(--atira-light-main);
  border-radius: ${Rounded.xl};
}

::-webkit-scrollbar-thumb:hover {
  background: var(--atira-main);
}

${fontFaces}

.atira-arabic-font {
  direction: rtl;
  font-family: 'Zain', sans-serif;
}

.atira-english-font {
  direction: ltr;
  font-family: 'Roboto', sans-serif;
}

.atira-arabic-font p {
  font-family: 'Zain', sans-serif;
}

.atira-english-font p {
  font-family: 'Roboto', sans-serif;
}


/*  */

.ant-layout .ant-layout-sider,
.ant-layout .ant-layout-sider-trigger {
  background-color: #000;
}

.ant-spin-dot-item {
  background-color: var(--atira-main) !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item.logout-button:hover,
.ant-dropdown-menu .ant-dropdown-menu-item.user-email:hover,
.ant-dropdown-menu .ant-dropdown-menu-item.hr:hover {
  cursor: default;
  background-color: transparent !important;
}

`;
