import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import i18n, { AppLangs } from '../../../../i18n';
import { WidgetKind } from '../../../../model/widget/types/WidgetKid.enum';
import { useAppSelector } from '../../../../redux/store';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { Breakpoints } from '../../../../theme/Breakpoints';
import { Rounded } from '../../../../theme/Rounded';
import { Shadow } from '../../../../theme/Shadow';
import { Spacing } from '../../../../theme/Spacing';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  width: 100%;
  height: 100%;
  max-height: 14rem;
  padding: 1rem;
  box-shadow: ${Shadow.SM};
  margin-bottom: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

const ResponsiveText = styled(Text)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.gray};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    font-size: 1.5rem;
  }
`;

const MainColorSpan = styled.span`
  color: ${({ theme }) => theme.main};
  text-overflow: ellipsis;
`;

export const WidgetTasks: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const stats = useAppSelector(widgetSliceSelectors.selectUserWidgets)?.find(
    (w) => w.kind === WidgetKind.TASK_STATS,
  )?.data;

  const arrowIcon = i18n.language === AppLangs.AR ? faArrowLeft : faArrowRight;

  return (
    <Wrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Title fontSize="l">{t('dashboard.widget.title.Tasks Stats')}</Title>

        <StyledButton onClick={() => navigate('/tasks')}>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={arrowIcon} color="main" />
            <Text color="main">{t('dashboard.widget.tasks.btn')}</Text>
          </Flex>
        </StyledButton>
      </Flex>

      <Container>
        <Flex flexDirection="column" gap="m">
          {stats
            ? Object.entries(stats as any).map(([key, value]) => (
                <Flex gap="s" alignItems="center">
                  <AtiraIcon icon={faCircle} color="main" size="xs" />

                  <ResponsiveText>
                    <MainColorSpan>{t(`common.${key}`)}: </MainColorSpan>

                    <MainColorSpan>
                      {(value as any).overdue.length}
                    </MainColorSpan>
                    {` ${t('common.outdue')}, `}
                    <MainColorSpan>
                      {(value as any).dueToday.length}
                    </MainColorSpan>
                    {` ${t('common.today')}, `}
                    <MainColorSpan>
                      {(value as any).dueTomorrow.length}
                    </MainColorSpan>
                    {` ${t('common.tomorrow')}`}
                  </ResponsiveText>
                </Flex>
              ))
            : null}
        </Flex>
      </Container>
    </Wrapper>
  );
};
