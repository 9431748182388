import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  SortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

type DNDSortableDropableContextProps = {
  id: string;
  items: any[];
  strategy?: SortingStrategy;
  children?: React.ReactNode;
};

export const DNDSortableDroppableContext: React.FC<
  DNDSortableDropableContextProps
> = ({ id, items, strategy = verticalListSortingStrategy, children }) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext items={items} strategy={strategy}>
      <div ref={setNodeRef}>{children}</div>
    </SortableContext>
  );
};
