import { createBrowserRouter } from 'react-router-dom';

import { makeMultiLangRouter } from '../../utils/MultiLangRouter';
import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { Login } from '../loginSignup/Login';
import { Signup } from '../loginSignup/signup/Signup';
import { PrivateRouter } from './Private.router';
import { PublicRouter } from './Public.router';

const router = createBrowserRouter([
  ...makeMultiLangRouter(PublicRouter),
  ...PrivateRouter,
  // login and signup routes need to be here too for google auth to work for non-English languages
  { path: '/login', element: <AtiraRouteNotLoggedIn component={<Login />} /> },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  { path: '*', element: <AtiraRoute redirect="/" /> },
]);

export default router;
