import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { Text } from '../../components/Text';
import { useAppSelector } from '../../redux/store';
import { thresholdSliceSelectors } from '../../redux/threshold/threshold.selector';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.m};

  border-radius: ${Rounded.lg};

  margin: ${Spacing.s} auto;
  padding: ${Spacing.m};
  flex-grow: 1;
`;

const CallToAction = styled.a`
  text-decoration: none;
  font-size: 1rem;
  padding: ${Spacing.s} ${Spacing.m};
  color: ${({ theme }) => theme.sub};
  background-color: ${({ theme }) => theme.main};
  border-radius: ${Rounded.md};
  transition: all 0.3s;
  width: fit-content;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

const StyledText = styled(Text)`
  text-align: center;
  white-space: pre-line;
  font-size: 1.25rem;
`;

export const Subscription: React.FC = () => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const thresholdData = useAppSelector(
    thresholdSliceSelectors.selectThresholdStats,
  );
  const loading = useAppSelector(
    thresholdSliceSelectors.selectThresholdLoading,
  );

  const showLoading = loading && !thresholdData;

  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Container>
      <Text fontSize="xxl">{t('subscription.page.title')}</Text>

      <StyledText>{t('subscription.info')}</StyledText>
      <Flex flexDirection="column" gap="m" width={'60%'}>
        <Text fontSize="l" align="center">
          {`${t('subscription.status')} `}
          <span style={{ color: theme.main }}>
            {t(`common.${loggedInUser?.kind.toLowerCase()}`)}
          </span>
        </Text>

        <Text fontSize="l" align="center">
          {t('subscription.plan.features')}
        </Text>

        {showLoading ? (
          <SpinnerFullScreen />
        ) : (
          <Flex flexDirection="column" gap="s">
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('common.user')} ${thresholdData?.consumed?.USERS}/${thresholdData?.total?.USERS}`}
              </Text>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('common.entries')} ${thresholdData?.consumed?.ENTRIES}/${thresholdData?.total?.ENTRIES}`}
              </Text>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('common.forms')}  ${thresholdData?.consumed?.FORMS}/${thresholdData?.total?.FORMS}`}
              </Text>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('common.pipeline')}  ${thresholdData?.consumed?.KANBANS}/${thresholdData?.total?.KANBANS}`}
              </Text>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('common.custom_fields')} ${thresholdData?.consumed?.CUSTOM_FIELDS}/${thresholdData?.total?.CUSTOM_FIELDS}`}
              </Text>
            </Flex>

            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} color="main" />
              <Text fontSize="xm">
                {`${t('pricing.hero.plan_card.fearure.app')} `}
                <span style={{ fontStyle: 'italic' }}>
                  ({t('common.soon')})
                </span>
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex flexDirection="column">
        <StyledText style={{ textAlign: 'start' }}>
          {t('subscription.info.contact')}
        </StyledText>

        <Flex alignItems="center" gap="s" justifyContent="center">
          <CallToAction target="_blank" href="https://wa.me/905335589040">
            {t('common.contact_whatsapp')}
          </CallToAction>

          <CallToAction target="_blank" href="mailto:support@atiragrup.com">
            {t('common.contact_support')}
          </CallToAction>

          <CallToAction target="_blank" href="mailto:sales@atiragrup.com">
            {t('common.contact_sales')}
          </CallToAction>
        </Flex>
      </Flex>

      <Flex maxWidth={'15rem'}>
        <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
      </Flex>
    </Container>
  );
};
