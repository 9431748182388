import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PermissionAxios } from '../../axios/permission/permission.axios';
import { PermissionInvite } from '../../model/permissions/PermisisonInvite';
import { GetPermissionInviteByIdDto } from '../../model/permissions/dto/GetPermissionInviteByIdDto';
import { PermissionLeaveOrganizationDto } from '../../model/permissions/dto/PermissionLeaveOrganizationDto';
import { PermissionRemoveMemberByIdDto } from '../../model/permissions/dto/PermissionRemoveMemberByIdDto';
import { PermissionRespondToInviteDto } from '../../model/permissions/dto/PermissionRespondToInviteDto';
import { PermissionUpdateMemberPermissionsDto } from '../../model/permissions/dto/PermissionUpdateMemberPermissionsDto';
import { PermissionsInviteMembersDto } from '../../model/permissions/dto/PermissionsInviteMembersDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { User } from '../../model/user/User';
import { LoginResponseDto } from '../../model/user/dto/LoginResponseDto';
import { SignupSuccessDto } from '../../model/user/dto/SignupSuccessDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from './../user/user.slice';

interface PermissionReducer {
  inviteLoading?: boolean;
  invitedMembers?: PermissionInvite[];
  invitedMembersLoading?: boolean;
  //
  currentInvite: PermissionInvite | null;
  members: User[];
}

const initialState = Object.freeze<PermissionReducer>({
  inviteLoading: false,
  invitedMembers: [],
  invitedMembersLoading: false,
  currentInvite: null,
  members: [],
});

const inviteMembers = AtiraThunk<void, PermissionsInviteMembersDto>(
  `/permissions/invite-members`,
  (dto) => PermissionAxios.inviteMembers(dto),
);

const getInvitedMembers = AtiraThunk<PermissionInvite[], GenericWithUserId>(
  '/permissions/invited-members',
  (dto) => PermissionAxios.getInvitedMembers(dto),
);

const getInviteByCode = AtiraThunk<
  PermissionInvite,
  GetPermissionInviteByIdDto
>('/permissions/invite/:id', (dto) => PermissionAxios.getInviteByCode(dto));

const respondToInvite = AtiraThunk<void, PermissionRespondToInviteDto>(
  'permissions/respond',
  (dto) => PermissionAxios.respondToInvite(dto),
);

const getMembers = AtiraThunk<User[], GenericWithUserId>(
  'permissions/members',
  (dto) => PermissionAxios.getMembers(dto),
);

const removeMember = AtiraThunk<void, PermissionRemoveMemberByIdDto>(
  'permissions/member/:id/remove',
  (dto) => PermissionAxios.removeMember(dto),
);

const leaveOrganization = AtiraThunk<void, PermissionLeaveOrganizationDto>(
  '/permission/member/leave',
  (dto) => PermissionAxios.leaveOrganization(dto),
);

const updateMemberPermissions = AtiraThunk<
  void,
  PermissionUpdateMemberPermissionsDto
>('/permissions/member/:id/update-permissions', (dto) =>
  PermissionAxios.updateMemberPermissions(dto),
);

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    resetCurrentInvite: (state, action) => {
      state.currentInvite = null;
    },
  },
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(inviteMembers.pending, (state, action) => {
      state.inviteLoading = true;
    });
    addCase(inviteMembers.fulfilled, (state, action) => {
      state.inviteLoading = false;
    });
    addCase(inviteMembers.rejected, (state, action) => {
      state.inviteLoading = false;
    });
    //
    addCase(getInvitedMembers.pending, (state, action) => {
      state.invitedMembersLoading = true;
    });
    addCase(getInvitedMembers.fulfilled, (state, action) => {
      state.invitedMembers = action.payload;
      state.invitedMembersLoading = false;
    });
    addCase(getInvitedMembers.rejected, (state, action) => {
      state.invitedMembersLoading = false;
    });
    //
    addCase(getInviteByCode.pending, (state, action) => {
      state.inviteLoading = true;
    });
    addCase(getInviteByCode.fulfilled, (state, action) => {
      state.currentInvite = action.payload;
      state.inviteLoading = false;
    });
    addCase(getInviteByCode.rejected, (state, action) => {
      state.inviteLoading = false;
    });
    //
    addCase(userActions.logout.fulfilled, (state, action) => {
      return (state = initialState);
    });
    //

    addCase(getMembers.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    addMatcher(
      isAnyOf(
        userActions.loginWithGoogle.fulfilled,
        userActions.verifyUserAccount.fulfilled,
        userActions.login.fulfilled,
      ),
      (state, action: PayloadAction<SignupSuccessDto | LoginResponseDto>) => {
        if (action.payload.fromInvite && action.payload.invite) {
          state.currentInvite = action.payload.invite;
        }
      },
    );
  },
});

export const permissionActions = {
  ...permissionSlice.actions,
  inviteMembers,
  getInvitedMembers,
  getInviteByCode,
  respondToInvite,
  getMembers,
  removeMember,
  leaveOrganization,
  updateMemberPermissions,
};

export default permissionSlice.reducer;
