import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { ForgotPasswordEmailDto } from '../../../model/user/dto/ForgotPasswordEmailDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2.3rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: 0.5rem;
  transition: border 0.2s;
  font-size: 1rem;

  &:focus {
    border: 1px solid #86b7fe !important;
  }
`;

type ForgotPasswordEmailProps = {
  updateStep: VoidFunction;
};

export const ForgotPasswordEmail: React.FC<ForgotPasswordEmailProps> = ({
  updateStep,
}) => {
  const [resetButtonLoading, setResetButtonLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordEmailDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onNext = async () => {
    try {
      setResetButtonLoading(true);
      const response = await dispatch(
        userActions.forgotPasswordEmail({ email: getValues('email') }),
      ).unwrap();
      AtiraToast.success(response || t('resetpassword.email.success'));
      updateStep();
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setResetButtonLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <Text color="gray" align="center" wordBreak="break-word">
        {t('resetpassword.email.title')}
      </Text>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width={'100%'}
        gap="s"
      >
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.email')}
              type="email"
              value={value}
              onChange={onChange}
              id="email"
              valid={isEmpty(errors.email)}
              placeholder={t('resetpassword.email.input.placeholder')}
            />
          )}
        />
        <StyledButton
          onClick={handleSubmit(onNext)}
          loading={resetButtonLoading}
        >
          {t('common.send')}
        </StyledButton>
      </Flex>
    </Flex>
  );
};
