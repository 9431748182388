import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile';
import { Empty } from 'antd';

import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';

interface AtiraErrorProps {
  icon?: IconProp;
  description: string;
}

export const AtiraEmpty: React.FC<AtiraErrorProps> = ({
  description,
  icon = faFaceSmile,
}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Empty
        image={<AtiraIcon icon={icon} color="main" />}
        description={
          <Text color="main" fontSize="l" align="center">
            {description}
          </Text>
        }
      />
    </Flex>
  );
};
