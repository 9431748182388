import { createSlice } from '@reduxjs/toolkit';

import { ThresholdAxios } from '../../axios/threshold/threshold.axios';
import { GetUserCurrentThresholdsDto } from '../../model/threshold/dto/GetUserCurrentThresholdsDto';
import { GetUserCurrentThresholdsResponseDto } from '../../model/threshold/dto/GetUserCurrentThresholdsResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from './../user/user.slice';

interface ThresholdReducer {
  threshold: GetUserCurrentThresholdsResponseDto | null;
  thresholdLoading: boolean;
}

const initialState = Object.freeze<ThresholdReducer>({
  threshold: null,
  thresholdLoading: false,
});

const getThreshold = AtiraThunk<
  GetUserCurrentThresholdsResponseDto,
  GetUserCurrentThresholdsDto
>(`/threshold/:id`, (dto) => ThresholdAxios.getThreshold(dto));

const thresholdSlice = createSlice({
  name: 'threshold',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getThreshold.pending, (state) => {
      state.thresholdLoading = true;
    });
    addCase(getThreshold.fulfilled, (state, action) => {
      state.threshold = action.payload;
      state.thresholdLoading = false;
    });
    addCase(getThreshold.rejected, (state) => {
      state.thresholdLoading = false;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const thresholdActions = {
  getThreshold,
};

export default thresholdSlice.reducer;
