import React, { ReactNode, createContext, useContext, useState } from 'react';

import { KanbanTask } from '../../model/kanban/KanbanTask';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';

type Task =
  | Omit<LeadTask, 'entryId'>
  | Omit<ContactTask, 'entryId'>
  | Omit<KanbanTask, 'kanbanCardId'>;

interface TaskContextType {
  task: Task | null;
  setTask: ATVoidFunction<Task>;
  //
  contactTaskUpdateVisible: boolean;
  setContactTaskUpdateVisible: ATVoidFunction<boolean>;
  contactTasksReadVisible: boolean;
  setContactTasksReadVisible: ATVoidFunction<boolean>;
  //
  kanbanTaskUpdateVisible: boolean;
  setKanbanTaskUpdateVisible: ATVoidFunction<boolean>;
  kanbanTasksReadVisible: boolean;
  setKanbanTasksReadVisible: ATVoidFunction<boolean>;
  //
  leadTasksReadVisibile: boolean;
  setLeadTasksReadVisible: ATVoidFunction<boolean>;
  leadTaskUpdateVisibile: boolean;
  setLeadTaskUpdateVisible: ATVoidFunction<boolean>;
  leadTaskCreateVisible: boolean;
  setLeadTaskCreateVisible: ATVoidFunction<boolean>;
  //
  deleteTaskVisible: boolean;
  setDeleteTaskVisible: ATVoidFunction<boolean>;
  //
  taskDoneVisible: boolean;
  setTaskDoneVisible: ATVoidFunction<boolean>
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const useTaskContext = () => {
  const context = useContext(TaskContext);

  if (!context) {
    throw new Error('useTaskContext must be used within a EntriesProvider');
  }

  return context;
};

interface TaskContextProviderProps {
  children: ReactNode;
}

export const TaskContextProvider: React.FC<TaskContextProviderProps> = ({
  children,
}) => {
  const [task, setTask] = useState<Task | null>(null);
  //
  const [contactTaskUpdateVisible, setContactTaskUpdateVisible] =
    useState(false);
  const [contactTasksReadVisible, setContactTasksReadVisible] = useState(false);
  const [kanbanTasksReadVisible, setKanbanTasksReadVisible] = useState(false);
  const [kanbanTaskUpdateVisible, setKanbanTaskUpdateVisible] = useState(false);
  const [leadTasksReadVisibile, setLeadTasksReadVisible] = useState(false);
  const [leadTaskUpdateVisibile, setLeadTaskUpdateVisible] = useState(false);
  const [leadTaskCreateVisible, setLeadTaskCreateVisible] = useState(false);
  const [deleteTaskVisible, setDeleteTaskVisible] = useState(false);
  const [taskDoneVisible, setTaskDoneVisible] = useState(false)

  return (
    <TaskContext.Provider
      value={{
        task,
        setTask,
        //
        contactTaskUpdateVisible,
        setContactTaskUpdateVisible,
        contactTasksReadVisible,
        setContactTasksReadVisible,
        kanbanTaskUpdateVisible,
        setKanbanTaskUpdateVisible,
        kanbanTasksReadVisible,
        setKanbanTasksReadVisible,
        leadTasksReadVisibile,
        setLeadTasksReadVisible,
        leadTaskUpdateVisibile,
        setLeadTaskUpdateVisible,
        leadTaskCreateVisible,
        setLeadTaskCreateVisible,
        deleteTaskVisible,
        setDeleteTaskVisible,
        taskDoneVisible,
        setTaskDoneVisible
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
