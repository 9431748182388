import { CollapseProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useTheme } from 'styled-components';

import { AtiraCollapse } from '../../../../components/AtiraCollapse';
import { AtiraEmpty } from '../../../../components/AtiraEmpty';
import { Drawer } from '../../../../components/Drawer';
import { KanbanTask } from '../../../../model/kanban/KanbanTask';
import { useKanbanContext } from '../../kanban-context';
import { KanbanTaskDetails } from './KanbanTaskDetails';
import { Flex } from '../../../../components/Flex';
import { AtiraIcon } from '../../../../components/AtiraIcon';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Text } from '../../../../components/Text';

interface KanbanTasksReadDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  tasks: KanbanTask[] | undefined;
}

export const KanbanTasksReadDrawer: React.FC<KanbanTasksReadDrawerProps> = ({
  isOpen = false,
  tasks,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    setTask,
    setTaskUpdateDrawerVisible,
    setTasksReadDrawerVisible,
    setTaskDeleteModalVisible,
    setTaskDoneVisible
  } = useKanbanContext();

  const items: CollapseProps['items'] = useMemo(() => {
    const panelStyle: CSSProperties = {
      borderBottom: '1px solid black',
      backgroundColor: theme.sub,
      border: '0',
    };

    if (tasks?.length) {
      return tasks.map((task, index) => ({
        key: task._id,
        label: <Flex justifyContent='space-between' alignItems='center'>
          {task.name}

          {task.done === true ?
            <Flex alignItems='center' gap='s'>
              <AtiraIcon icon={faCheck} size='lg' color='green' />
              <Text color='green'>{t('common.done')}</Text>
            </Flex>
            : null
          }
        </Flex>,
        children: task ? (
          <KanbanTaskDetails
            setTaskDoneVisible={setTaskDoneVisible}
            kanbanTask={task}
            setTask={setTask}
            setKanbanTaskReadVisible={setTasksReadDrawerVisible}
            setKanbanTaskUpdateVisible={setTaskUpdateDrawerVisible}
            setDeleteVisible={setTaskDeleteModalVisible}
          />
        ) : null,
        style: panelStyle,
      }));
    }

    return [];
  }, [
    t,
    setTaskDoneVisible,
    setTask,
    setTaskDeleteModalVisible,
    setTaskUpdateDrawerVisible,
    setTasksReadDrawerVisible,
    tasks,
    theme
  ]);

  if (!tasks) {
    return null;
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={`${t('common.task_preview')}`}
      width={'30rem'}
    >
      {items.length ? (
        <AtiraCollapse items={items} accordion />
      ) : (
        <AtiraEmpty description={t('kanban.tasks.empty')} />
      )}
    </Drawer>
  );
};
