import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { CreateFormDto } from '../../../model/form/dto/CreateFormDto';
import { InputTypes } from '../../../model/form/types/InputTypes.enum';
import { Lengths } from '../../../model/shared/enum/Lengths.enum';

const SaveButton = styled(Button)`
  flex-grow: 1;
  border: ${({ theme }) => `1px solid ${theme.main}`};

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const CancelButton = styled(Button)`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.transparent};
  border: ${({ theme }) => `1px solid ${theme.black}`};
  color: ${({ theme }) => theme.black};

  &:hover {
    background-color: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
  }
`;

interface FormCreateEditInputUpdateModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  inputIndex: number;
}

export const FormCreateEditInputUpdateModal: React.FC<
  FormCreateEditInputUpdateModalProps
> = ({ isOpen = false, onClose, inputIndex }) => {
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [maxChars, setMaxChars] = useState(0);
  const [isRequired, setIsRequired] = useState(false);

  const { control, setValue, getValues } =
    useFormContext<Partial<CreateFormDto>>();

  const getDefaultMaxChar = () => {
    switch (getValues(`inputs.${inputIndex}.type`)?.toLowerCase()) {
      case InputTypes.TEXT.toLowerCase():
        return Lengths.NAME;
      case InputTypes.NUMBER.toLowerCase():
      case InputTypes.PHONE.toLowerCase():
        return Lengths.PHONE;
      case InputTypes.EMAIL.toLowerCase():
        return Lengths.EMAIL;
      default:
        return Lengths.DESCRIPTION;
    }
  };

  const { t } = useTranslation();

  const defaultMaxChar = getDefaultMaxChar();

  const currentInput = getValues(`inputs.${inputIndex}`);

  const isMaxCharValid =
    maxChars <= defaultMaxChar && maxChars >= Lengths.MIN_3;

  const isSaveButtonDisabled =
    label.length < Lengths.MIN_3 || label.length > Lengths.NAME;

  const onLocalClose = () => {
    if (!isSaveButtonDisabled) {
      handleSaveInput();
    } else {
      onClose();
    }
  };

  const handleSaveInput = () => {
    const input = getValues(`inputs.${inputIndex}`);

    setValue(`inputs.${inputIndex}.label`, label);
    setValue(`inputs.${inputIndex}.placeholder`, placeholder);
    setValue(`inputs.${inputIndex}.settings.required`, isRequired);

    if (
      input.type.toLowerCase() !== InputTypes.DATE.toLowerCase() &&
      input.type.toLowerCase() !== InputTypes.SELECT.toLowerCase()
    ) {
      setValue(`inputs.${inputIndex}.settings.maxChar`, maxChars);
    }

    onClose();
  };

  useEffect(() => {
    const input = getValues(`inputs.${inputIndex}`);

    if (isOpen) {
      setLabel(input.label || '');
      setPlaceholder(input.placeholder || '');
      setIsRequired(input.settings?.required || false);

      if (
        input.type.toLowerCase() !== InputTypes.DATE.toLowerCase() &&
        input.type.toLowerCase() !== InputTypes.SELECT.toLowerCase()
      ) {
        setMaxChars(input.settings?.maxChar || defaultMaxChar);
      }
    }
  }, [isOpen, inputIndex, getValues, defaultMaxChar]);

  return (
    <Modal
      destroyOnClose={true}
      zIndex={10000}
      key={inputIndex}
      open={isOpen}
      onClose={onLocalClose}
      title={t('forms.create.input_edit_modal.title')}
    >
      <Flex flexDirection="column" gap="m" width={'100%'}>
        <Controller
          key={`inputs.${inputIndex}.label`}
          control={control}
          name={`inputs.${inputIndex}.label` as const}
          render={() => (
            <Input
              value={label}
              onChange={(e) => setLabel(e.currentTarget.value)}
              placeholder={t('common.input_label')}
              maxLength={Lengths.NAME}
              valid={
                label.length >= Lengths.MIN_3 && label.length < Lengths.NAME
              }
              errorMessage={
                label.length < Lengths.MIN_3
                  ? t('error.length.more', {
                      name: t('common.input_label'),
                      length: Lengths.MIN_3,
                    })
                  : t('error.length.less', {
                      name: t('common.input_label'),
                      length: Lengths.NAME,
                    })
              }
            />
          )}
        />

        <Controller
          key={`inputs.${inputIndex}.placeholder`}
          control={control}
          name={`inputs.${inputIndex}.placeholder` as const}
          render={() => (
            <Input
              value={placeholder}
              onChange={(e) => setPlaceholder(e.currentTarget.value)}
              placeholder={t('common.input_placeholder')}
            />
          )}
        />

        {currentInput?.type.toLowerCase() !== InputTypes.DATE.toLowerCase() &&
        currentInput?.type.toLowerCase() !== InputTypes.SELECT.toLowerCase() ? (
          <Controller
            key={`inputs.${inputIndex}.settings.maxChars`}
            control={control}
            name={`inputs.${inputIndex}.settings.maxChar`}
            rules={{ required: true, max: defaultMaxChar, min: Lengths.MIN_3 }}
            render={() => (
              <Input
                title={t('common.max_chars')}
                type="number"
                value={maxChars}
                onChange={(e) => setMaxChars(Number(e.currentTarget.value))}
                valid={isMaxCharValid}
                errorMessage={
                  !isMaxCharValid
                    ? t('error.range', {
                        name: t('common.max_chars'),
                        max: defaultMaxChar,
                        min: Lengths.MIN_3,
                      })
                    : ''
                }
                max={defaultMaxChar}
                min={Lengths.MIN_3}
              />
            )}
          />
        ) : null}

        <Flex alignItems="center" justifyContent="space-between">
          <Text>{t('common.required')}</Text>

          <Controller
            key={`inputs.${inputIndex}.settings.required`}
            control={control}
            name={`inputs.${inputIndex}.settings.required`}
            rules={{ required: true }}
            render={() => (
              <Switch
                disabled={currentInput?.name === 'email'}
                onChange={() => setIsRequired((prev) => !prev)}
                checked={isRequired}
              />
            )}
          />
        </Flex>

        <Flex gap="m">
          <CancelButton onClick={onClose}>{t('common.cancel')}</CancelButton>

          <SaveButton onClick={handleSaveInput} disabled={isSaveButtonDisabled}>
            {t('common.save')}
          </SaveButton>
        </Flex>
      </Flex>
    </Modal>
  );
};
