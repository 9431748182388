import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const adminState = (state: RootState) => state;

const selectAdminStats = createSelector(
  adminState,
  (state) => state.admin.stats,
);

const selectUsers = createSelector(
  adminState,
  (state) => state.admin.searchUsers.result || [],
);

const searchUsersLoading = createSelector(
  adminState,
  (state) => state.admin.searchUsersLoading,
);

export const adminSliceSelectors = {
  selectAdminStats,
  selectUsers,
  searchUsersLoading,
};
