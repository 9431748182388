import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { TasksSettingsPatchDto } from '../../../model/task/dto/TasksSettingsPatchDto';
import { TaskKind } from '../../../model/task/types/TaskKind.enum';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { taskSliceSelectors } from '../../../redux/task/task.selector';
import { taskActions } from '../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledButton = styled(Button)`
  width: 7rem;
  height: 2.5rem;
  padding: 0;
  font-size: 1rem;
`;

type EntryTableSettingsModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const TasksSettingsDrawer: React.FC<EntryTableSettingsModalProps> = ({
  open,
  onClose,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);

  const { t } = useTranslation(undefined, { keyPrefix: 'tasks.settings' });
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const tasksSettings = useAppSelector(taskSliceSelectors.selectTasksSettings);

  const { control, handleSubmit, getValues } = useForm<TasksSettingsPatchDto>({
    defaultValues: { settings: { LEAD: tasksSettings?.settings.LEAD } },
  });

  const onSave = async () => {
    try {
      setSaveLoading(true);
      await dispatch(
        taskActions.updateTasksSettings({
          userId: user._id,
          settings: {
            [TaskKind.LEAD]: getValues('settings').LEAD,
          },
        }),
      ).unwrap();
      await dispatch(
        taskActions.getTasksSettings({ userId: user._id }),
      ).unwrap();
      AtiraToast.success(t('update.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Drawer
      key={user._id}
      destroyOnClose
      open={open}
      onClose={onClose}
      title={t('header')}
      width={'30rem'}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="l"
        height={'100%'}
      >
        <Flex flexDirection="column" gap="xl">
          <Flex flexDirection="column" gap="m">
            <Text fontSize="l">{t('drawer.attempts.title')}</Text>
            <Flex flexDirection="column" gap="s">
              <Text fontSize="s" color="darkerSub">
                {t('drawer.attempts.description')}
              </Text>

              <Flex gap="s" alignItems="center">
                <Text>{t('drawer.attempts.subtitle')}</Text>
                <Controller
                  control={control}
                  name="settings.LEAD.contactAttempts"
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      containerStyle={{ width: '5rem' }}
                      onChange={onChange}
                      value={value}
                      options={Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (i) => ({ label: i, value: i }),
                      )}
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="xl">
          <Flex flexDirection="column" gap="m">
            <Text fontSize="l">{t('drawer.interval.title')}</Text>
            <Flex flexDirection="column" gap="s">
              <Text fontSize="s" color="darkerSub">
                {t('drawer.interval.description')}
              </Text>

              <Flex gap="s" alignItems="center">
                <Text>{t('drawer.interval.subtitle')}</Text>
                <Controller
                  control={control}
                  name="settings.LEAD.contactAttemptsInterval"
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      containerStyle={{ width: '5rem' }}
                      onChange={onChange}
                      value={value}
                      options={Array.from({ length: 7 }, (_, i) => i + 1).map(
                        (i) => ({
                          label: `${i} day${i! > 1 ? 's' : ''}`,
                          value: i,
                        }),
                      )}
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <StyledButton
          loading={saveLoading}
          onClick={handleSubmit(onSave, (e) => console.log({ onvalid: e }))}
        >
          {t('drawer.button')}
        </StyledButton>
        <Flex justifyContent="center">
          <AtiraImage width="60%" src={Images.AtiraSpaceLogoBetaMain} />
        </Flex>
      </Flex>
    </Drawer>
  );
};
