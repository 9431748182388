// @ts-nocheck
import { Navigate } from 'react-router-dom';

import { UserRoles } from '../model/user/types/UserRoles';
import { useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';

interface AtiraRouteAdminProps {
  component?: React.ReactNode;
  redirect?: string;
}

/** Make sure only logged-in users can go to props.component */
export const AtiraRouteAdmin: React.FC<AtiraRouteAdminProps> = ({
  component,
  redirect,
}) => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const isLoggedIn = Boolean(loggedInUser?._id);

  const isAdmin = Boolean(loggedInUser?.roles.includes(UserRoles.ADMIN));

  if (!isLoggedIn || !isAdmin) {
    return <Navigate to={'/'} replace />;
  }

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return component;
};
