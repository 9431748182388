import { AppLangs } from './i18n';

export function setLangugeAndFont() {
  const params = new URLSearchParams(window.location.search);
  const langParameter = params.get('lang');

  const urlLang = Object.values(AppLangs).find((l) => l === langParameter);

  const langToUse =
    urlLang ||
    ((window.localStorage.getItem('atiraspace_lang') ||
      AppLangs.EN) as AppLangs);

  const direction = langToUse === AppLangs.AR ? 'rtl' : 'ltr';

  const doc = document.documentElement;
  doc.setAttribute('dir', direction);
  doc.setAttribute('lang', langToUse);

  switch (langToUse) {
    case AppLangs.AR: {
      doc.classList.remove('atira-english-font');
      doc.classList.add('atira-arabic-font');
      break;
    }

    default:
      doc.classList.remove('atira-arabic-font');
      doc.classList.add('atira-english-font');
  }
}
