import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { add } from 'date-fns';
import { isDate } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateEntryDto } from '../../../model/entry/dto/UpdateEntryDto';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { standardDate } from '../../../utils/Date';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 7rem;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  border-radius: 0.3rem;
  width: 7rem;
`;

type EntryDetailsDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
};

export const EntryDetailsDrawer: React.FC<EntryDetailsDrawerProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { control, handleSubmit, reset, getValues } = useForm<UpdateEntryDto>();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const entryDate = standardDate(entry?.createdAt || '');

  const onSaveDescription = async () => {
    try {
      setLoading(true);

      const dto = getValues();

      if (dto.description !== entry?.description) {
        await dispatch(
          entryActions.updateEntryById({
            entryId: entry!._id,
            userId,
            description: dto.description,
          } as UpdateEntryDto),
        ).unwrap();

        await dispatch(
          entryActions.getEntryById({ entryId: entry!._id, userId }),
        ).unwrap();
      }

      AtiraToast.success(t('entries.table.detailsModal.textarea.success'));
      onClose();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && entry) {
      reset({ description: entry.description || '' });
    }
  }, [entry, isOpen, reset]);

  return (
    <Drawer title={t('common.details')} onClose={onClose} open={isOpen}>
      <Flex flexDirection="column" gap="m">
        {entry?.markedForDeletion ? (
          <Text color="red" align="center">
            {t('entries.delete.modal.notice.title', {
              date: standardDate(
                add(new Date(entry?.markedForDeletionAt || ''), {
                  days: 30,
                }),
              ),
            })}
          </Text>
        ) : null}
        <StyledFlex>
          <BoldText>{t('common.kind')}:</BoldText>

          <ValueText>{t(`entries.table.entry.${entry?.kind}`)}</ValueText>
        </StyledFlex>

        {entry?.parentForm?.title ? (
          <StyledFlex>
            <BoldText>{t('entries.table.header.form_title')}:</BoldText>

            <ValueText>{entry?.parentForm.title}</ValueText>
          </StyledFlex>
        ) : null}

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.details')}</BoldText>

          {Object.entries(entry?.fields || {}).map(([key, value]) =>
            value ? (
              <StyledFlex key={key}>
                <Flex alignItems="center" gap="s">
                  <AtiraIcon icon={faCircle} color="main" size="xs" />
                  <BoldText>{t(`common.${key}`)}:</BoldText>
                </Flex>

                <ValueText>
                  {isDate(value) ? standardDate(value, true) : value}
                </ValueText>
              </StyledFlex>
            ) : null,
          )}
        </Flex>

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.info')}</BoldText>

          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('entries.table.header.submit_time')}:</BoldText>
            </Flex>

            <ValueText>{entryDate}</ValueText>
          </StyledFlex>

          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('entries.table.detailsModal.id')}:</BoldText>
            </Flex>

            <ValueText>{entry?._id}</ValueText>
          </StyledFlex>
        </Flex>

        <hr />

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.description')}</BoldText>

          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <StyledTextarea
                value={value}
                onChange={onChange}
                placeholder={t(
                  'entries.table.detailsModal.textarea.placeholder',
                )}
                rows={3}
              />
            )}
          />
        </Flex>

        <StyledButton
          loading={loading}
          onClick={handleSubmit(onSaveDescription)}
          title={t('common.save')}
        />
      </Flex>
    </Drawer>
  );
};
