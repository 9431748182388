import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Spinner } from '../../components/Spinner';
import { SubHeader } from '../../components/SubHeader';
import { SearchUsersUserDto } from '../../model/admin/dto/SearchUsersUserDto';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { AdminSearchUserRow } from './components/AdminSearchUserRow';
import { AdminSearchUsersDetailsModal } from './components/AdminSearchUsersDetailsModal';

export const AdminSearchUsers: React.FC = () => {
  const [keyword, setKeyword] = useState('');
  const [userDetailsModalVisible, setUserDetailsModalVisible] = useState(false);
  const [user, setUser] = useState<SearchUsersUserDto | null>(null);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const users = useAppSelector(adminSliceSelectors.selectUsers);

  const searchLoading = useAppSelector(adminSliceSelectors.searchUsersLoading);

  const fetchUsers = useCallback(() => {
    dispatch(adminActions.searchUsers({ userId, keyword: '' }));
  }, [dispatch, userId]);

  const debouncedSearch = useCallback(
    debounce(async () => {
      try {
        if (keyword) {
          await dispatch(
            adminActions.searchUsers({
              keyword,
              userId,
            }),
          ).unwrap();
        } else {
          fetchUsers();
        }
      } catch (e: any) {
        AtiraToast.apiError(e);
        console.log(e);
      }
    }, 1000),
    [dispatch, userId, keyword],
  );

  const onSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setKeyword(target.value);
    debouncedSearch();
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Flex flexDirection="column" flex={1}>
      <SubHeader title={t('common.users')} showButton={false} />

      <Flex flexDirection="column" gap="m" padding="m" flex={1}>
        <Flex>
          <Input
            width="15rem"
            value={keyword}
            onChange={onSearch}
            placeholder={t('common.search')}
          />
        </Flex>

        <Flex flexDirection="column" gap="s" flex={1}>
          {!searchLoading ? (
            users.map((user) => (
              <AdminSearchUserRow
                key={user.user?._id}
                setUser={setUser}
                user={user}
                setDetailsModalVisible={setUserDetailsModalVisible}
              />
            ))
          ) : (
            <Spinner size="3rem" />
          )}
        </Flex>
      </Flex>

      <AdminSearchUsersDetailsModal
        isOpen={userDetailsModalVisible}
        onClose={() => setUserDetailsModalVisible(false)}
        user={user}
      />
    </Flex>
  );
};
