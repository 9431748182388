import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { TableProps } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { AtiraTable } from '../../components/table/AtiraTable';
import { PageMeta } from '../../model/meta/PageMeta';
import { TaskKind } from '../../model/task/types/TaskKind.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { taskSliceSelectors } from '../../redux/task/task.selector';
import { taskActions } from '../../redux/task/task.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { createTasksColumns } from './columns/createTasksTablesColumns';
import { TaskTableHeader } from './components/TasksTableHeader';
import { useTaskContext } from './task-context';

const TableWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  gap: ${Spacing.s};

  .task-red {
    background-color: ${({ theme }) => theme.red} !important;
  }
  .task-orange {
    background-color: ${({ theme }) => theme.orange} !important;
  }
  .task-green {
    background-color: ${({ theme }) => theme.green} !important;
  }

  .contact-task {
    background-color: ${({ theme }) => theme.lightMain};
  }

  .contact-task:hover {
    background-color: ${({ theme }) => theme.main} !important;
  }

  .kanban-task {
    background-color: ${({ theme }) => theme.lightAccent};
  }

  .kanban-task:hover {
    background-color: ${({ theme }) => theme.accent} !important;
  }

  .lead-task {
    background-color: ${({ theme }) => theme.darkSub};
  }

  .lead-task:hover {
    background-color: ${({ theme }) => theme.darkerSub} !important;
  }
`;

export const TasksTable: React.FC = () => {
  const allTasks = useAppSelector(taskSliceSelectors.selectAllTasks);
  const allTasksMeta = useAppSelector(taskSliceSelectors.selectAllTasksMeta);
  const taskPage = useAppSelector(taskSliceSelectors.selectTasksPage);
  const taskPageSize = useAppSelector(taskSliceSelectors.selectTasksPageSize);
  const loading = useAppSelector(taskSliceSelectors.selectAllTasksLoading);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setTask,
    setDeleteTaskVisible,

    setLeadTasksReadVisible,
    setLeadTaskUpdateVisible,

    setContactTaskUpdateVisible,
    setContactTasksReadVisible,

    setKanbanTaskUpdateVisible,
    setKanbanTasksReadVisible,
  } = useTaskContext();

  const columns = useMemo(
    () =>
      createTasksColumns(TaskKind.LEAD, {
        deleteTask: (task) => {
          setTask(task);
          setDeleteTaskVisible(true);
        },
        previewTask: (task) => {
          setTask(task);
          if (task.kind === TaskKind.LEAD) {
            setLeadTasksReadVisible(true);
          } else if (task.kind === TaskKind.CONTACT) {
            setContactTasksReadVisible(true);
          } else if (task.kind === TaskKind.KANBAN) {
            setKanbanTasksReadVisible(true);
          }
        },
        updateTask: (task) => {
          setTask(task);
          if (task.kind === TaskKind.LEAD) {
            setLeadTaskUpdateVisible(true);
          } else if (task.kind === TaskKind.CONTACT) {
            setContactTaskUpdateVisible(true);
          } else if (task.kind === TaskKind.KANBAN) {
            setKanbanTaskUpdateVisible(true);
          }
        },
        t,
      }) as TableProps['columns'],
    [
      setTask,
      setDeleteTaskVisible,
      setLeadTaskUpdateVisible,
      setLeadTasksReadVisible,
      setContactTasksReadVisible,
      setKanbanTasksReadVisible,
      setContactTaskUpdateVisible,
      setKanbanTaskUpdateVisible,
      t,
    ],
  );

  const onPageChange = async (page: number, pageSize: number) => {
    dispatch(taskActions.setTasksTablePage(page));
  };

  const onTableShowSize = (curr: number, newSize: number) => {
    dispatch(taskActions.setTasksTablePageSize(newSize));
    onPageChange(1, newSize);
  };

  useEffect(() => {
    dispatch(
      taskActions.getAllTasks({
        userId,
        meta: PageMeta.create({
          page: taskPage - 1,
          count: taskPageSize,
        }),
      }),
    );
  }, [dispatch, taskPage, taskPageSize, userId]);

  return (
    <TableWrapper>
      <TaskTableHeader />

      <Flex alignItems="center" gap="l">
        <Flex alignItems="center" gap="s">
          <AtiraIcon icon={faCircle} color="lightMain" />
          <Text color="lightMain">{t('common.contact_task')}</Text>
        </Flex>

        <Flex alignItems="center" gap="s">
          <AtiraIcon icon={faCircle} color="lightAccent" />
          <Text color="lightAccent">{t('common.kanban_task')}</Text>
        </Flex>

        <Flex alignItems="center" gap="s">
          <AtiraIcon icon={faCircle} color="darkerSub" />
          <Text color="darkerSub">{t('common.lead_task')}</Text>
        </Flex>
      </Flex>

      <AtiraTable
        data={allTasks}
        columns={columns}
        loading={loading}
        rowSelection={undefined}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          pageSize: taskPageSize,
          current: taskPage,
          total: allTasksMeta?.total,
          onChange: onPageChange,
          showSizeChanger: true,
          onShowSizeChange: onTableShowSize,
        }}
        size="small"
      />
    </TableWrapper>
  );
};
