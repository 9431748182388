import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const SectionTitle = styled(Text)`
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
  padding: ${Spacing.m};
`;

const InnerContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

const LeftSideWrapper = styled(Flex)`
  gap: ${Spacing.xl};
  align-items: center;
  width: 100%;

  @media (min-width: 1200px) {
    width: 75%;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 1.5rem;

  @media (min-width: 1200px) {
    font-size: 1.75rem;
  }
`;

const DeleteAccountButton = styled(Button)`
  background-color: #dc3545;
  font-size: 0.9rem;
  transition: all 0.3s;
  padding: ${Spacing.s} ${Spacing.xl};
  flex-grow: 1;

  &:hover {
    background-color: #bb2d3b;
  }
`;

export const ProfileDeleteAccount: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <SectionTitle>{t('profile.title.delete_account')}</SectionTitle>

      <Container>
        <InnerContainer>
          <LeftSideWrapper>
            <AtiraIcon icon={faTriangleExclamation} size="5x" color="main" />

            <Flex flexDirection="column" gap="s">
              <Title>{t('profile.delete_account.title')}</Title>

              <Text>{t('profile.delete_account.paragraph')}</Text>
            </Flex>
          </LeftSideWrapper>

          <DeleteAccountButton
            title={t('profile.delete_account.button')}
            disabled
          />
        </InnerContainer>
      </Container>
    </Section>
  );
};
