import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../components/AtiraDatePicker';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { KanbanCard } from '../../../model/kanban/KanbanCard';
import { UpdateKanbanCardDto } from '../../../model/kanban/dto/UpdateKanbanCardDto';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { comparePayloads } from '../../../utils/ComparePayloads';

const StyledDropdown = styled(DropDown)`
  height: 2.3rem;
`;

const StyledInput = styled(Input)`
  height: 2.3rem;
`;

const CustomFieldButton = styled(Button)`
  margin: 0;
  height: auto;
  width: 2.4rem;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
`;

const SubmitButton = styled(Button)`
  height: 2.3rem;
  width: 50%;
`;

type KanbanCardUpdateDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanCard: KanbanCard | undefined;
};

export const KanbanCardUpdateDrawer: React.FC<KanbanCardUpdateDrawerProps> = ({
  isOpen = false,
  onClose,
  kanbanCard,
}) => {
  const [kanbanCardCreateLoading, setKanbanCardCreateLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues, reset, watch } =
    useForm<UpdateKanbanCardDto>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customFields',
  });

  const userKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const title = watch('title');
  const columnId = watch('columnId');
  const kanbanColumn = userKanban?.columns?.find(
    (c) => c._id === kanbanCard?.parentId,
  );

  const onCreateKanbanCrad = async () => {
    try {
      if (!kanbanCard) {
        return;
      }

      setKanbanCardCreateLoading(true);

      const dto = getValues();

      const { kanbanId, columnId, cardId, ...modifiableDto } = dto;

      const hasCustomFieldsChanged = !isEqual(
        kanbanCard.customFields,
        dto.customFields,
      );

      const updatedDto = comparePayloads(
        kanbanCard,
        modifiableDto,
      ) as UpdateKanbanCardDto;

      if (updatedDto.closingDate) {
        updatedDto.closingDate = new Date(updatedDto.closingDate);
      }

      const payload = {
        ...updatedDto,
        ...(hasCustomFieldsChanged
          ? { customFields: [...dto.customFields!] }
          : {}),
      };

      if (!isEmpty(payload)) {
        await dispatch(
          kanbanActions.updateKanbanCard({
            ...payload,
            userId,
            kanbanId: userKanban?._id!,
            columnId: columnId,
            cardId,
          }),
        ).unwrap();
      }

      onClose();
      AtiraToast.success(t('deals.drawer.update.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setKanbanCardCreateLoading(false);
    }
  };

  useEffect(() => {
    reset({
      title: kanbanCard?.title || '',
      kanbanId: userKanban?._id || '',
      columnId: kanbanCard?.parentId || '',
      cardId: kanbanCard?._id || '',
      note: kanbanCard?.note || '',
      closingDate: kanbanCard?.closingDate
        ? new Date(kanbanCard?.closingDate)
        : undefined,
      amount: kanbanCard?.amount || '',
      customFields: kanbanCard?.customFields || [],
    });
  }, [kanbanCard, reset, userKanban]);

  if (!kanbanCard) {
    return null;
  }

  return (
    <Drawer
      destroyOnClose
      title={t('deals.drawer.edit.title')}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('deals.drawer.input.deal_name')}
            />
          )}
        />

        <StyledDropdown
          onChange={noop}
          showSearch
          filterOption={false}
          value={
            kanbanCard.entry?.fields.name ||
            kanbanCard.entry?.fields.email ||
            kanbanCard.entryId
          }
          title={t('deals.drawer.input.contact_name')}
          disabled
        />

        <Controller
          name="columnId"
          control={control}
          render={({ field: { onChange, value } }) => {
            const isCardInFirstThreeColumns =
              userKanban?.columns &&
              userKanban.columns.findIndex(({ _id }) => _id === value) < 3;

            const isCardInLastThreeColumns =
              userKanban?.columns &&
              userKanban.columns.findIndex(({ _id }) => _id === value) >=
                userKanban.columns.length - 3;

            const filteredColumns = userKanban?.columns?.filter(
              (column, index) =>
                isCardInFirstThreeColumns ? index < 3 : index >= 3,
            );

            return (
              <StyledDropdown
                defaultValue={{
                  label: kanbanColumn?.name,
                  value: kanbanColumn?._id,
                }}
                value={value}
                options={filteredColumns?.map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))}
                onChange={onChange}
                title={t('common.stage')}
                disabled={isCardInLastThreeColumns}
              />
            );
          }}
        />

        <Flex position="relative">
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                title={t('common.amount')}
                icon={faDollarSign}
              />
            )}
          />
        </Flex>

        <Controller
          name="closingDate"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Flex gap="s" flexDirection="column">
                <AtiraDatePicker
                  onChange={(date) => onChange(date)}
                  type="date"
                  value={value || null}
                  id="date"
                  title={t('deals.drawer.input.closing_date')}
                />
              </Flex>
            );
          }}
        />

        <Controller
          name="note"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('common.note')}
              type="text"
            />
          )}
        />

        <Flex width={'100%'} flexDirection="column" gap="s">
          <Flex marginBottom="s" marginTop="s" flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight={600}>{t('common.custom_fields')}</Text>

              <Tooltip title={t('common.tooltip.add_new_field')}>
                <CustomFieldButton
                  icon={faPlus}
                  iconWidth="2x"
                  disabled={fields.length >= 3}
                  onClick={() => append({ label: '', value: '' })}
                />
              </Tooltip>
            </Flex>

            <Text wordBreak="break-word" color="gray" fontSize="s">
              {t('common.custom_fields.note')}
            </Text>
          </Flex>

          <Flex gap="m" flexDirection="column" width={'100%'}>
            {fields.map((field, index) => (
              <Flex flexDirection="column" gap="s" key={field.id}>
                <Controller
                  control={control}
                  name={`customFields.${index}.label`}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      border="0"
                      placeholder={t('common.input.edit_label')}
                      maxLength={12}
                      padding="initial 0"
                    />
                  )}
                />

                <Flex justifyContent="space-between" gap="s">
                  <Controller
                    control={control}
                    name={`customFields.${index}.value`}
                    render={({ field: { value, onChange } }) => (
                      <StyledInput
                        value={value}
                        onChange={onChange}
                        placeholder={t('common.input.edit_value')}
                        maxLength={280}
                      />
                    )}
                  />

                  <Tooltip title={t('common.tooltip.remove_this_field')}>
                    <CustomFieldButton
                      icon={faMinus}
                      iconWidth="2x"
                      onClick={() => remove(index)}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex>
          <SubmitButton
            onClick={handleSubmit(onCreateKanbanCrad)}
            loading={kanbanCardCreateLoading}
            disabled={!title || !columnId}
          >
            {t('common.update')}
          </SubmitButton>
        </Flex>
      </Flex>
    </Drawer>
  );
};
