import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../../components/AtiraTabs';
import { Drawer } from '../../../components/Drawer';
import { blogsActions } from '../../../redux/blogs/blogs.slice';
import { useAppDispatch } from '../../../redux/store';
import { Spacing } from '../../../theme/Spacing';
import { NotificationsBlogTab } from './NotificationsBlogTab';
import { NotificationsUpdatesTab } from './NotificationsUpdatesTab';

const StyledTabs = styled(AtiraTabs)`
  margin-top: ${Spacing.s};

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
    justify-content: space-between !important;
  }

  .ant-tabs-tab {
    flex-grow: 1;
    justify-content: center;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

type NotificationsDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(blogsActions.getBlogs());
  }, [dispatch]);

  return (
    <StyledDrawer
      title={t('header.bell.drawer.title')}
      onClose={onClose}
      open={open}
      width={'30rem'}
    >
      <StyledTabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: t('header.bell.drawer.articles_news'),
            children: <NotificationsBlogTab />,
          },
          {
            key: '2',
            label: t('header.bell.drawer.products_updates'),
            children: <NotificationsUpdatesTab />,
          },
        ]}
      />
    </StyledDrawer>
  );
};
