import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { taskActions } from '../../redux/task/task.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { TasksTable } from './TasksTable';
import { TasksDrawers } from './components/TasksDrawers';
import { TaskContextProvider } from './task-context';

export const TasksRoute: React.FC = () => {
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(taskActions.getTasksSettings({ userId }));
  }, [dispatch, userId]);

  return (
    <TaskContextProvider>
      <Flex flexDirection="column">
        <SubHeader
          title={t('common.tasks')}
          icon={faPlus}
          buttonTitle={t('common.create')}
          onClick={() => {}}
          enabled={false}
        />
        <TasksTable />
        <TasksDrawers />
      </Flex>
    </TaskContextProvider>
  );
};
