import React from "react";
import { Drawer } from "../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Entry } from "../../../model/entry/Entry";
import { AtiraTabs } from "../../../components/AtiraTabs";
import { LeadTasksReadTab } from "./lead-tasks/LeadTasksReadTab";
import { ContactTasksReadTab } from "./contact-tasks/ContactTasksReadTab";
import styled from "styled-components";
import { Spacing } from "../../../theme/Spacing";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`
const StyledTabs = styled(AtiraTabs)`
  margin-top: ${Spacing.m};

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    flex-grow: 1;
    justify-content: center;
  }
`

type TasksReadDrawerProps = {
  entry?: Entry | null
  isOpen: boolean;
  onClose: VoidFunction;
}

export const TasksReadDrawer: React.FC<TasksReadDrawerProps> = ({ entry, isOpen = false, onClose }) => {
  const { t } = useTranslation()
  
  return <StyledDrawer title={t('common.tasks')} open={isOpen} onClose={onClose} width={'30rem'}>
      <StyledTabs items={[
      { key: 'lead', label: t('common.lead_tasks'), children: <LeadTasksReadTab entryKind={entry?.kind} tasks={entry?.leadTasks} /> },
      { key: 'contact', label: t('common.contact_tasks'), children: <ContactTasksReadTab tasks={entry?.contactTasks} /> }
    ]} />
  </StyledDrawer>
}
