import { Row, RowProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  margin: 0 !important;
  width: 100%;
`;

interface AtiraRowProps extends RowProps {
  children?: React.ReactNode;
}

export const AtiraRow: React.FC<AtiraRowProps> = ({ children, ...props }) => {
  return <StyledRow {...props}>{children}</StyledRow>;
};
