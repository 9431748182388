import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { User } from '../../../model/user/User';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { usePermissionsContext } from '../permissions-context';

const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.s};
  border-radius: ${Rounded.md};
  width: 100%;
`;

const InfoWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.m};
`;

const ImageWrapper = styled(Flex)`
  height: 3rem;
  width: 3rem;
  border-radius: ${Rounded.circle};
  overflow: hidden;
  border: ${({ theme }) => `2px solid ${theme.main}`};
`;

const IconWrapper = styled.div`
  .svg-inline--fa {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.main};
    font-size: 5rem;
  }
`;

const ActionsWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.l};
  margin-inline-end: ${Spacing.m};
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  font-size: 1.5rem;
`;

interface MemberRowProps {
  member?: User;
}

export const MemberRow: React.FC<MemberRowProps> = ({ member }) => {
  const { setRemoveMemberModalVisible, setCurrentMember } =
    usePermissionsContext();

  const onRemoveMember = () => {
    setRemoveMemberModalVisible(true);
    setCurrentMember(member!);
  };

  return (
    <Container>
      <InfoWrapper>
        <ImageWrapper>
          {member?.picture ? (
            <AtiraImage src={member.picture} />
          ) : (
            <IconWrapper>
              <AtiraIcon icon={faUserCircle} />
            </IconWrapper>
          )}
        </ImageWrapper>

        <Text>{member?.name}</Text>

        {member?.inviteInfo?.respondedToAt ? (
          <Text fontSize="s">
            {t('permissions.member.member-since', {
              date: standardDate(member.inviteInfo.respondedToAt),
            })}
          </Text>
        ) : null}
      </InfoWrapper>

      <ActionsWrapper>
        <Tooltip title={t('common.send_email')}>
          <StyledButton disabled icon={faEnvelope} />
        </Tooltip>

        <Tooltip title={t('common.remove')}>
          <StyledButton onClick={onRemoveMember} icon={faTrash} />
        </Tooltip>
      </ActionsWrapper>
    </Container>
  );
};
