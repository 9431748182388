import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { ForgotPasswordTokenDto } from '../../../model/user/dto/ForgotPasswordTokenDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledVerificationInput = styled(VerificationInput)`
  div {
    color: red !important;
  }
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

type ForgotPasswordTokenProps = {
  updateStep: VoidFunction;
};

export const ForgotPasswordToken: React.FC<ForgotPasswordTokenProps> = ({
  updateStep,
}) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordTokenDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(
        userActions.forgotPasswordToken({ token: getValues('token') }),
      ).unwrap();

      AtiraToast.success(t('resetpassword.token.success'));
      updateStep();
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width={'100%'}
      gap="s"
    >
      <Text color="gray" align="center">
        {t('resetpassword.token.title')}
      </Text>
      <Controller
        name="token"
        control={control}
        rules={{ required: true, minLength: 6 }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledVerificationInput
            placeholder={'-'}
            onChange={onChange}
            value={value}
            inputProps={{
              style: {
                border: errors.token ? '1px solid red' : 'none',
              },
            }}
          />
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={loading}>
        {t('resetpassword.token.button')}
      </StyledButton>
    </Flex>
  );
};
