import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const entryState = (state: RootState) => state.entry;

const selectEntryById = createSelector(
  [entryState, (_, entryId: string) => entryId],
  (state, entryId) => state.latestEntries.find(({ _id }) => _id === entryId),
);

const selectLatestEntries = createSelector(
  entryState,
  (state) => state.latestEntries || [],
);

const selectLatestEntriesLoading = createSelector(
  entryState,
  (state) => state.latestEntriesLoading,
);

const selectLatestEntriesMeta = createSelector(
  entryState,
  (state) => state.latestEntriesMeta,
);

const selectUserEntryTableSettings = createSelector(
  entryState,
  (state) => state.entryTableSettings,
);

const selectSearchEntriesLoading = createSelector(
  entryState,
  (state) => state.searchEntriesLoading,
);

const selectEntriesLegibleForKanbanCard = createSelector(
  entryState,
  (state) => state.entiresLegibleForKanbanCard || [],
);

const selectEntriesTablePageSize = createSelector(
  entryState,
  (state) => state.entriesTablePageSize || 10,
);

const selectEntriesTablePage = createSelector(
  entryState,
  (state) => state.entriesTablePage || 1,
);

export const entrySliceSelectors = {
  selectEntryById,
  selectLatestEntries,
  selectLatestEntriesLoading,
  selectLatestEntriesMeta,
  selectUserEntryTableSettings,
  selectSearchEntriesLoading,
  selectEntriesLegibleForKanbanCard,
  selectEntriesTablePageSize,
  selectEntriesTablePage,
};
