import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Spacing } from '../../theme/Spacing';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { ParagraphProps, Text } from '../Text';

const StyledAntdModal = styled(AntdModal)`
  .ant-modal-close {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.gray};
`;

const StyledTitle = styled(Text)`
  font-size: 1.5rem !important;
  font-weight: bold;
  text-align: start !important;
  color: ${({ theme }) => theme.gray};
`;

const StyledHr = styled.hr`
  height: 1.5px;
  opacity: 1;
  background-color: ${({ theme }) => theme.lightgray};
  width: 100%;
  border: 0;
  margin-bottom: ${Spacing.m};
`;

interface ModalProps extends AntdModalProps {
  children: React.ReactNode;
  title?: string;
  titleProps?: ParagraphProps;
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
  open: boolean;
  onClose?: VoidFunction;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  content,
  overlay,
  title,
  titleProps = {},
  open,
  className,
  ...props
}) => {
  const { theme } = useTheme();
  return (
    <StyledAntdModal
      className={className}
      styles={{
        content: { backgroundColor: theme.white },
      }}
      open={open}
      onCancel={onClose}
      footer={null}
      title={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor={theme.white}
        >
          <StyledTitle {...titleProps}>{title}</StyledTitle>
          {props.maskClosable !== false ? (
            <CloseButton icon={faX} iconWidth="lg" onClick={onClose} />
          ) : null}
        </Flex>
      }
      {...props}
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <StyledHr />

        {children}
      </Flex>
    </StyledAntdModal>
  );
};
