import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Switch } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';

const DenyButton = styled(Button)`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.black};
  width: fit-content;
  height: 2.5rem;
  color: ${({ theme }) => theme.black};

  &:hover {
    background-color: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
  }
`;

const StyledWarningModal = styled(Modal)`
  z-index: 1000000;
`;

type EntryDeleteModalProps = {
  onConfirm: ATVoidFunction<boolean>;
  loading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  title?: string;
};

export const EntryDeleteModal: React.FC<EntryDeleteModalProps> = ({
  onConfirm,
  loading,
  isOpen,
  onClose,
  title,
}) => {
  const [isInstantDelete, setIsInstantDelete] = useState(false);
  const { t } = useTranslation(undefined, { keyPrefix: 'entries.delete' });

  return (
    <StyledWarningModal
      destroyOnClose={true}
      open={isOpen}
      onClose={onClose}
      title={title || t('modal.title')}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="xl"
      >
        <Flex flexDirection="column" gap="m" alignItems="center">
          <Flex width={'4rem'}>
            <AtiraIcon icon={faTriangleExclamation} size="4x" color="main" />
          </Flex>

          <Text fontSize="xm" align="center" color="darkTextColor">
            {t('modal.subtitle')}
          </Text>

          <Text align="center" color="darkerSub">
            {t('modal.description')}
          </Text>
        </Flex>

        <Flex flexDirection="column" gap="s">
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            width={'70%'}
            alignSelf="flex-start"
          >
            <Flex gap="m" alignItems="center">
              <Text fontSize="xm">{t('modal.switch.title')}</Text>
              <Switch onChange={setIsInstantDelete} />
            </Flex>
          </Flex>
          <Text color="darkerSub">{t('modal.switch.description')}</Text>
        </Flex>

        <Flex gap="xl">
          <DenyButton onClick={onClose}>{t('modal.button.no')}</DenyButton>

          <Button
            hover
            width="5rem"
            height="2.5rem"
            loading={loading}
            onClick={() => onConfirm(isInstantDelete)}
          >
            {t('modal.button.yes')}
          </Button>
        </Flex>
      </Flex>
    </StyledWarningModal>
  );
};
