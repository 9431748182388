import { useNavigate } from 'react-router-dom';

import i18n, { AppLangs } from '../i18n';

const currentLang = i18n.language;

export const useMultiLangNavigate = () => {
  const navigate = useNavigate();
  return (path: string, options?: { replace: boolean }) => {
    // const currentLang = localStorage.getItem('i18nextLng');
    const newPath = `${currentLang === AppLangs.EN ? '' : `/${currentLang}`}${path}`;
    navigate(newPath, options);
  };
};
