import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Spacing } from '../../../../theme/Spacing';

const StyledButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  color: ${({ theme }) => theme.white};
`;

export const PendingMemberPENDING: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" gap="l">
      <Flex alignItems="flex-start" gap="s">
        <StyledButton backgroundColor="red">
          <AtiraIcon icon={faX} />

          {t('common.cancel')}
        </StyledButton>
        <Tooltip title={t('permissions.invite.member.status-pending')}>
          <Flex cursor="pointer">
            <AtiraIcon icon={faCircleExclamation} color="gray" />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
