import { AtiraRoute } from '../AtiraRoute';
import { Dashboard } from '../dashboard/Dashboard';
import { EntriesRouteWithProvider } from '../entries/EntriesRouteWithProvider';
import { EntryReadDrawer } from '../entries/EntryReadDrawer';
import { FormCreateEdit } from '../form/FormCreateEdit';
import { UserForms } from '../form/UserForms';
import { KanbanRoute } from '../kanban/KanbanRoute';
import { ProductsRoute } from '../product/ProductsRoute';
import { Reports } from '../reports/Reports';
import { TasksRoute } from '../tasks/TasksRoute';

export const PrivateBasicRouter = [
  {
    path: '/',
    element: <AtiraRoute redirect="dashboard" />,
  },
  {
    path: '/dashboard',
    element: <AtiraRoute component={<Dashboard />} />,
  },
  {
    path: '/forms',
    element: <AtiraRoute component={<UserForms />} />,
  },
  {
    path: '/entries',
    element: <AtiraRoute component={<EntriesRouteWithProvider />} />,
  },
  {
    path: '/entries/view/:id',
    element: <AtiraRoute component={<EntryReadDrawer />} />,
  },
  {
    path: '/deals',
    element: <AtiraRoute component={<KanbanRoute />} />,
  },
  {
    path: '/reports',
    element: <AtiraRoute component={<Reports />} />,
  },
  {
    path: '/tasks',
    element: <AtiraRoute component={<TasksRoute />} />,
  },
  {
    path: '/products',
    element: <AtiraRoute component={<ProductsRoute />} />,
  },
  {
    path: '/forms/create',
    element: <AtiraRoute component={<FormCreateEdit />} />,
  },
  {
    path: '/forms/edit',
    element: <AtiraRoute component={<FormCreateEdit />} />,
  },
];
