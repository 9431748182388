import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { useTranslation } from 'react-i18next';

import { AtiraEmpty } from '../../components/AtiraEmpty';
import { Flex } from '../../components/Flex';

export const Reports: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      flex={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="m"
    >
      <AtiraEmpty description={t('reports.coming_soon')} icon={faChartPie} />
    </Flex>
  );
};
