import { detailedDiff } from 'deep-object-diff';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export const comparePayloads = (source: object, target: object) => {
  const diffResult = detailedDiff(source, target);

  const filteredDeletedPayload = omitBy(diffResult.deleted, isNil);

  const updatedPayload = {
    ...filteredDeletedPayload,
    ...diffResult.added,
    ...diffResult.updated,
  };

  return updatedPayload;
};
