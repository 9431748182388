import { EntryKind } from '../../../model/entry/types/EntryKind.enum';

export const getTableCellClassName = (entryKind: EntryKind) => {
  switch (entryKind) {
    case EntryKind.CONTACT:
      return 'contact-cell';
    case EntryKind.LEAD:
      return 'lead-cell';
    case EntryKind.JUNK:
      return 'junk-cell';
    default:
      return 'lead-cell';
  }
};
