import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const thresholddState = (state: RootState) => state.threshold;

const selectThresholdStats = createSelector(
  thresholddState,
  (state) => state.threshold,
);

const selectThresholdLoading = createSelector(
  thresholddState,
  (state) => state.thresholdLoading,
);

export const thresholdSliceSelectors = {
  selectThresholdStats,
  selectThresholdLoading,
};
