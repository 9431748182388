import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const permissionState = (state: RootState) => state.permission;

const selectInviteLoading = createSelector(
  permissionState,
  (state) => state.inviteLoading,
);

const selectInvitedMembers = createSelector(
  permissionState,
  (state) => state.invitedMembers || [],
);
const selectedInvitedMembersLoading = createSelector(
  permissionState,
  (state) => state.invitedMembersLoading,
);

const selectCurrentInvite = createSelector(
  permissionState,
  (state) => state.currentInvite,
);

const selectMembers = createSelector(
  permissionState,
  (state) => state.members || [],
);

export const permissionSliceSelectors = {
  selectInviteLoading,
  selectInvitedMembers,
  selectedInvitedMembersLoading,
  selectCurrentInvite,
  selectMembers,
};
