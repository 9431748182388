// ThemeContext.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { DarkTheme, Theme, ThemeType, YellowTheme } from './theme/Theme';

interface ThemeContextType {
  toggleTheme: (theme: Themes) => void;
  theme: ThemeType;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
  YELLOW = 'yellow',
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const getInitialTheme = (): ThemeType => {
    const savedTheme = window.localStorage.getItem(
      'atira-space_theme',
    ) as Themes | null;
    switch (savedTheme) {
      case Themes.DARK:
        return DarkTheme;
      case Themes.YELLOW:
        return YellowTheme;
      case Themes.LIGHT:
      default:
        return Theme;
    }
  };

  const [theme, setTheme] = useState<ThemeType>(() => getInitialTheme());

  const toggleTheme = (theme: Themes) => {
    let newTheme: ThemeType;

    switch (theme) {
      case Themes.DARK:
        newTheme = DarkTheme;
        break;
      case Themes.YELLOW:
        newTheme = YellowTheme;
        break;
      case Themes.LIGHT:
      default:
        newTheme = Theme;
        break;
    }

    setTheme(newTheme);
    window.localStorage.setItem('atira-space_theme', theme);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
