export enum AtriaTourClasses {
  MENU_BUTTON = 'ant-layout-sider-trigger',
  MENU_DASHBOARD = 'atira-menu-dashboard',
  MENU_FORMS = 'atira-menu-forms',
  MENU_ENTRIES = 'atira-menu-entries',
  MENU_DEALS = 'atira-menu-deals',
  MENU_REPORTS = 'atira-menu-reports',
  MENU_TASKS = 'atira-menu-tasks',
  MENU_PRODUCTS = 'atira-menu-products',
  WIDGET_STATS = 'widget-stats',
  WIDGET_LATEST_ENTRIES = 'widget-latest-entries',
}
