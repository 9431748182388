export enum Rounded {
  none = '0',
  sm = '0.25rem',
  md = '0.375rem',
  lg = '0.5rem',
  xl = '1rem',
  xxl = '2rem',
  pill = '50rem',
  circle = '50%',
}
