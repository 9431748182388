import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const kanbanState = (state: RootState) => state.kanban;

const selectUserDefaultKanban = createSelector(
  kanbanState,
  (state) => state.kanban,
);

const selectUserDefaultKanbanLoading = createSelector(
  kanbanState,
  (state) => state.kanbanLoading,
);

export const kanbanSliceSelectors = {
  selectUserDefaultKanban,
  selectUserDefaultKanbanLoading,
};
