import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar.json';
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

export enum AppLangs {
  AR = 'ar',
  EN = 'en',
  TR = 'tr',
}

const resources: Record<string, Resource> = {
  en: { translation: translationEN },
  ar: { translation: translationAR },
  tr: { translation: translationTR },
};

const params = new URLSearchParams(window.location.search);
const langParameter = params.get('lang');

const urlLang = Object.values(AppLangs).find((l) => l === langParameter);

const langToUse =
  urlLang ||
  ((window.localStorage.getItem('atiraspace_lang') || AppLangs.EN) as AppLangs);
window.localStorage.setItem('atiraspace_lang', langToUse);

i18n.use(initReactI18next).init({
  resources,
  lng: langToUse,
  fallbackLng: AppLangs.EN,
  interpolation: { escapeValue: false },
});

i18n.on('languageChanged', (lang) => {
  // We need to reload the page to apply the different font
  window.location.reload();
  window.localStorage.setItem('atiraspace_lang', lang);
});

export const removeLangParamFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('lang');
  const newUrl = params.toString()
    ? `${window.location.pathname}?${params.toString()}`
    : window.location.pathname;
  window.history.replaceState({}, '', newUrl);
};

export default i18n;
