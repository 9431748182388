import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const SectionTitle = styled(Text)`
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
  padding: ${Spacing.m};
  flex-wrap: wrap;
`;

const RightSideWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${Spacing.m};
  order: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 30%;
    order: 2;
  }
`;

const LeftSideWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.l};
  flex-grow: 1;
  order: 2;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    order: 1;
  }
`;

const SaveButton = styled(Button)`
  height: 2.5rem;
  width: 8.5rem;
  font-size: 0.9rem;
  margin: -${Spacing.m} 0 0 auto;
  padding: 0 ${Spacing.s};
`;

const ChangeButton = styled(Button)`
  font-size: 1.1rem;
  background: ${(props) => props.theme.lightSub};
  padding: ${Spacing.s};
  margin: 0;
  transition: border-bottom 0.3s;
  border: 1px solid ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.black};
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    border: 1px solid ${(props) => props.theme.darkSub};
  }
`;

const StyledInput = styled(Input)`
  width: 20rem;
  height: 3rem;
  border: 1px solid ${(props) => props.theme.darkSub};
  font-size: 1rem;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.black};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 3.6rem;
  }
`;

export const ProfileChangePassword: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <SectionTitle>{t('profile.title.change_password')}</SectionTitle>

      <Container>
        <LeftSideWrapper>
          <StyledInput
            type="text"
            placeholder={t('profile.change_password.old_password')}
          />

          <StyledInput
            type="text"
            placeholder={t('profile.change_password.new_password')}
          />
          <StyledInput
            type="text"
            placeholder={t('profile.change_password.confirm_password')}
          />

          <SaveButton title={t('common.save_changes').toUpperCase()} disabled />
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text fontSize="xl" align="center">
            {t('profile.change_password.title')}
          </Text>
          <Text>{t('profile.change_password.paragraph')}</Text>
          <ChangeButton title={t('profile.change_password.button')} disabled />
        </RightSideWrapper>
      </Container>
    </Section>
  );
};
