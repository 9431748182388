import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { useStepContext } from '../../components/stepper/StepperContext';
import { useStepperRegisterValidation } from '../../components/stepper/useStepperRegisterValidation';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { Regexes } from '../../utils/String';

const Container = styled(Flex)`
  align-items: center;
  gap: ${Spacing.m};
  width: 100%;
  flex-wrap: wrap;
`;

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding-top: ${Spacing.l};
  border-top: 2px solid ${({ theme }) => theme.darkerSub};
  gap: ${Spacing.m};
  order: 2;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    border-right: ${({ theme }) =>
      i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
    border-left: ${({ theme }) =>
      i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};
    padding-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.xl};
    padding-left: ${i18n.language === AppLangs.AR ? Spacing.xl : '0'};
    padding-top: 0;
    border-top: 0;
    width: 63%;
    order: 1;
  }
`;

const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.s};
  order: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 35%;
    order: 2;
  }
`;

const FormInput = styled(Input)`
  width: 100%;
  height: 3rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.darkSub};
  font-size: 1rem;
  align-self: center;
  opacity: 1 !important;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    font-size: 1.2rem;
  }
`;

export const FormCreateEditStepBasicInfo: React.FC = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<CreateFormDto>();
  const { currentStep } = useStepContext();

  const location = useLocation();

  const editMode = location.pathname.includes('/edit');

  const title = watch('title');

  const canGoNext = useCallback(() => {
    return title?.length >= Lengths.MIN_3;
  }, [title]);

  useStepperRegisterValidation(currentStep, canGoNext);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  });

  return (
    <Container>
      <LeftSideWrapper>
        <Controller
          name="title"
          control={control}
          rules={{
            required: true,
            maxLength: {
              value: Lengths.NAME,
              message: t('forms.form.create.error.title_length'),
            },
            minLength: {
              value: Lengths.MIN_3,
              message: t('forms.form.create.error.title_length'),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormInput
              placeholder={t('forms.create.step.1.input.form_name.placeholder')}
              title={t('forms.create.step.1.input.form_name.label')}
              onChange={onChange}
              value={value}
              valid={!error?.message}
              errorMessage={error?.message}
              id="title"
              required
              maxLength={Lengths.NAME}
            />
          )}
        />

        <Flex gap="s" flexDirection="column">
          <Flex gap="m" justifyContent="center" width={'100%'}>
            <Controller
              control={control}
              name={`domains.${0}.content`}
              rules={{
                pattern: {
                  value: Regexes.URL_REGEX,
                  message: t('forms.form.create.error.invalid_domain'),
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormInput
                  title={t('forms.create.step.1.input.domain.title')}
                  placeholder={t(
                    'forms.create.step.1.input.domain.placeholder',
                  )}
                  valid={!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Tooltip title={t('forms.create.step.1.new_domain.tooltip')}>
              <Button
                height="3rem"
                padding="0.2rem"
                margin="auto 0 0 0"
                backgroundColor="transparent"
                color="main"
                iconWidth="2x"
                onClick={() => append({ content: '' })}
                disabled={fields.length > 2}
                icon={faPlus}
              />
            </Tooltip>
          </Flex>

          {fields.slice(1).map((field, index) => (
            <Flex gap="m" justifyContent="center" width={'100%'} key={field.id}>
              <Controller
                control={control}
                name={`domains.${index + 1}.content`}
                rules={{
                  pattern: {
                    value: Regexes.URL_REGEX,
                    message: t('forms.form.create.error.invalid_domain'),
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormInput
                    type="text"
                    placeholder={t(
                      'forms.create.step.1.input.domain.placeholder',
                    )}
                    value={value}
                    onChange={onChange}
                    valid={!error}
                    errorMessage={error?.message}
                  />
                )}
              />

              <Tooltip title={t('forms.create.step.1.delete_domain.tooltip')}>
                <Button
                  height="3rem"
                  padding="0.2rem"
                  margin="auto 0 0 0"
                  backgroundColor="transparent"
                  color="main"
                  iconWidth="2x"
                  onClick={() => remove(index + 1)}
                  icon={faTrash}
                />
              </Tooltip>
            </Flex>
          ))}
        </Flex>
      </LeftSideWrapper>

      <RightSideWrapper>
        <Text color="darkerSub">{t('forms.create.step.1.tutorial.1')}</Text>
        <Text color="darkerSub">{t('forms.create.step.1.tutorial.2')}</Text>
        <Text color="darkerSub">{t('forms.create.step.1.tutorial.3')}</Text>
      </RightSideWrapper>
    </Container>
  );
};
