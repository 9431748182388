import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';

import { KanbanColumn } from '../../model/kanban/KanbanColumn';

type GenericReturn = {
  newOrder: number;
  oldOrder: number;
  newColumns: KanbanColumn[];
};

export const getNewKanbanStateForColumnsDND = (data: {
  columns: KanbanColumn[];
  activeCol: KanbanColumn;
  overCol: KanbanColumn;
}): GenericReturn => {
  const { overCol, activeCol, columns } = data;

  const oldOrder = columns.findIndex((col) => col._id === activeCol._id);
  let newOrder = columns.findIndex((el) => el._id === overCol._id);
  newOrder = newOrder === -1 ? 0 : newOrder;

  const newColumns = arrayMove(columns, oldOrder, newOrder);

  return { newOrder, oldOrder, newColumns };
};

export const getNewColumnsStateForSameColumnDND = (data: {
  currentColumn: KanbanColumn;
  columns: KanbanColumn[];
  active: Active;
  over: Over;
}): GenericReturn => {
  const { currentColumn, over, active, columns } = data;

  const targetCol = cloneDeep(
    columns.find((col) => col._id === currentColumn._id)!,
  );

  const oldOrder = targetCol.cards!.findIndex((el) => el._id === active.id);
  let newOrder = targetCol.cards!.findIndex((el) => el._id === over.id);
  // If the new order is -1 (empty column), set it to 0
  newOrder = newOrder === -1 ? 0 : newOrder;
  targetCol.cards = arrayMove(targetCol.cards!, oldOrder, newOrder);

  const newColumns = columns.map((_col) =>
    _col._id === targetCol._id ? targetCol : _col,
  );

  return { newOrder, oldOrder, newColumns };
};

export const getNewColumnsStateForDifferentColumnsDND = (data: {
  currentColumn: KanbanColumn;
  sourceColumn: KanbanColumn;
  columns: KanbanColumn[];
  active: Active;
  over: Over;
}): GenericReturn => {
  const { currentColumn, sourceColumn, active, over, columns } = data;

  const targetCol = cloneDeep(
    columns.find((col) => col._id === currentColumn._id)!,
  );

  const sourceCol = cloneDeep(
    columns.find((col) => col._id === sourceColumn._id)!,
  );

  const oldOrder = sourceCol.cards!.findIndex((el) => el._id === active.id);
  let newOrder = targetCol.cards!.findIndex((el) => el._id === over.id);
  // If the new order is -1 (empty column), set it to 0
  newOrder = newOrder === -1 ? 0 : newOrder;

  // Remove card from source column and insert into target column
  const [movedCard] = sourceCol.cards!.splice(oldOrder, 1);
  targetCol.cards!.splice(newOrder, 0, movedCard);

  const newColumns = columns.map((_col) => {
    if (_col._id === targetCol._id) {
      return targetCol;
    } else if (_col._id === sourceCol._id) {
      return sourceCol;
    } else {
      return _col;
    }
  });

  return { newOrder, oldOrder, newColumns };
};
