import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const SectionTitle = styled(Text)`
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
  padding: ${Spacing.m};
`;

const StyledInput = styled(Input)`
  width: 20rem;
  height: 3rem;
  border: 1px solid ${(props) => props.theme.darkSub};
  font-size: 1rem;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.black};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 3.6rem;
  }
`;

export const ProfileAPIKey: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <SectionTitle>{t('profile.title.api_key')}</SectionTitle>

      <Container>
        <StyledInput placeholder={t('profile.title.api_key')} disabled />
      </Container>
    </Section>
  );
};
