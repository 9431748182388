import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../../components/AtiraDatePicker';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Lengths } from '../../../../model/shared/enum/Lengths.enum';
import { LeadTask } from '../../../../model/task/LeadTask';
import { UpdateLeadTaskDto } from '../../../../model/task/dto/UpdateLeadTaskDto';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { comparePayloads } from '../../../../utils/ComparePayloads';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const StyledTextarea = styled(Textarea)`
  max-height: 9rem;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

interface LeadTaskUpdateDrawerProps {
  leadTask?: LeadTask | null;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const LeadTaskUpdateDrawer: React.FC<LeadTaskUpdateDrawerProps> = ({
  leadTask,
  onClose,
  isOpen = false,
}) => {
  const [updateLoading, setUpdateLoading] = useState(false);

  const { t } = useTranslation();

  const { control, handleSubmit, reset, getValues } =
    useForm<UpdateLeadTaskDto>();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const dispatch = useAppDispatch();

  const updateLeadTask = async () => {
    try {
      setUpdateLoading(true);

      const dto = getValues();

      const payload = comparePayloads(leadTask!, dto) as UpdateLeadTaskDto;

      if (Object.keys(payload).length) {
        Object.assign(payload, {
          userId,
          ownerId: userId,
          taskId: leadTask?._id,
          ...(payload.reminder ? { reminder: new Date(payload.reminder) } : {}),
        });

        await dispatch(entryActions.updateLeadTask(payload)).unwrap();

        onClose();
      }

      AtiraToast.success(t('task.update.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && leadTask) {
      reset({
        name: leadTask.name || '',
        description: leadTask.description || '',
        reminder: leadTask.reminder ? new Date(leadTask.reminder) : undefined,
      });
    }
  }, [leadTask, reset, userId, isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={t('tasks.update.lead.drawer.title')}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            minLength: {
              value: Lengths.MIN_3,
              message: t('task.drawer.error.short_name'),
            },
            maxLength: Lengths.NAME,
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('common.task_name')}
              required
              valid={!error}
              errorMessage={error?.message}
              maxLength={Lengths.NAME}
            />
          )}
        />

        <Controller
          control={control}
          name="reminder"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker
              title={t('common.remind_date')}
              onChange={onChange}
              value={value || null}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          rules={{
            maxLength: {
              value: Lengths.DESCRIPTION,
              message: t('error.length.less', {
                name: t('common.description'),
                length: Lengths.DESCRIPTION,
              }),
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StyledTextarea
              rows={3}
              title={t('common.description')}
              value={value}
              onChange={onChange}
              maxLength={Lengths.DESCRIPTION}
              valid={!error}
              errorMessage={error?.message}
            />
          )}
        />

        <StyledButton
          onClick={handleSubmit(updateLeadTask)}
          loading={updateLoading}
        >
          {t('common.update')}
        </StyledButton>
      </Flex>
    </Drawer>
  );
};
