import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Spinner } from '../../../components/Spinner';
import i18n, { AppLangs } from '../../../i18n';
import { GetUserDefaultKanbanDto } from '../../../model/kanban/dto/GetUserDefaultKanbanDto';
import { KanbanCardFilterRange } from '../../../model/kanban/types/KanbanCardFilterRange.enum';
import { KanbanCardFilterSort } from '../../../model/kanban/types/KanbanCardFilterSort.enum';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const StickyFlex = styled(Flex)`
  align-items: center;
  gap: ${Spacing.m};
  position: sticky;
  left: ${i18n.language === AppLangs.AR ? 'auto' : '0'};
  right: ${i18n.language === AppLangs.AR ? '0' : 'auto'};
  margin-block: ${Spacing.m};
`;

const StyledDropdown = styled(DropDown)`
  .ant-select .ant-select-selector {
    border: 2px solid ${(props) => props.theme.transparent} !important;
  }

  width: 10rem;
  border: 0;
  border-radius: ${Rounded.md};
`;

export const KanbanHeader: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, getValues } = useForm<GetUserDefaultKanbanDto>({
    defaultValues: {
      range: KanbanCardFilterRange.ALL,
      sort: KanbanCardFilterSort.CREATED_AT,
    },
  });
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onFilter = async () => {
    try {
      setLoading(true);
      await dispatch(
        kanbanActions.getUserDefaultKanban({
          userId,
          ...omitBy(getValues(), isNil),
        }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const cardsRangeFitlerOptions = [
    {
      label: t('deals.cards.filters.time.all_deals'),
      value: KanbanCardFilterRange.ALL,
    },
    {
      label: t('deals.cards.filters.time.closing_this_month'),
      value: KanbanCardFilterRange.THIS_MONTH,
    },
    {
      label: t('deals.cards.filters.time.closing_next_month'),
      value: KanbanCardFilterRange.NEXT_MONTH,
    },
  ];

  const cardsSortOptions = [
    {
      label: t('deals.cards.sort.time.created'),
      value: KanbanCardFilterSort.CREATED_AT,
    },
    {
      label: t('deals.cards.sort.time.modified'),
      value: KanbanCardFilterSort.UPDATED_AT,
    },
    {
      label: t('deals.cards.sort.time.amount'),
      value: KanbanCardFilterSort.AMOUNT,
    },
    {
      label: t('deals.cards.sort.time.closing'),
      value: KanbanCardFilterSort.CLOSING_DATE,
    },
  ];

  return (
    <StickyFlex>
      <Controller
        control={control}
        name="range"
        render={({ field: { onChange, value } }) => (
          <StyledDropdown
            value={value}
            options={cardsRangeFitlerOptions}
            onChange={(e) => {
              onChange(e);
              onFilter();
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="sort"
        render={({ field: { onChange, value } }) => (
          <StyledDropdown
            value={value}
            options={cardsSortOptions}
            onChange={(e) => {
              onChange(e);
              onFilter();
            }}
          />
        )}
      />

      {loading ? (
        <Spinner size="2rem" containerStyle={{ margin: '0' }} />
      ) : null}
    </StickyFlex>
  );
};
