import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { AtiraUpload } from '../../../components/AtiraUpload';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';

type ProductsCreateModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

type ProductFormInputs = {
  name: string;
  price: number;
  description: string;
  image: FileList;
};

const SaveButton = styled(Button)`
  width: 7rem;
  height: 3rem;
  margin: auto;
  font-size: 1.3rem;
`;

export const ProductsCreateModal: React.FC<ProductsCreateModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<ProductFormInputs>();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('products.create_modal.title')}
    >
      {/* <Flex flexDirection="column" gap="m" width={'100%'}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input {...field} title={t('common.name')} required />
          )}
        />

        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Input {...field} title={t('common.price')} required />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Input {...field} title={t('common.description')} />
          )}
        />

        <Flex flexDirection="column" gap="s">
          <Text>{t('common.image')} </Text>
          <Controller
            control={control}
            name="image"
            render={({ field: { value, onChange } }) => (
              <AtiraUpload onChange={(f) => onChange(f)} />
            )}
          />
        </Flex>
        <SaveButton> {t('common.save')}</SaveButton>
      </Flex> */}
      <AtiraEmpty description={t('common.feature.coming_soon')} />
    </Modal>
  );
};
