import { CloneFormDto } from '../../model/form/dto/CloneFormDto';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { DeleteFormDto } from '../../model/form/dto/DeleteFormDto';
import { UpdateFormDto } from '../../model/form/dto/UpdateFormDto';
import { AtiraApi } from '../AxiosClient';

export class FormAxios {
  static async getMyForms(userId: string) {
    return AtiraApi.post(`/form/user/${userId}`, { userId }).then(
      ({ data }) => data,
    );
  }

  static async createForm(dto: CreateFormDto) {
    return AtiraApi.post(`/form/create`, dto).then(({ data }) => data);
  }

  static async deleteForm(dto: DeleteFormDto) {
    return AtiraApi.post(`/form/${dto.formId}/delete`, dto).then(
      ({ data }) => data,
    );
  }

  static updateForm(dto: UpdateFormDto) {
    return AtiraApi.patch<void>(`/form/${dto.formId}`, dto).then(
      ({ data }) => data,
    );
  }

  static cloneForm(dto: CloneFormDto) {
    return AtiraApi.post<void>(`/form/${dto.formId}/clone`, dto).then(
      ({ data }) => data,
    );
  }
}
