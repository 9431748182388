import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../../components/AtiraDatePicker';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { KanbanCard } from '../../../../model/kanban/KanbanCard';
import { CreateKanbanTaskDto } from '../../../../model/kanban/dto/CreateKanbanTaskDto';
import { kanbanActions } from '../../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { AtiraToast } from '../../../../utils/AtiraToast';

const SubmitButton = styled(Button)`
  height: 2.3rem;
  width: 50%;
`;

interface KanbanTaskCreateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanCard: KanbanCard | undefined;
}

export const KanbanTaskCreateDrawer: React.FC<KanbanTaskCreateDrawerProps> = ({
  isOpen = false,
  kanbanCard,
  onClose,
}) => {
  const [createButtonLoading, setCreateButtonLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const { control, getValues, handleSubmit, reset, watch } =
    useForm<CreateKanbanTaskDto>({
      defaultValues: {
        name: '',
        description: '',
        reminder: undefined,
        userId,
        done: false,
        kanbanCardId: kanbanCard?._id,
        ownerId: userId,
      },
    });

  const dispatch = useAppDispatch();
  const taskName = watch('name');

  const onSubmit = async () => {
    try {
      setCreateButtonLoading(true);

      const dto = getValues();

      if (dto.reminder) {
        dto.reminder = new Date(dto.reminder);
      }

      await dispatch(kanbanActions.createKanbanTask(dto)).unwrap();
      await dispatch(
        kanbanActions.getKanbanCardById({ cardId: kanbanCard?._id!, userId }),
      ).unwrap();

      onClose();

      AtiraToast.success(t('kanban.task.create_success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateButtonLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && kanbanCard) {
      reset({
        userId,
        kanbanCardId: kanbanCard._id || '',
        ownerId: userId,
      });
    }
  }, [isOpen, kanbanCard, reset, userId]);

  if (!kanbanCard) {
    return null;
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={`${t('common.task_create')}: ${kanbanCard?.title}`}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <Input
              title={t('common.task_name')}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              title={t('common.description')}
            />
          )}
        />

        <Controller
          control={control}
          name="reminder"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker
              value={value || null}
              onChange={(date) => onChange(date)}
              title={t('common.remind_date')}
            />
          )}
        />

        <SubmitButton
          disabled={!taskName || taskName.length < 3}
          title={t('common.create')}
          loading={createButtonLoading}
          onClick={handleSubmit(onSubmit)}
        />
      </Flex>
    </Drawer>
  );
};
