import merge from 'lodash/merge';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { Flex } from './Flex';
import { Text } from './Text';

const StyledTextarea = styled.textarea`
  min-height: 4rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 4rem;
  outline: none;
  border-radius: ${Rounded.md};
  border: ${({ theme }) => `1px solid ${theme.lightgray}`};
  font-family: inherit;
`;

interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  id?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  rows?: number;
  border?: string;
  borderRadius?: keyof typeof Rounded;
  placeholder?: string;
  padding?: string;
  margin?: string;
  color?: string;
  errorMessage?: string;
  valid?: boolean;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
  const { theme } = useTheme();

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      {props.title ? (
        <label
          htmlFor={props.id}
          style={{
            color: theme.black,
            cursor: 'pointer',
          }}
        >
          {props.title}
          {props.required ? <span style={{ color: theme.red }}>*</span> : null}
        </label>
      ) : null}
      <StyledTextarea
        style={merge(
          props.style,
          props.disabled ? { opacity: '0.2' } : {},
          props.valid === false ? { border: `1px solid ${theme.red}` } : {},
          {
            border: props.border,
            borderRadius: props.borderRadius,
            placeholder: props.placeholder,
            padding: props.padding || Spacing.s,
            margin: props.margin,
            maxHeight: props.maxHeight,
          },
        )}
        rows={props.rows}
        {...props}
      />

      {!props.valid && props.errorMessage ? (
        <Text color="red" fontSize="s">
          {props.errorMessage}
        </Text>
      ) : null}
    </Flex>
  );
};
