/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

import { Flex } from '../../../../components/Flex';
import { TextWithTruncated } from '../../../../components/TextWithTruncated';
import { PermissionInvite } from '../../../../model/permissions/PermisisonInvite';
import { PermissionInviteStatus } from '../../../../model/permissions/types/PermissionInviteStatus';
import { Rounded } from '../../../../theme/Rounded';
import { Spacing } from '../../../../theme/Spacing';
import { PendingMemberCREATED } from './PendingMemberCREATED';
import { PendingMemberPENDING } from './PendingMemberPENDING';
import { PendingMemberREJECTED } from './PendingMemberREJECTED';

const PendingMemberWrapper = styled(Flex)`
  border-radius: ${Rounded.md};
  background-color: ${({ theme }) => theme.sub};
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.s};
`;

interface PendingMemberProps {
  invite: PermissionInvite;
}

export const PendingMember: React.FC<PendingMemberProps> = ({ invite }) => {
  return (
    <PendingMemberWrapper>
      <TextWithTruncated>{invite.inviteeEmail}</TextWithTruncated>

      {invite.status === PermissionInviteStatus.PENDING ? (
        <PendingMemberPENDING />
      ) : invite.status === PermissionInviteStatus.CREATED ? (
        <PendingMemberCREATED />
      ) : invite.status === PermissionInviteStatus.REJECTED ? (
        <PendingMemberREJECTED />
      ) : null}
    </PendingMemberWrapper>
  );
};
