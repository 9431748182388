import { useSortable } from '@dnd-kit/sortable';
import { useContext } from 'react';

import { DragIndexContext } from './EntriesTableWithDNDContext';
import { DragIndexState } from './types/DragIndexState';

const dragActiveStyle = (dragState: DragIndexState, id: string) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style: React.CSSProperties = {};
  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? { borderRight: '1px dashed gray' }
        : { borderLeft: '1px dashed gray' };
  }
  return style;
};

interface HeaderCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string;
}

export const EntriesTableWithDNDHeaderCell: React.FC<HeaderCellProps> = (
  props,
) => {
  const isDisabled = props.id === '0' || props.id === '1';

  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
    disabled: {
      draggable: isDisabled,
      droppable: isDisabled,
    },
  });

  const style: React.CSSProperties = {
    ...props.style,
    cursor: isDisabled ? 'default' : 'move',
    ...(isDragging
      ? { position: 'relative', zIndex: 4, userSelect: 'none' }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };
  return (
    <th
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};
