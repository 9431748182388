import { AtiraRoute } from '../AtiraRoute';
import { AtiraRouteAdmin } from '../AtiraRouteAdmin';
import { AdminRoute } from '../admin/AdminRoute';
import { AdminSearchUsers } from '../admin/AdminSearchUsers';
import { PermissionsRoute } from '../permissions/PermissionsRoute';
import { ProfileEdit } from '../profile/ProfileEdit';
import { Subscription } from '../subscription/Subscription';

export const PrivateHelpRouter = [
  {
    path: '/profile',
    element: <AtiraRoute component={<ProfileEdit />} />,
  },
  {
    path: '/subscription',
    element: <AtiraRoute component={<Subscription />} />,
  },
  {
    path: '/permissions',
    element: <AtiraRoute component={<PermissionsRoute />} />,
  },
  { path: '/admin', element: <AtiraRouteAdmin component={<AdminRoute />} /> },
  {
    path: '/admin/users',
    element: <AtiraRouteAdmin component={<AdminSearchUsers />} />,
  },
];
