import { Kanban } from './Kanban';
import { KanbanContextProvider } from './kanban-context';

export const KanbanRoute: React.FC = () => {
  return (
    <KanbanContextProvider>
      <Kanban />
    </KanbanContextProvider>
  );
};
