import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import { MultiLangLink } from '../components/MultiLangLink';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { AppLangs } from '../i18n';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { PublicSidebar } from './PublicSidebar';

const Container = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.main};
  padding: ${Spacing.s};
  z-index: 100;
  width: 100%;
  gap: ${Spacing.m};
  position: sticky;
  top: 0;

  @media (min-width: 576px) {
    padding: ${Spacing.s} ${Spacing.l};
  }
`;

const StartButton = styled(Button)`
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  margin: 0;
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  transition: all 0.3s;
  flex-grow: 1;

  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.black};
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
  @media (min-width: ${Breakpoints.DESKTOP}) {
    flex-grow: 0;
  }
`;

const RegisterButtonsWrapper = styled(Flex)`
  gap: ${Spacing.m};
  align-items: center;
  width: 100%;

  @media (min-width: ${Breakpoints.TABLET}) {
    width: fit-content;
  }
`;

const LinksWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  flex-grow: 1;
  display: none;

  @media (min-width: ${Breakpoints.TABLET}) {
    display: flex;
  }
`;

const StyledLink = styled(MultiLangLink)<{ isActive: boolean }>`
  text-decoration: none;
  color: ${({ theme, isActive }) => (isActive ? theme.main : theme.sub)};
  padding: ${Spacing.s} 0.7rem;
  border-radius: ${Rounded.md};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.sub : theme.transparent};
  transition: all 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.sub};
    color: ${({ theme }) => theme.main};
  }
`;

const SidebarButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};

  @media (min-width: ${Breakpoints.TABLET}) {
    display: none;
  }
`;

export const PublicHeader: React.FC = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const { t, i18n } = useTranslation();

  const navigate = (path: string) => {
    window.open(
      i18n.language === AppLangs.EN ? path : `/${i18n.language}/${path}`,
      '_blank',
    );
  };

  const location = useLocation();

  const { width } = useWindowDimensions();

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }

    const pathToCompare =
      i18n.language === AppLangs.EN ? path : `/${i18n.language}${path}`;

    return location.pathname === pathToCompare;
  };

  return (
    <Container>
      <Flex maxWidth="15rem" flex={1}>
        <Link to={'/'}>
          <AtiraImage src={Images.AtiraSpaceLogoSub} width="100%" />
        </Link>
      </Flex>

      <LinksWrapper>
        <StyledLink to={'/'} isActive={isActive('/')}>
          {t('public_header.home')}
        </StyledLink>

        <StyledLink to={'/pricing'} isActive={isActive('/pricing')}>
          {t('public_header.pricing')}
        </StyledLink>

        <StyledLink to={'/features'} isActive={isActive('/features')}>
          {t('public_header.features')}
        </StyledLink>

        <StyledLink to={'/use-cases'} isActive={isActive('/use-cases')}>
          {t('public_header.use_cases')}
        </StyledLink>
      </LinksWrapper>

      <Flex style={{ marginInlineStart: 'auto' }} gap="m">
        <LanguageSwitcher />

        <SidebarButton
          icon={faBars}
          onClick={() => setSidebarVisible(true)}
          iconWidth="2x"
        />
      </Flex>

      <RegisterButtonsWrapper>
        <StartButton onClick={() => navigate('login')}>
          {t('common.login')}
        </StartButton>

        <StartButton onClick={() => navigate('signup')}>
          {t('common.signup')}
        </StartButton>
      </RegisterButtonsWrapper>

      {width < 768 ? (
        <PublicSidebar
          isOpen={sidebarVisible}
          onClose={() => setSidebarVisible(false)}
        />
      ) : null}
    </Container>
  );
};
