import { TFunction } from 'i18next';
import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateEntryDto } from '../../../model/entry/dto/UpdateEntryDto';
import { InputTypes } from '../../../model/form/types/InputTypes.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { comparePayloads } from '../../../utils/ComparePayloads';
import { Regexes } from '../../../utils/String';

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 15rem;
`;

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const UpdateButton = styled(Button)`
  width: 7rem;
  height: 2.5rem;
  font-size: 1.3rem;
  margin: 0;
`;

const validateNameAndEmail = (
  value: string,
  type: InputTypes,
  t: TFunction,
) => {
  if (
    type === InputTypes.EMAIL &&
    value &&
    (!value.trim() || !Regexes.EMAIL_REGEX.test(value.trim()))
  ) {
    return t('entries.update.error.email_validate');
  }
  return true;
};

interface EntryUpdateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
}

export const EntryUpdateDrawer: React.FC<EntryUpdateDrawerProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const [entryUpdateLodaing, setEntryUpdateLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const dispatch = useAppDispatch();

  const { control, getValues, reset, handleSubmit } = useForm<UpdateEntryDto>();

  const onUpdateEntry = async () => {
    try {
      setEntryUpdateLoading(true);

      const dto = getValues();

      const { entryId, userId, ...modifiableDto } = dto;

      const updatedData = comparePayloads(
        entry!,
        modifiableDto,
      ) as UpdateEntryDto;

      if (!isEmpty(updatedData)) {
        await dispatch(
          entryActions.updateEntryById({
            ...updatedData,
            userId,
            entryId: entry?._id!,
          }),
        ).unwrap();
        await dispatch(
          entryActions.getEntryById({ entryId: entry?._id!, userId: userId }),
        ).unwrap();
      }

      onClose();
      AtiraToast.success(t('entries.update.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEntryUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && entry) {
      reset({
        entryId: entry._id || '',
        userId,
        kind: entry.kind,
        ...entry.fields,
      });
    }
  }, [userId, entry, isOpen, reset]);

  return (
    <Drawer
      title={`${t('entries.modal.update.title')} - ${entry?.fields.name || entry?.fields.email}`}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" justifyContent="center" gap="m">
        {entry?.parentForm?.inputs?.map((input) => (
          <Controller
            key={input._id}
            name={input.name}
            control={control}
            rules={{
              required: input.settings?.required === true,
              validate: (value: string) =>
                validateNameAndEmail(value, input.type, t),
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const _value =
                input.type.toLowerCase() === InputTypes.DATE.toLowerCase() ||
                isDate(new Date(value))
                  ? value && new Date(value)
                  : value;
              return (
                <StyledInput
                  value={_value}
                  onChange={onChange}
                  onChangeDate={onChange}
                  title={input.label}
                  id={input._id}
                  type={input.type}
                  required={input.settings?.required}
                  valid={!(errors[input.name] as FieldError)?.message}
                  errorMessage={(errors[input.name] as FieldError)?.message}
                  disabled={input.name === InputTypes.EMAIL.toLowerCase()}
                />
              );
            }}
          />
        ))}

        <Controller
          name={'description'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Flex flexDirection="column" gap="s">
              <StyledTextarea
                value={value}
                onChange={onChange}
                id={'description'}
                rows={3}
                title={t('common.description')}
              />
            </Flex>
          )}
        />

        <UpdateButton
          onClick={handleSubmit(onUpdateEntry, (e) => console.log(e))}
          loading={entryUpdateLodaing}
          title={t('common.update')}
        />
      </Flex>
    </Drawer>
  );
};
