import { createSlice } from '@reduxjs/toolkit';

import { BlogsAxios } from '../../axios/blogs/blogs.axios';
import { AtiraThunk } from '../AtiraThunk';

interface BlogsReducer {
  blogs: any[];
}

const initialState = Object.freeze<BlogsReducer>({
  blogs: [],
});

const getBlogs = AtiraThunk<any[], void>('/blogs/', () =>
  BlogsAxios.getBlogs(),
);

const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getBlogs.fulfilled, (state, action) => {
      state.blogs = action.payload;
    });
  },
});

export const blogsActions = {
  getBlogs,
};

export default blogsSlice.reducer;
