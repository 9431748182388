import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from '../../components/AtiraIcon';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { useStepContext } from '../../components/stepper/StepperContext';
import { useStepperRegisterValidation } from '../../components/stepper/useStepperRegisterValidation';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { useAppSelector } from '../../redux/store';
import { getFormDefaultInputs } from './FormUtils';

export const FormCreateEditStepIntegrateCustomFields = () => {
  const { control } = useFormContext<CreateFormDto>();
  const { fields, replace } = useFieldArray({
    control,
    name: 'inputs',
  });

  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;

  const defaultInputsNames = useMemo(() => {
    return getFormDefaultInputs(tableSettings).map((x) => x.name);
  }, [tableSettings]);

  const customFields = useMemo(
    () =>
      fields.filter(
        (f: any) => f.custom === true || !defaultInputsNames.includes(f.name),
      ),
    [fields, defaultInputsNames],
  ) as CreateFormDto['inputs'];

  const { currentStep } = useStepContext();
  const { t } = useTranslation();

  const canGoNext = useCallback(() => {
    return customFields.every((f) => f.name?.length > 0);
  }, [customFields]);

  useStepperRegisterValidation(currentStep, canGoNext);

  const customColumns = tableSettings.columns.filter((c) => c.custom === true);

  const onIntegrateInput = (f: (typeof fields)[number], fieldName: string) => {
    const newInputs = fields.map((input) => {
      if (input.id === f.id) {
        return {
          ...input,
          name: customColumns.find((f) => f.label === fieldName)?.field!,
        };
      }
      return input;
    });

    replace(newInputs);
  };

  if (!customFields.length) {
    return (
      <Flex
        flexDirection="column"
        gap="m"
        justifyContent="center"
        alignItems="center"
      >
        <AtiraIcon color="main" icon={faCheck} size="3x" />
        <Text>{t('forms.create.step.4.no_custom_fields')}</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center" gap="m">
      <Text>{t('forms.create.step.4.has_custom_fields')}</Text>

      {fields.map((f) => {
        const isCustom =
          (f as any).custom === true || !defaultInputsNames.includes(f.name);
        return (
          <Flex
            key={f?.label}
            gap="m"
            alignItems="center"
            flexDirection="column"
          >
            <Flex>
              <Text>{`${t('forms.create.step.4.dropdown.title')}: ${f.label}`}</Text>
            </Flex>
            <Flex gap="xl" alignItems="center">
              <DropDown
                containerStyle={{ width: '10rem' }}
                onChange={() => {}}
                placeholder={f?.placeholder}
                disabled
                defaultValue={{
                  label: f.label,
                  value: f.label,
                }}
                allowClear
              />
              <AtiraIcon icon={faArrowRightArrowLeft} />
              <DropDown
                placeholder={isCustom ? undefined : f.name}
                containerStyle={{ width: '10rem' }}
                onChange={(fieldName) => onIntegrateInput(f, fieldName)}
                options={customColumns
                  .filter(
                    (column) =>
                      !fields.map((f) => f.name).includes(column.field),
                  )
                  .map((_f) => ({
                    label: _f.label,
                    value: _f.label,
                  }))}
                disabled={!isCustom}
                value={customColumns.find((c) => c.field === f.name)?.label}
                allowClear
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
