import { TableProps, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import truncate from 'lodash/truncate';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { EntriesTableSettings } from '../../../model/entries-table-settings/EntriesTableSettings';
import { Entry } from '../../../model/entry/Entry';
import { standardDate } from '../../../utils/Date';
import { getTableCellClassName } from './getTableCellClassName';

type FieldFunctions = {
  t: TFunction;
};

const TRUNCATE_LENGTH = 20;

const getTruncatedCell = (value?: string) => {
  if (value && value.length >= TRUNCATE_LENGTH) {
    return (
      <Tooltip title={value}>
        <Text>{truncate(value, { length: TRUNCATE_LENGTH })}</Text>
      </Tooltip>
    );
  }

  return <Text>{value}</Text>;
};

const getFieldWidth = (field: string) => {
  return '8rem';
};

const getFieldInfo = (
  column: EntriesTableSettings['columns'][number],
  functions: { t: TFunction },
) => {
  const { t } = functions;
  const { field } = column;

  switch (field) {
    case 'time':
      return {
        title: t('entries.table.header.submit_time'),
        key: 'time',
        render: ({ createdAt }: Entry) => standardDate(createdAt),
        sortable: true,
        reorder: true,
        width: '8rem',
        data: column,
      };

    case 'phone':
      return {
        title: t('common.phone'),
        key: 'phone',
        render: ({ fields }: Entry) => {
          return getTruncatedCell(fields?.phone);
        },
        sortable: true,
        reorder: true,
        width: getFieldWidth(field),
        data: column,
      };

    case 'email':
      return {
        title: t('common.email'),
        key: 'email',
        render: ({ fields, kind }: Entry) => {
          return {
            props: { className: getTableCellClassName(kind) },
            children: <Flex>{getTruncatedCell(fields.email)}</Flex>,
          };
        },
        sortable: true,
        reorder: true,
        data: column,
        width: '6rem',
      };

    case 'actions':
    case 'task':
    case 'form':
    case 'name':
    case 'description':
      return { data: { enabled: false } };

    default:
      return {
        title: t(`common.${field}`),
        key: field,
        render: (entry: Entry) => {
          const value =
            (
              entry[field as keyof Entry] ||
              entry.fields?.[field as keyof Entry['fields']]
            )?.toString() || '';

          return getTruncatedCell(value);
        },
        sortable: true,
        reorder: true,
        width: getFieldWidth(field),
        data: column,
      };
  }
};

export function createEntriesWidgetTableColumns<T>(
  columns: EntriesTableSettings['columns'],
  functions: FieldFunctions,
) {
  return columns.map((c) =>
    getFieldInfo(c, functions),
  ) as TableProps<T>['columns'];
}
