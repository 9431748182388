import { AdminStats } from '../../model/admin/AdminStats';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { AtiraApi } from '../AxiosClient';

export class AdminAxios {
  static getAdminStats(dto: GetAdminStatsDto) {
    return AtiraApi.post<AdminStats>('/admin/stats', dto).then(
      ({ data }) => data,
    );
  }

  static searchUsers(dto: SearchUsersDto) {
    return AtiraApi.post<SearchUsersResponseDto>(
      `/admin/user/search`,
      dto,
    ).then(({ data }) => data);
  }
}
