import { Flex } from './Flex';
import { Spinner } from './Spinner';

export const SpinnerFullScreen: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" flex={1}>
      <Flex width={'3rem'} height={'3rem'}>
        <Spinner />
      </Flex>
    </Flex>
  );
};
