import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Flex } from './Flex';

const StyledSelectContainer = styled(Flex)`
  flex-direction: column;

  .ant-select-selector:hover {
    border: 1px solid ${({ theme }) => theme.main} !important;
  }
`;

export interface DropDownRemoteSearchProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  title?: string;
  defaultOptions?: ValueType[];
}

export function DropDownRemoteSearch<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  title,
  defaultOptions = [],
  ...props
}: DropDownRemoteSearchProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>(() => defaultOptions);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <StyledSelectContainer gap="s">
      {title ? <label>{title}</label> : null}
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        showSearch
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    </StyledSelectContainer>
  );
}
