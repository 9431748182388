import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { Images } from '../../../assets';
import { AtiraRow } from '../../../components/AtiraRow';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';
import { AtiraColumn } from '../../entries/components/AtiraColumn';

const Container = styled(Flex)`
  background-color: #eee;
  padding-inline: ${Spacing.s};
  padding-block: ${Spacing.xl};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: calc(${Spacing.xxxl} * 1.3);
    padding-block: ${Spacing.xxxl};
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: ${Spacing.s};
  font-weight: bold;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2rem;
  }
`;

const Paragraph = styled(Text)`
  font-size: 1.25rem;
  line-height: 2.25rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 1.5rem;
  }
`;

export const UseCasesManageBusinessSection: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Container>
      <AtiraRow gutter={20} align={'middle'}>
        <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <AtiraImage src={Images.CasesManageBusiness} />
        </AtiraColumn>

        <AtiraColumn xs={24} sm={24} md={24} lg={12} xl={12}>
          <Flex flexDirection="column" gap="m">
            <Title>{t('use_cases.manage_business.title')}</Title>

            <Paragraph>
              <span style={{ color: theme.main, fontStyle: 'italic' }}>
                {`${t('common.atira_space_crm')} `}
              </span>

              {t('use_cases.manage_business.paragraph.1')}
            </Paragraph>

            <Text fontSize="xm" style={{ lineHeight: '2rem' }}>
              {t('use_cases.manage_business.paragraph.2')}
            </Text>
          </Flex>
        </AtiraColumn>
      </AtiraRow>
    </Container>
  );
};
