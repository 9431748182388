import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  gap: ${Spacing.m};
  padding-left: ${Spacing.xl};
  padding-right: ${Spacing.xl};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15rem;
`;

type EntryBulkConvertModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: ATVoidFunction<EntryKind>;
  loading: boolean;
};

export const EntryBulkConvertModal: React.FC<EntryBulkConvertModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
}) => {
  const [entryKind, setEntryKind] = useState<EntryKind | undefined>(undefined);

  const { t } = useTranslation(undefined, { keyPrefix: 'entries.table' });

  const getDropDownOptions = () => {
    return Object.keys(EntryKind).map((x) => ({
      label: t(`entry.${x}`),
      value: x,
    }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={{ height: '25rem', maxHeight: '25rem', overflow: 'scroll' }}
      title={t('entry.convert.bulk.title')}
    >
      <Container>
        <Flex flexDirection="column" gap="m">
          <Text color="darkTextColor">{t('entry.convert.bulk.subtitle')}</Text>

          <DropDown
            options={getDropDownOptions()}
            value={{
              label: t(`entry.${entryKind || EntryKind.LEAD}`),
              value: entryKind,
            }}
            onChange={(e: any) => setEntryKind(e)}
          />
          <Button
            loading={loading}
            disabled={!entryKind}
            onClick={() => onSubmit(entryKind!)}
            width="15rem"
            height="2.3rem"
          >
            {t('entry.convert.update.button')}
          </Button>
        </Flex>
      </Container>
    </Modal>
  );
};
