import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraIcon } from '../components/AtiraIcon';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { Text } from '../components/Text';
import { Spacing } from '../theme/Spacing';

const Footer = styled(Flex)`
  background-color: ${({ theme }) => theme.main};
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  padding: ${Spacing.xxl};
`;

const FooterLogoWrapper = styled(Flex)`
  width: 17rem;
`;

export const PublicFooter: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Footer>
      <FooterLogoWrapper>
        <AtiraImage src={Images.AtiraSpaceLogoSub} />
      </FooterLogoWrapper>

      <Text color="sub">{t('app.description')}</Text>

      <Flex alignItems="center" justifyContent="center" gap="m">
        <Flex>
          <AtiraIcon icon={faFacebook} size="2x" color="lightergray" />
        </Flex>

        <Flex>
          <AtiraIcon icon={faXTwitter} size="2x" color="lightergray" />
        </Flex>
      </Flex>

      <Text color="lightergray">{t('common.terms_and_conditions')}</Text>

      <Flex marginTop="m">
        <Text color="sub">{t('app.all_rights_reserved')}</Text>
      </Flex>
    </Footer>
  );
};
