import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons/faArrowRotateRight';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { Tooltip } from 'antd';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import i18n, { AppLangs } from '../../i18n';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { AtiraIcon } from '../AtiraIcon';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Input } from '../Input';
import { Text } from '../Text';

const StyledIconButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  gap: ${Spacing.m};
  flex-wrap: wrap;
  height: 6rem;

  @media (min-width: 1300px) {
    height: 4rem;
  }
`;

const SearchAndRefreshWrapper = styled(Flex)`
  align-items: center;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    width: fit-content;
  }
`;

const FiltersWrapper = styled(Flex)`
  width: 100%;
  order: 3;
  align-items: center;
  gap: ${Spacing.m};

  @media (min-width: 1300px) {
    width: fit-content;
    order: 2;
  }
`;

const SettingsButtonWrapper = styled(Flex)`
  gap: ${Spacing.s};
  order: 2;
  margin-left: ${i18n.language === AppLangs.AR ? '0' : 'auto'};
  margin-right: ${i18n.language === AppLangs.AR ? 'auto' : '0'};

  @media (min-width: 1300px) {
    order: 3;
  }
`;

type TableHeaderProps = {
  onFetch: () => void;
  onFetchLoading?: boolean;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onSettings: () => void;
  filters?: React.ReactNode;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  onFetch,
  onFetchLoading,
  onSearch,
  onSettings,
  filters,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SearchAndRefreshWrapper>
        <StyledIconButton
          backgroundColor="gray"
          onClick={onFetch}
          disabled={onFetchLoading}
        >
          <Tooltip title={t('common.refresh')}>
            <AtiraIcon icon={faArrowRotateRight} color="main" size="2x" />
          </Tooltip>
        </StyledIconButton>

        <Input
          width="10rem"
          height="2rem"
          placeholder={t('common.search')}
          onChange={onSearch}
        />
      </SearchAndRefreshWrapper>

      <FiltersWrapper>{filters}</FiltersWrapper>

      <SettingsButtonWrapper>
        <StyledIconButton onClick={onSettings}>
          <Flex alignItems="center" gap="s">
            <Tooltip title={t('common.settings')}>
              <AtiraIcon icon={faGear} color="main" size="2x" />
            </Tooltip>
            <Text color="main" fontSize="m">
              {t('common.settings')}
            </Text>
          </Flex>
        </StyledIconButton>
      </SettingsButtonWrapper>
    </Wrapper>
  );
};
