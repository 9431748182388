import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  background-image: url(${Images.CasesHeroBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 92vh;
  justify-content: center;
  align-items: center;
  padding-inline: ${Spacing.s};

  @media (min-width: ${Breakpoints.TABLET}) {
    padding-inline: calc(${Spacing.xxxl} * 1.2);
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    padding-inline: calc(${Spacing.xxxl} * 1.4);
  }
`;

const TextsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.l};
  align-items: center;
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.main};
  font-weight: bold;
  font-size: 2rem;
  text-align: center;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2.5rem;
  }
`;

const Paragraph = styled(Text)`
  font-size: 1.5rem;
  text-align: center;
  line-height: 2.25rem;

  @media (min-width: ${Breakpoints.TABLET}) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const UseCasesHeroSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TextsWrapper>
        <TextsWrapper gap="o">
          <Title>{t('common.atira_space_crm')}</Title>

          <Title>{t('use_cases.hero.title')}</Title>
        </TextsWrapper>

        <Paragraph>{t('use_cases.hero.paragraph')}</Paragraph>
      </TextsWrapper>
    </Container>
  );
};
