/* eslint-disable */
export enum SideBarRoutes {
  DASHBOARD = '/dashboard',
  FORMS = '/forms',
  ENTRIES = '/entries',
  DEALS = '/deals',
  REPORTS = '/reports',
  TASKS = '/tasks',
  PRODUCTS = '/products',
}
