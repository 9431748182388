import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericGetStartedSection } from '../../components/public/GenericGetStartedSection';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { UseCasesByIndustrySection } from './sections/UseCasesByIndustrySection';
import { UseCasesFeaturesSection } from './sections/UseCasesFeaturesSection';
import { UseCasesHeroSection } from './sections/UseCasesHeroSection';
import { UseCasesManageBusinessSection } from './sections/UseCasesManageBusinessSection';
import { UseCasesWhyChooseSection } from './sections/UseCasesWhyChooseSection';

export const UseCases: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(
    `${t('app.name').toUpperCase()} - ${t('public_header.use_cases')}`,
  );

  return (
    <Fragment>
      <UseCasesHeroSection />
      <UseCasesManageBusinessSection />
      <UseCasesByIndustrySection />
      <UseCasesFeaturesSection />
      <UseCasesWhyChooseSection />
      <GenericGetStartedSection />
    </Fragment>
  );
};
