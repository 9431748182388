import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { StepsProps } from 'antd';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import xorWith from 'lodash/xorWith';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { AtiraStepperWithContext } from '../../components/stepper/AtiraStepper';
import { StepperStep } from '../../components/stepper/StepperStep';
import i18n, { AppLangs } from '../../i18n';
import { Form } from '../../model/form/Form';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { UpdateFormDto } from '../../model/form/dto/UpdateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { Direction } from '../../model/shared/Direction.enum';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { thresholdActions } from '../../redux/threshold/threshold.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { comparePayloads } from '../../utils/ComparePayloads';
import { isValidUrl } from '../../utils/String';
import { FormCreateEditStepBasicInfo } from './FormCreateEditStepBasicInfo';
import { FormCreateEditStepFormBuilder } from './FormCreateEditStepFormBuilder';
import { FormCreateEditStepIntegrateCustomFields } from './FormCreateEditStepIntegrateCustomFields';
import { FormCreateEditStepPostSubmitType } from './FormCreateEditStepPostSubmitType';
import { FormCreateEditStepTheme } from './FormCreateEditStepTheme';
import { canCreateForm, getFormDefaultInputs } from './FormUtils';

export const FormCreateEdit: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const form = location?.state as Form | null;

  const dispatch = useAppDispatch();

  const loggedinUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const websiteLanguage = i18n.language;
  const state = useAppSelector((s) => s);
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;
  const userForms = useAppSelector(formSliceSelectors.selectMyForms);

  const stepperItems: StepsProps['items'] = [
    { title: t('forms.create.step.1') },
    { title: t('forms.create.step.2') },
    { title: t('forms.create.step.3') },
    { title: t('forms.create.step.4') },
    { title: t('forms.create.step.5') },
  ];

  const formMethods = useForm<CreateFormDto | UpdateFormDto>({
    mode: 'onChange',
    defaultValues: {
      inputs:
        form?.inputs ||
        getFormDefaultInputs(tableSettings)
          .filter(({ label }) => label === EntryFieldsDefaults.EMAIL)
          .map((x) => ({
            ...x,
            order: 0,
            label: t(`common.${EntryFieldsDefaults.EMAIL}`),
            settings: { required: true, maxChars: Lengths.EMAIL },
          })),
      postSubmit: form?.postSubmit || FormPostSubmit.confirm,
      domains: form?.domains.map((domain) => ({ content: domain })) || [
        { content: '' },
      ],
      theme: form?.theme || FormTheme.WHITE,
      title: form?.title || '',
      formDirection: form?.formDirection || Direction.LTR,
      postSubmitMessage: form?.postSubmitMessage,
      postSubmitUrl: form?.postSubmitUrl,
      submitButtonTitle: form?.submitButtonTitle || '',
    },
  });

  const editMode =
    !isEmpty(state.form) &&
    location.pathname.includes('/forms/edit') &&
    !isEmpty(form?._id);

  const transformURL = (url: string) => {
    let urlWithProtocol = isValidUrl(url)
      ? new URL(url.startsWith('http') ? url : `https://${url}`).hostname
      : url;

    urlWithProtocol = `https://${urlWithProtocol}`;

    return urlWithProtocol;
  };

  const onCreateForm = () => {
    const payload = {
      ...formMethods.getValues(),
      userId: loggedinUserId,
      inputs: formMethods
        .getValues('inputs')
        ?.map((x, i) =>
          omit({ ...x, type: x.type?.toLowerCase(), order: i }, ['icon', 'id']),
        ),
    } as CreateFormDto;

    payload.domains = payload.domains
      .map((domain) =>
        domain.content ? transformURL(domain.content) : domain.content,
      )
      .filter((x) => x) as any;

    if (payload.postSubmitUrl) {
      transformURL(payload.postSubmitUrl);
    }

    return dispatch(formActions.createForm(payload as CreateFormDto)).unwrap();
  };

  const onEditForm = () => {
    const {
      domains: newDomains,
      inputs: newInputs,
      ...newValues
    } = formMethods.getValues();
    const {
      domains: oldDomains,
      inputs: oldInputs,
      ...oldValues
    } = form as Form;

    const modifiedInputs = formMethods
      .getValues('inputs')
      ?.map((x, i) =>
        omit({ ...x, type: x.type?.toLowerCase(), order: i }, ['icon', 'id']),
      );
    const modifiedNewDomains = !isEmpty(
      xorWith(oldDomains, map(newDomains, 'content'), isEqual),
    )
      ? map(newDomains, 'content').filter(identity).map(transformURL)
      : null;

    const modifiedNewValues = comparePayloads(oldValues, newValues);

    const updateDto = omitBy<UpdateFormDto>(
      {
        formId: form?._id as string,
        userId: loggedinUserId,
        domains: modifiedNewDomains as any,
        inputs: modifiedInputs as any,
        ...modifiedNewValues,
      },
      isNil,
    ) as unknown as UpdateFormDto;

    dispatch(
      formActions.setCurrentForm(userForms.find((f) => f.orphan === true)!),
    );
    return dispatch(formActions.updateForm(updateDto)).unwrap();
  };

  const onSubmit = async () => {
    try {
      setSubmitLoading(true);

      if (editMode) {
        await onEditForm();
      } else {
        await onCreateForm();
        dispatch(thresholdActions.getThreshold({ userId: loggedinUserId }));
      }

      await dispatch(
        formActions.getMyForms({ userId: loggedinUserId }),
      ).unwrap();

      formMethods.reset();

      AtiraToast.success(t('forms.form.create.success'));

      navigate('/forms');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e, { duration: 8 });
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (!canCreateForm(state) && !editMode) {
      navigate('/forms', { replace: true });
    }
  }, [editMode, navigate, state]);

  return (
    <FormProvider {...formMethods}>
      <Flex width={'100%'} flexDirection="column" flex={1}>
        <SubHeader
          title={`${t('common.forms')} / ${t(editMode ? 'common.edit' : 'common.create')}`}
          icon={websiteLanguage === AppLangs.AR ? faArrowRight : faArrowLeft}
          buttonTitle={t('common.back')}
          onClick={() => navigate('/forms')}
        />

        <AtiraStepperWithContext
          submitButtonTitle={t(form ? 'common.edit' : 'common.create')}
          submitLoading={submitLoading}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          components={[
            <StepperStep
              title={t('forms.create.step.1')}
              children={<FormCreateEditStepBasicInfo />}
            />,
            <StepperStep
              title={t('forms.create.step.2')}
              children={<FormCreateEditStepFormBuilder />}
            />,
            <StepperStep
              title={t('forms.create.step.3')}
              children={<FormCreateEditStepPostSubmitType />}
            />,
            <StepperStep
              title={t('forms.create.step.4')}
              children={<FormCreateEditStepIntegrateCustomFields />}
            />,
            <StepperStep
              title={t('forms.create.step.5')}
              children={<FormCreateEditStepTheme />}
            />,
          ]}
          items={stepperItems}
        />
      </Flex>
    </FormProvider>
  );
};
