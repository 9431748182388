import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Textarea } from '../../../components/Textarea';
import { Modal } from '../../../components/modal/Modal';

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 7rem;
`;

interface KanbanCardMovingReasonModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: ATVoidFunction<string>;
}

export const KanbanCardMovingReasonModal: React.FC<
  KanbanCardMovingReasonModalProps
> = ({ isOpen = false, onClose, onConfirm }) => {
  const [reasonMessage, setReasonMessage] = useState('');

  const { t } = useTranslation();

  const onSubmit = () => {
    onConfirm(reasonMessage);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setReasonMessage('');
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} title={t('common.moving_reason')}>
      <Flex flexDirection="column" alignItems="center" width={'75%'}>
        <Textarea
          value={reasonMessage}
          onChange={(e) => setReasonMessage(e.currentTarget.value)}
        />
        <StyledButton
          disabled={reasonMessage.length < 3}
          onClick={onSubmit}
          title={t('common.confirm')}
        />
      </Flex>
    </Modal>
  );
};
