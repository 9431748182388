import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const userState = (state: RootState) => state.form;

const selectMyForms = createSelector(userState, (state) => state.forms);

const selectCurrentForm = createSelector(
  userState,
  (state) => state.currentForm,
);

export const formSliceSelectors = { selectMyForms, selectCurrentForm };
