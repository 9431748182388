import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../../components/AtiraTabs';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import i18n, { AppLangs } from '../../../i18n';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { UseCasesECommerceTab } from './UseCasesECommerceTab';
import { UseCasesEducationTab } from './UseCasesEducationTab';
import { UseCasesHealthCareTab } from './UseCasesHealthCareTab';
import { UseCasesMedicalTourismTab } from './UseCasesMedicalTourismTab';
import { UseCasesRealEstateTab } from './UseCasesRealEstateTab';
import { UseCasesServicesTab } from './UseCasesServicesTab';
import { UseCasesTravelTab } from './UseCasesTravelTab';

const StyledTabs = styled(AtiraTabs)`
  padding-inline: 0;

  .ant-tabs-tab {
    color: ${({ theme }) => theme.main} !important;

    @media (min-width: ${Breakpoints.DESKTOP}) {
      border-radius: ${i18n.language === AppLangs.AR
        ? `0 ${Rounded.md} ${Rounded.md} 0`
        : `${Rounded.md} 0 0 ${Rounded.md}`};
    }
  }
`;

export const UseCasesByIndustryTabs: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const getTabsPosition = useMemo(() => {
    if (width < 992) {
      return 'top';
    } else {
      if (i18n.language === AppLangs.AR) {
        return 'right';
      }

      return 'left';
    }
  }, [width, i18n.language]);

  const items = [
    {
      key: '1',
      label: t('use_cases.industry.real_estate'),
      children: <UseCasesRealEstateTab />,
    },
    {
      key: '2',
      label: t('use_cases.industry.education'),
      children: <UseCasesEducationTab />,
    },
    {
      key: '3',
      label: t('use_cases.industry.medical_tourism'),
      children: <UseCasesMedicalTourismTab />,
    },
    {
      key: '4',
      label: t('use_cases.industry.e_commerce'),
      children: <UseCasesECommerceTab />,
    },
    {
      key: '5',
      label: t('use_cases.industry.healthcare'),
      children: <UseCasesHealthCareTab />,
    },
    {
      key: '6',
      label: t('use_cases.industry.travel_hospitality'),
      children: <UseCasesTravelTab />,
    },
    {
      key: '7',
      label: t('use_cases.industry.professional_services'),
      children: <UseCasesServicesTab />,
    },
  ];

  return <StyledTabs tabPosition={getTabsPosition} items={items} />;
};
