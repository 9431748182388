import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import adminReducer from './admin/admin.slice';
import appReducer from './app/app.slice';
import blogsReducer from './blogs/blogs.slice';
import dashboardReduce from './dashboard/dashboard.slice';
import entryReducer from './entry/entry.slice';
import formReducer from './forms/form.slice';
import kanbanSlice from './kanban/kanban.slice';
import permissionReducer from './permission/permission.slice';
import taskReducer from './task/task.slice';
import templateReducer from './template/template.slice';
import thresholdReducer from './threshold/threshold.slice';
import userReducer from './user/user.slice';
import widgetReducer from './widget/widget.slice';

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
};

const reducers = combineReducers({
  user: userReducer,
  form: formReducer,
  template: templateReducer,
  entry: entryReducer,
  dashBaord: dashboardReduce,
  kanban: kanbanSlice,
  widget: widgetReducer,
  app: appReducer,
  task: taskReducer,
  blogs: blogsReducer,
  threshold: thresholdReducer,
  permission: permissionReducer,
  admin: adminReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const _store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

type AppDispatch = typeof _store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof _store.getState>;

export const useAppSelector = useSelector.withTypes<RootState>();

const persistedStore = persistStore(_store);
const store = { store: _store, persistor: persistedStore };

export default store;
