import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload, message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';

const { Dragger } = Upload;

const StyledDragger = styled(Dragger)`
  .ant-upload {
    width: 100% !important;
    display: block;
    padding: 0 !important;
  }

  .ant-btn-variant-outlined {
    background-color: ${({ theme }) => theme.darkSub} !important;
    width: 100%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${Spacing.m};
    border: 0;
  }

  .ant-btn-icon {
    font-size: 2rem;
  }

  .ant-upload-drag {
    overflow: hidden !important;
    border-color: ${({ theme }) => theme.black} !important;
  }

  .ant-upload-drag-hover,
  .ant-upload-drag-hover:hover,
  .ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${({ theme }) => theme.darkerSub} !important;
  }

  .ant-btn-variant-outlined:hover,
  .ant-upload-drag-hover .ant-btn-variant-outlined {
    color: ${({ theme }) => theme.darkerSub} !important;
  }
`;

const uploadProps: UploadProps = {
  name: 'file',
  defaultFileList: [],
  beforeUpload: (file) => false,
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

type UplaodProps = {
  onChange: (file: UploadFile) => void;
  defaultFiles?: UploadFile[];
  className?: string;
  title?: string;
  dragHover?: boolean;
};

export const AtiraUpload: React.FC<UplaodProps> = (props) => {
  const { t } = useTranslation();
  return props.dragHover ? (
    <StyledDragger
      className={props.className}
      {...uploadProps}
      maxCount={1}
      defaultFileList={props.defaultFiles}
      onChange={(info) => {
        uploadProps.onChange!(info);
        if (info.file.status !== 'removed') {
          props.onChange(info.file);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>
        {props.title || t('common.upload_file')}
      </Button>
    </StyledDragger>
  ) : (
    <Upload
      className={props.className}
      {...uploadProps}
      maxCount={1}
      defaultFileList={props.defaultFiles}
      onChange={(info) => {
        uploadProps.onChange!(info);
        if (info.file.status !== 'removed') {
          props.onChange(info.file);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>
        {props.title || t('common.upload_file')}
      </Button>
    </Upload>
  );
};
