import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { Modal } from '../../../../components/modal/Modal';

type InviteLandingPageModalProps = {
  inviteMode: string | null;
  inviteCode: string | null;
};

export const InviteLandingPageModal: React.FC<InviteLandingPageModalProps> = ({
  inviteMode,
  inviteCode,
}) => {
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  const onLogin = () => {
    window.open(
      `${process.env.REACT_APP_APP_URL}/login?inviteMode=${inviteMode}&inviteCode=${inviteCode}`,
      '_blank',
    );
  };

  const onSignup = () => {
    window.open(
      `${process.env.REACT_APP_APP_URL}/signup?inviteMode=${inviteMode}&inviteCode=${inviteCode}`,
      '_blank',
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(open)}
      title={t('permissions.invites.landing-page.header')}
    >
      <Flex flexDirection="column" gap="l" alignItems="center">
        <Text fontSize="xm">{t('permissions.invites.landing-page.title')}</Text>
        <Text>{t('permissions.invites.landing-page.description')}</Text>
        <Flex gap="m">
          <Button title={t('common.login')} onClick={onLogin} />
          <Button title={t('common.signup')} onClick={onSignup} />
        </Flex>
      </Flex>
    </Modal>
  );
};
