import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../assets';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';

const FormThemeImageWrapper = styled(Flex)<{ selected: boolean }>`
  border-radius: ${Rounded.md};
  flex-direction: column;
  align-items: center;
  box-shadow: ${Shadow.MD};
  overflow: hidden;
  width: 12rem;
  outline: ${({ theme, selected }) =>
    selected ? `3px solid ${theme.main}` : `none`};
  cursor: pointer;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: fit-content;
  }
`;

const ComingSoonTheme = styled(Flex)`
  background-image: url(${Images.FormTheme1});
  position: relative;
  height: 17rem;
  width: 12rem;
  z-index: 1;
  overflow: hidden;
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.MD};
`;

const ComingSoonThemeText = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  padding: 1rem;
`;

const StyledImage = styled(AtiraImage)`
  height: 17rem !important;
  object-fit: contain;
`;

export const FormCreateEditStepTheme: React.FC = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<CreateFormDto>();

  const selectedTheme = watch('theme');

  return (
    <Flex flex={1} flexDirection="column" alignItems="center">
      <Flex gap="m">
        <Flex justifyContent="center" flexWrap="wrap" gap="xl">
          <FormThemeImageWrapper
            selected={selectedTheme === FormTheme.WHITE}
            onClick={() => setValue('theme', FormTheme.WHITE)}
          >
            <StyledImage src={Images.FormTheme1} />
          </FormThemeImageWrapper>

          <FormThemeImageWrapper
            selected={selectedTheme === FormTheme.PURPLE}
            onClick={() => setValue('theme', FormTheme.PURPLE)}
          >
            <StyledImage src={Images.FormTheme2} />
          </FormThemeImageWrapper>

          <FormThemeImageWrapper
            selected={selectedTheme === FormTheme.PURPLE_CURVE}
            onClick={() => setValue('theme', FormTheme.PURPLE_CURVE)}
          >
            <StyledImage src={Images.FormTheme3} />
          </FormThemeImageWrapper>

          <FormThemeImageWrapper
            selected={selectedTheme === FormTheme.YELLOW}
            onClick={() => setValue('theme', FormTheme.YELLOW)}
          >
            <StyledImage src={Images.FormTheme4} />
          </FormThemeImageWrapper>

          <FormThemeImageWrapper
            selected={selectedTheme === FormTheme.SIMPLE}
            onClick={() => setValue('theme', FormTheme.SIMPLE)}
          >
            <StyledImage src={Images.FormTheme5} />
          </FormThemeImageWrapper>

          <ComingSoonTheme>
            <ComingSoonThemeText>
              <Text align="center" fontWeight="bolder" fontSize="l">
                {t('form.create.step.4.themes_coming_soon')}
              </Text>
            </ComingSoonThemeText>
          </ComingSoonTheme>
        </Flex>
      </Flex>
    </Flex>
  );
};
