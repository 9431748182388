import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)<{
  pointerEvents: React.CSSProperties['pointerEvents'];
  disabled: boolean;
}>`
  background-color: ${({ theme }) => theme.white};
  flex-grow: 1;
  aspect-ratio: 1;
  flex-direction: column;
  gap: ${Spacing.m};
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.md};
  border: 1px solid ${({ theme }) => theme.darkerSub};
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.red : theme.lightMain};
    opacity: 0;
    color: ${({ theme }) => theme.white};
    font-weight: bold;
    font-size: ${({ disabled }) => (disabled ? '1.1rem' : '1.2rem')};
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }

  &:hover::after {
    opacity: 0.9;
  }

  p {
    font-weight: 600;
    font-size: 0.8rem;
    text-align: center;
    user-select: none;
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    p {
      font-size: 1rem;
    }
  }

  pointer-events: ${({ pointerEvents }) => pointerEvents};
`;

interface InputBoxProps {
  icon: IconProp;
  inputName: string;
  onClick?: VoidFunction;
  pointerEvents?: React.CSSProperties['pointerEvents'];
  disabled?: boolean;
}

export const InputBox: React.FC<InputBoxProps> = ({
  icon,
  inputName,
  onClick,
  disabled,
  pointerEvents = 'all',
}) => {
  const { t } = useTranslation();

  return (
    <Container
      pointerEvents={pointerEvents}
      data-text={t(
        disabled ? 'forms.create.custom_fields_limit_reached' : 'common.add',
      )}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled === true}
    >
      <AtiraIcon icon={icon} size="sm" />

      <Text>{inputName}</Text>
    </Container>
  );
};
