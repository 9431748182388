import React, { useEffect } from 'react';

import { permissionActions } from '../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { Permissions } from './Permissions';
import { PermissionContextProvider } from './permissions-context';

export const PermissionsRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  useEffect(() => {
    dispatch(userActions.getLoggedInUser({ userId }));
    dispatch(permissionActions.getMembers({ userId }));
    dispatch(permissionActions.getInvitedMembers({ userId }));
  }, [dispatch, userId]);

  return (
    <PermissionContextProvider>
      <Permissions />
    </PermissionContextProvider>
  );
};
