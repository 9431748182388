import { t } from 'i18next';

import { Layout } from '../Layout';
import { PrivateHelpRouter } from './Private-help.router';
import { PrivateBasicRouter } from './Private-main.router';

export const PrivateRouter = [
  {
    element: <Layout />,
    errorElement: <div>{t('common.error.back_home')}</div>,
    children: [...PrivateBasicRouter, ...PrivateHelpRouter],
  },
];
