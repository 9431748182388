import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { styled } from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { KanbanTask } from '../../../../model/kanban/KanbanTask';
import { Spacing } from '../../../../theme/Spacing';
import { standardDate } from '../../../../utils/Date';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { AtiraIcon } from '../../../../components/AtiraIcon';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  padding: ${Spacing.s};
  margin: 0;
`

const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.red};
`;

type KanbanTaskDetailsProps = {
  kanbanTask: KanbanTask;
  setTask: ATVoidFunction<KanbanTask>;
  setKanbanTaskReadVisible: ATVoidFunction<boolean>;
  setKanbanTaskUpdateVisible: ATVoidFunction<boolean>;
  setDeleteVisible: ATVoidFunction<boolean>;
  setTaskDoneVisible: ATVoidFunction<boolean>
};

export const KanbanTaskDetails: React.FC<KanbanTaskDetailsProps> = ({
  kanbanTask,
  setTask,
  setKanbanTaskReadVisible,
  setKanbanTaskUpdateVisible,
  setDeleteVisible,
  setTaskDoneVisible
}) => {
  const onOpenKanbanTaskUpdateDrawer = () => {
    setKanbanTaskReadVisible(false);
    setTask(kanbanTask);
    setKanbanTaskUpdateVisible(true);
  };

  const onDeleteTask = () => {
    setTask(kanbanTask);
    setDeleteVisible(true);
  };

  const onTaskDone = () => {
    setTask(kanbanTask)
    setTaskDoneVisible(true)
  }

  return (
    <Flex flexDirection="column" gap="m" flex={1}>
      <StyledFlex>
        <BoldText>{t('common.task_name')}:</BoldText>

        <ValueText>{kanbanTask?.name}</ValueText>
      </StyledFlex>

      {kanbanTask?.description ? (
        <StyledFlex>
          <BoldText>{t('common.description')}:</BoldText>

          <ValueText>{kanbanTask.description}</ValueText>
        </StyledFlex>
      ) : null}

      {kanbanTask?.reminder ? (
        <StyledFlex>
          <BoldText>{t('common.remind_date')}</BoldText>

          <ValueText>{standardDate(kanbanTask.reminder)}</ValueText>
        </StyledFlex>
      ) : null}

      <Flex justifyContent="space-between" alignItems="center" marginTop='m'>
        <Flex alignItems='center' gap='s'>
          <StyledButton onClick={onOpenKanbanTaskUpdateDrawer} disabled={kanbanTask.done === true} width='5rem'>
            {t('common.edit')}
          </StyledButton>

          {!kanbanTask.done ?
            <StyledButton icon={faCheck} iconWidth='lg' onClick={onTaskDone} />
            :
            <Flex alignItems='center' gap='s'>
              <AtiraIcon icon={faCheck} size='lg' color='green' />
              <Text color='green' fontWeight={'bold'}>{t('common.done')}</Text>
            </Flex>
          }
        </Flex>

        <Tooltip title={t('common.delete')}>
          <DeleteButton icon={faTrash} iconWidth="2x" onClick={onDeleteTask} />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
