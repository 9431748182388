import { Image, ImageProps } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Images } from '../assets';

const StyledImage = styled(Image)<{ imageLoaded: boolean }>`
  object-fit: ${({ imageLoaded }) => (!imageLoaded ? 'fill' : 'cover')};
`;

export interface AtiraImageProps extends ImageProps {
  width?: string;
  height?: string;
  src: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const AtiraImage: React.FC<AtiraImageProps> = ({
  width = '100%',
  height,
  src,
  onClick,
  style,
  preview = false,
  ...props
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <StyledImage
      referrerPolicy="no-referrer"
      alt="atira_image"
      src={imageLoaded ? src : Images.TransparentPlaceholder}
      width={width}
      height={height}
      onClick={onClick}
      onLoad={() => setImageLoaded(true)}
      style={style}
      preview={preview}
      imageLoaded={imageLoaded}
      {...props}
    />
  );
};
