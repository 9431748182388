import {
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDAndD } from '@fortawesome/free-brands-svg-icons/faDAndD';
import { faAdd } from '@fortawesome/free-solid-svg-icons/faAdd';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faTextHeight } from '@fortawesome/free-solid-svg-icons/faTextHeight';
import { faTextWidth } from '@fortawesome/free-solid-svg-icons/faTextWidth';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faVoicemail } from '@fortawesome/free-solid-svg-icons/faVoicemail';
import clone from 'lodash/clone';
import noop from 'lodash/noop';
import _remove from 'lodash/remove';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { Clickable } from '../../components/Clickable';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { Textarea } from '../../components/Textarea';
import { DNDContext } from '../../components/dnd/DNDContext';
import { DNDSortableDroppableContext } from '../../components/dnd/DNDSortableDroppableContext';
import { SortableChild } from '../../components/dnd/SortableChild';
import { useStepContext } from '../../components/stepper/StepperContext';
import { useStepperRegisterValidation } from '../../components/stepper/useStepperRegisterValidation';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { useAppSelector } from '../../redux/store';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { getFormDefaultInputs } from './FormUtils';
import { FormCreateEditInputUpdateModal } from './components/FormCreateEditInputUpdateModal';
import { InputBox } from './components/InputBox';

const FormContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.l};
  border-radius: ${Rounded.md};
  gap: ${Spacing.l};
  background-color: ${({ theme }) => theme.sub};
  border: 1px dotted ${({ theme }) => theme.darkerSub};
  overflow: scroll;
  min-height: 24rem;
  flex-grow: 1;
`;

const DroppedInputsWrapper = styled(Flex)`
  width: 18rem;
  flex-direction: column;
  gap: ${Spacing.m};
  border-radius: ${Rounded.md};

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    width: 28rem;
  }
`;

const FormTitleInput = styled(Input)`
  border: 0 !important;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0 !important;
  text-align: center;
  background-color: ${({ theme }) => theme.transparent};
  border: 1px solid ${({ theme }) => theme.transparent} !important;
  transition: all 0.3s;
  width: fit-content !important;
  margin: auto;

  &:focus {
    border: 1px solid ${({ theme }) => theme.black} !important;
  }
`;

const StyledInputWrapper = styled.div`
  width: 100%;
  .ant-picker-disabled {
    background-color: ${({ theme }) => theme.white} !important;
  }
`;

const StyledInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.sm};
  height: 2.3rem;

  &::placeholder {
    opacity: 0.7;
  }
`;

const InputLabel = styled(Input)`
  border: 0 !important;
  padding: 0.2rem !important;
  background-color: ${({ theme }) => theme.transparent};

  &::placeholder {
    opacity: 0.7;
  }
`;

const StyledTextArea = styled(Textarea)`
  border-radius: ${Rounded.sm};
  resize: none;

  &::placeholder {
    opacity: 0.7;
  }
`;

const StyledClickable = styled(Clickable)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const AddSelectOptionClickableFlex = styled(Flex)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width: 10%;
  justify-content: center;
  align-items: center;
`;

const InputBoxesWrapper = styled(Flex)`
  gap: ${Spacing.l};
  flex-direction: column;
  width: 25%;

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    width: 15%;
  }
`;

const RequiredStar = styled.div`
  color: red;
  margin-left: ${Spacing.s};
  height: 1rem;
  width: 1rem;
  content: '*';
  font-size: 1.2rem;
`;

const MoveButtonsWrapper = styled(Flex)`
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    display: none;
  }
`;

type DefaultInput = Omit<CreateFormDto['inputs'][number], 'order'> & {
  icon: IconProp;
};

const ExtraInputs: DefaultInput[] = [
  {
    type: InputTypes.TEXT,
    label: '',
    placeholder: '',
    settings: { required: false, maxChar: Lengths.NAME },
    icon: faTextHeight,
    name: '',
  },
  {
    type: InputTypes.PHONE,
    label: '',
    placeholder: '',
    settings: { required: false, maxChar: Lengths.PHONE },
    icon: faPhone,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    settings: { required: false, maxChar: Lengths.DESCRIPTION },
    type: InputTypes.TEXTAREA,
    icon: faTextWidth,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    settings: { required: false },
    type: InputTypes.DATE,
    icon: faCalendar,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    settings: { required: false, maxChar: Lengths.EMAIL },
    type: InputTypes.EMAIL,
    icon: faVoicemail,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    settings: { required: false },
    type: InputTypes.SELECT,
    icon: faVoicemail,
    name: '',
  },
];

const getDefaultMaxChars = (inputType: InputTypes) => {
  switch (inputType) {
    case InputTypes.TEXT:
      return Lengths.NAME;
    case InputTypes.PHONE:
    case InputTypes.NUMBER:
      return Lengths.PHONE;
    default:
      return Lengths.DESCRIPTION;
  }
};

export const FormCreateEditStepFormBuilder: React.FC = () => {
  const { control, getValues, watch } = useFormContext<CreateFormDto>();

  const { append, remove, fields, replace, update } = useFieldArray({
    control,
    name: 'inputs',
  });

  const currentSelects = fields.filter(
    (f) => f.type.toLowerCase() === InputTypes.SELECT.toLowerCase(),
  );

  const currentSelectOptions = currentSelects.map((s) => s.options!);

  const [selectOptions, setSelectOptions] = useState<string[][]>(
    currentSelectOptions || [],
  );
  const [selectOptionsName, setSelectOptionsName] = useState('');
  const [inputUpdateModalVisible, setInputUpdateModalVisible] = useState(false);
  const [selectedInputIndex, setSelectedInputIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const { width } = useWindowDimensions();

  const dragIconStyle = {
    right: i18n.language === AppLangs.AR ? '-2rem' : 'auto',
    left: i18n.language === AppLangs.AR ? 'auto' : '-2rem',
    bottom: '22%',
    transform: 'translateY(40%)',
    display: width > 992 ? 'block' : 'none',
  };

  const { currentStep } = useStepContext();

  const inputs = watch('inputs');
  const [activeItemId, setActiveItemId] = useState<
    UniqueIdentifier | undefined
  >();

  const { t } = useTranslation();
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;

  const getBasicInputIcon = (inputType: InputTypes) => {
    switch (inputType) {
      case InputTypes.PHONE:
        return faPhone;

      case InputTypes.TEXTAREA:
        return faTextHeight;
      default:
        return faTextWidth;
    }
  };

  const [defaultInputs, setDefaultInputs] = useState<DefaultInput[]>(
    getFormDefaultInputs(tableSettings)
      .filter(
        ({ label, name }) =>
          label !== EntryFieldsDefaults.EMAIL &&
          !getValues('inputs').find((x) => x.name === name),
      )
      .map((x) => ({
        ...x,
        label: t(`common.${x.label}`),
        icon: getBasicInputIcon(x.type),
      })),
  );

  const defaultInputsNames = getFormDefaultInputs(tableSettings).map(
    (x) => x.name,
  );

  const usedCustomFields = getValues('inputs').filter(
    (x) => !defaultInputsNames.includes(x.name),
  );

  const canGoNext = useCallback(
    () =>
      inputs.every(
        (inp) =>
          (inp.label?.length || 0) >= Lengths.MIN_3 &&
          inputs
            .filter(
              (inp) =>
                inp.type.toLowerCase() === InputTypes.SELECT.toLowerCase(),
            )
            .every((inp) => (inp.options?.length || 0) > 0),
      ),
    [inputs],
  );

  useStepperRegisterValidation(currentStep, canGoNext);

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItemId(active.id);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return;
    }

    const activeIndex = fields.findIndex((item) => item.id === active.id);
    const overIndex = fields.findIndex((item) => item.id === over.id);
    const newInputs = arrayMove(clone(inputs), activeIndex, overIndex);

    reOrderSelectOptions(activeIndex, overIndex);
    replace(newInputs);
    setActiveItemId(undefined);
  };

  const reOrderSelectOptions = (activeIndex: number, overIndex: number) => {
    const activeIsSelect =
      fields[activeIndex].type?.toLowerCase() ===
      InputTypes.SELECT.toLowerCase();
    const overIsSelect =
      fields[overIndex].type?.toLowerCase() === InputTypes.SELECT.toLowerCase();

    const activeSelectIndex = currentSelects.findIndex(
      (s) => s.id === fields[activeIndex].id,
    );
    const overSelectIndex = currentSelects.findIndex(
      (s) => s.id === fields[overIndex].id,
    );

    setSelectOptions((prev) => {
      const newOptions = [...prev];
      if (activeIsSelect && overIsSelect) {
        [newOptions[activeSelectIndex], newOptions[overSelectIndex]] = [
          newOptions[overSelectIndex],
          newOptions[activeSelectIndex],
        ];
      } else if (activeIsSelect) {
        newOptions[activeSelectIndex] = prev[overIndex];
      } else if (overIsSelect) {
        newOptions[overSelectIndex] = prev[activeIndex];
      }
      return newOptions;
    });
  };

  const onMoveUp = (movedItemId: string) => {
    const activeIndex = fields.findIndex((item) => item.id === movedItemId);
    const overIndex = activeIndex - 1;
    if (overIndex > -1) {
      const newInputs = arrayMove(clone(inputs), activeIndex, overIndex);

      replace(newInputs);
    }
  };

  const onMoveDown = (movedItemId: string) => {
    const activeIndex = fields.findIndex((item) => item.id === movedItemId);
    const overIndex = activeIndex + 1;
    if (overIndex < fields.length) {
      const newInputs = arrayMove(clone(inputs), activeIndex, overIndex);

      replace(newInputs);
    }
  };

  const onRemoveInput = (
    index: number,
    input: FieldArrayWithId<CreateFormDto, 'inputs', 'id'>,
  ) => {
    remove(index);

    if (input.type.toLowerCase() === InputTypes.SELECT.toLowerCase()) {
      const currentSelectIndex = currentSelects.findIndex(
        (s) => s.id === input.id,
      );

      setSelectOptions((prev) => {
        return prev.filter((_, index) => index !== currentSelectIndex);
      });
    }

    if ((input as any).custom === false) {
      setDefaultInputs([...defaultInputs, { ...input, icon: faDAndD }]);
    }
  };

  const onAddDefaultInput = (i: number, x: DefaultInput) => {
    append({
      ...x,
      order: fields.length,
      settings: {
        required: false,
        ...(x.type.toLowerCase() !== InputTypes.DATE.toLowerCase() &&
        x.type.toLowerCase() !== InputTypes.SELECT.toLowerCase()
          ? { maxChar: getDefaultMaxChars(x.type.toUpperCase() as InputTypes) }
          : {}),
      },
    });
    setDefaultInputs(_remove(defaultInputs, (n, index) => index !== i));
  };

  const onAddExtraInput = (x: DefaultInput) => {
    append({ ...x, order: fields.length, custom: true } as any);
  };

  const onCloseSelect = (id: string, selectIndex: number) => {
    const currentFieldIndex = fields.findIndex((field) => field.id === id);

    const currentField = getValues(`inputs.${currentFieldIndex}`);

    if (
      currentFieldIndex === -1 ||
      selectIndex < 0 ||
      selectIndex >= selectOptions.length
    ) {
      return;
    }

    update(currentFieldIndex, {
      ...currentField,
      options: selectOptions[selectIndex],
    });
  };

  const onEditInput = (inputIndex: number) => {
    setSelectedInputIndex(inputIndex);
    setInputUpdateModalVisible(true);
  };

  const renderDragOverlay = () => {
    if (!activeItemId) {
      return null;
    }

    const input = fields.find((item) => item.id === activeItemId);

    if (!input) {
      return null;
    }

    return (
      <Flex flexDirection="column">
        <InputLabel placeholder={input.label} />

        {input.type.toLowerCase() === InputTypes.TEXTAREA.toLowerCase() ? (
          <StyledTextArea
            placeholder={input.placeholder}
            type={input.type.toLowerCase()}
          />
        ) : (
          <StyledInputWrapper>
            <StyledInput
              placeholder={input.placeholder}
              type={input.type?.toLowerCase()}
            />
          </StyledInputWrapper>
        )}
      </Flex>
    );
  };

  const getDropdownRender = useMemo(
    () => (menu: any, index: number) => {
      const onAddSelectOption = (e: React.MouseEvent, index: number) => {
        if (selectOptionsName.trim() === '') {
          return;
        }
        e?.preventDefault();

        setSelectOptions((prev) => {
          const newOptions = [...prev];

          newOptions[index] = [...(newOptions[index] || []), selectOptionsName];

          setSelectOptionsName('');

          return newOptions;
        });

        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      };

      return (
        <>
          <Flex style={{ padding: '0 8px 4px' }} width={'100%'}>
            <Input
              placeholder={t('forms.create.step.2.select.placeholder')}
              ref={inputRef}
              value={selectOptionsName}
              onChange={(e) => setSelectOptionsName(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <AddSelectOptionClickableFlex
              disabled={selectOptions[index]?.length >= 5}
              onClick={(e) =>
                selectOptions[index]?.length >= 5
                  ? {}
                  : onAddSelectOption(e, index)
              }
            >
              <AtiraIcon icon={faAdd} color="main" />
            </AddSelectOptionClickableFlex>
          </Flex>
          <Flex flexDirection="column" gap="s">
            {selectOptions[index]?.map((item, itemIndex) => (
              <Flex
                key={`${item}-${itemIndex}`}
                justifyContent="space-between"
                paddingLeft="m"
                paddingRight="m"
              >
                <Text>{item}</Text>
                <Clickable
                  onClick={() =>
                    setSelectOptions((prev) => {
                      const newOptions = [...prev];

                      newOptions[index] = newOptions[index]?.filter(
                        (x) => x !== item,
                      );
                      return newOptions;
                    })
                  }
                >
                  <AtiraIcon icon={faTrash} color="main" />
                </Clickable>
              </Flex>
            ))}
          </Flex>
        </>
      );
    },
    [selectOptions, selectOptionsName, t],
  );

  return (
    <Flex gap="m" width={'100%'}>
      <InputBoxesWrapper>
        <Flex flexDirection="column" gap="m">
          <Text fontSize="xm" fontWeight={'bold'}>
            {t('forms.create.step.2.default_inputs.title')}
          </Text>

          <Flex gap="s" flexWrap="wrap">
            {defaultInputs.map((x, i) => (
              <Flex width={'47%'} key={`${x.label}-${i}`}>
                <InputBox
                  icon={x.icon}
                  inputName={t(`common.${x.name.toLowerCase()}`)}
                  onClick={() => onAddDefaultInput(i, x)}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="m">
          <Text fontWeight={'bold'}>
            {t('forms.create.step.2.extra_inputs.title')}
          </Text>

          <Flex gap="s" flexWrap="wrap">
            {ExtraInputs.map((x, i) => (
              <Flex width={'47%'} key={`${x.label}-${i}`}>
                <InputBox
                  icon={x.icon}
                  inputName={t(`common.${x.type.toLowerCase()}`)}
                  disabled={usedCustomFields.length >= 3}
                  onClick={() => onAddExtraInput(x)}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </InputBoxesWrapper>

      <FormContainer>
        <Controller
          control={control}
          name="title"
          render={({ field: { value } }) => (
            <FormTitleInput value={value} readOnly />
          )}
        />

        <DNDContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <DroppedInputsWrapper>
            <DNDSortableDroppableContext id="dropZone" items={fields}>
              {fields.map((input, index) => (
                <SortableChild
                  key={input.id}
                  id={input.id}
                  showDragIcon
                  dragIconStyle={dragIconStyle}
                  iconSize="2x"
                >
                  <Flex flexDirection="column" width={'100%'}>
                    <Controller
                      control={control}
                      name={`inputs.${index}.label` as const}
                      render={({ field: { value } }) => (
                        <Flex alignItems="center">
                          <RequiredStar>*</RequiredStar>

                          <InputLabel
                            value={value}
                            readOnly
                            placeholder={t(
                              `forms.create.step.2.${input.type?.toLowerCase()}.label`,
                            )}
                          />
                        </Flex>
                      )}
                    />

                    <Flex alignItems="center" gap="s">
                      <MoveButtonsWrapper>
                        <Button
                          padding="s"
                          margin="0"
                          color="main"
                          backgroundColor="transparent"
                          icon={faAngleUp}
                          onClick={() => onMoveUp(input.id)}
                        />

                        <Button
                          padding="s"
                          margin="0"
                          color="main"
                          backgroundColor="transparent"
                          icon={faAngleDown}
                          onClick={() => onMoveDown(input.id)}
                        />
                      </MoveButtonsWrapper>
                      <Controller
                        control={control}
                        name={`inputs.${index}.placeholder` as const}
                        render={({ field: { value } }) =>
                          // eslint-disable-next-line no-nested-ternary
                          input.type.toLowerCase() ===
                          InputTypes.TEXTAREA.toLowerCase() ? (
                            <StyledTextArea
                              value={value}
                              placeholder={t(
                                `forms.create.step.2.${input.type.toLowerCase()}.placeholder`,
                              )}
                              readOnly
                              type={input.type.toLowerCase()}
                            />
                          ) : input.type.toLowerCase() ===
                            InputTypes.SELECT.toLowerCase() ? (
                            (() => {
                              const currentSelectIndex =
                                currentSelects.findIndex(
                                  (s) => s.id === input.id,
                                );

                              return (
                                <DropDown
                                  containerStyle={{
                                    width: '100%',
                                  }}
                                  value={value}
                                  onChange={noop}
                                  dropdownRender={(menu) =>
                                    getDropdownRender(menu, currentSelectIndex)
                                  }
                                  onDropdownVisibleChange={(open) =>
                                    !open
                                      ? onCloseSelect(
                                          input.id,
                                          currentSelectIndex,
                                        )
                                      : {}
                                  }
                                  options={selectOptions[
                                    currentSelectIndex
                                  ]?.map((item) => ({
                                    label: item,
                                    value: item,
                                  }))}
                                />
                              );
                            })()
                          ) : (
                            <StyledInputWrapper>
                              <StyledInput
                                value={
                                  input.type.toLowerCase() !==
                                  InputTypes.DATE.toLowerCase()
                                    ? value
                                    : undefined
                                }
                                placeholder={
                                  value ||
                                  t(
                                    `forms.create.step.2.${input.type?.toLowerCase()}.placeholder`,
                                  )
                                }
                                readOnly
                                disabled={
                                  input.type.toLowerCase() ===
                                  InputTypes.DATE.toLowerCase()
                                }
                                type={input.type?.toLowerCase()}
                              />
                            </StyledInputWrapper>
                          )
                        }
                      />

                      <Flex alignItems="center" gap="s">
                        <StyledClickable
                          disabled={
                            (input as any).custom === false &&
                            input.type.toLowerCase() ===
                              InputTypes.EMAIL.toLowerCase()
                          }
                          onClick={() =>
                            (input as any).custom === false &&
                            input.type.toLowerCase() ===
                              InputTypes.EMAIL.toLowerCase()
                              ? {}
                              : onRemoveInput(index, input)
                          }
                        >
                          <AtiraIcon icon={faTrash} color="main" size="xl" />
                        </StyledClickable>

                        <StyledClickable onClick={() => onEditInput(index)}>
                          <AtiraIcon icon={faEdit} color="main" size="xl" />
                        </StyledClickable>
                      </Flex>
                    </Flex>
                  </Flex>
                </SortableChild>
              ))}
            </DNDSortableDroppableContext>

            <DragOverlay
              dropAnimation={{
                duration: 500,
                easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              }}
            >
              {renderDragOverlay()}
            </DragOverlay>
          </DroppedInputsWrapper>
        </DNDContext>
      </FormContainer>

      <FormCreateEditInputUpdateModal
        inputIndex={selectedInputIndex}
        isOpen={inputUpdateModalVisible}
        onClose={() => setInputUpdateModalVisible(false)}
      />
    </Flex>
  );
};
