import { useCallback, useEffect } from 'react';

import { useStepContext } from './StepperContext';

export const useStepperRegisterValidation = (
  step: number,
  validate: () => boolean,
) => {
  const { registerValidation } = useStepContext();

  const memoizedValidate = useCallback(validate, [validate]);

  useEffect(() => {
    registerValidation(step, memoizedValidate);
  }, [step, registerValidation, memoizedValidate]);
};
