import { UniqueIdentifier } from '@dnd-kit/core';
import { createContext } from 'react';

interface DragIndexState {
  active: UniqueIdentifier;
  over: UniqueIdentifier | undefined;
  direction?: 'left' | 'right';
}

export const DragIndexContext = createContext<DragIndexState>({
  active: -1,
  over: -1,
});
