import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../../assets';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Spacing } from '../../../theme/Spacing';

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.m};
`;

export const NotificationsUpdatesTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text>{t('header.bell.drawer.articles_news.description')}</Text>

      <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
    </Container>
  );
};
