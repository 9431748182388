import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { Images } from '../../../../assets';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { AtiraImage } from '../../../../components/Image';
import { Text } from '../../../../components/Text';
import i18n, { AppLangs } from '../../../../i18n';
import { Widget } from '../../../../model/widget/Widget';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { widgetActions } from '../../../../redux/widget/widget.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

const ToggleWidgetButton = styled(Button)`
  height: 100%;
  aspect-ratio: 1;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  margin: 0;
`;

type WidgetCustomizeDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const WidgetCustomizeDrawer: React.FC<WidgetCustomizeDrawerProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const widgets = useAppSelector(widgetSliceSelectors.selectUserWidgets);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const drawerPlacement = i18n.language === AppLangs.AR ? 'left' : 'right';

  const onToggleWidget = async (widget: Widget) => {
    try {
      setLoading(true);

      await dispatch(
        widgetActions.EditUserWidget({
          widgetId: widget._id,
          userId,
          active: !widget.active,
        }),
      ).unwrap();
      await dispatch(widgetActions.getUserWidgets({ userId })).unwrap();

      AtiraToast.success(
        widget.active
          ? t('widget.disable.success')
          : t('widget.enable.success'),
      );
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={t('widget.customize')}
      onClose={onClose}
      open={open}
      placement={drawerPlacement}
    >
      <Flex marginBottom="m">
        <Text fontSize="l" fontWeight={'bold'}>
          {t('widget.customize.description')}
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height={'80%'}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
          marginTop="s"
          gap="m"
        >
          {widgets.map((widget) => (
            <Flex
              key={widget._id}
              alignItems="center"
              justifyContent="start"
              gap="s"
              width={'100%'}
            >
              <Flex
                backgroundColor={theme.lightergray}
                width={'80%'}
                alignItems="center"
                borderRadius="pill"
              >
                <Text
                  fontSize="xm"
                  fontWeight={'bold'}
                  style={{ padding: '0.5rem' }}
                >
                  {t(`dashboard.widget.title.${widget.name}`)}
                </Text>
              </Flex>

              {widget.toggleAble ? (
                <Tooltip
                  title={
                    widget.active ? t('common.disable') : t('common.enable')
                  }
                >
                  <ToggleWidgetButton
                    loading={loading}
                    disabled={loading}
                    onClick={() => onToggleWidget(widget)}
                    icon={widget.active ? faMinusCircle : faPlusCircle}
                    iconWidth="3x"
                  />
                </Tooltip>
              ) : null}
            </Flex>
          ))}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="s"
        >
          <Text fontSize="m" color="gray">
            {t('dashboard.widget.customize.more_widget')}
          </Text>

          <AtiraImage src={Images.AtiraSpaceLogoBetaMain} />
        </Flex>
      </Flex>
    </Drawer>
  );
};
