import React from 'react';

import { EntriesRoute } from './EntriesRoute';
import { EntriesContextProvider } from './entries-context';

export const EntriesRouteWithProvider: React.FC = () => {
  return (
    <EntriesContextProvider>
      <EntriesRoute />
    </EntriesContextProvider>
  );
};
