import { createSlice } from '@reduxjs/toolkit';

import { WidgetAxios } from '../../axios/widget/widget.axios';
import { Widget } from '../../model/widget/Widget';
import { EditUserWidgetDto } from '../../model/widget/dto/EditUserWidgetDto';
import { GetUserWidgetsDto } from '../../model/widget/dto/GetUserWidgetsDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface WidgetReducer {
  widgets: Widget[];
}

const initialState = Object.freeze<WidgetReducer>({
  widgets: [],
});

const getUserWidgets = AtiraThunk<Widget[], GetUserWidgetsDto>(
  '/widget',
  (dto) => WidgetAxios.getUserWidgets(dto),
);

const EditUserWidget = AtiraThunk<void, EditUserWidgetDto>(
  '/widget/:id/edit',
  (dto) => WidgetAxios.EditUserWidget(dto),
);

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(getUserWidgets.fulfilled, (state, action) => {
      state.widgets = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      state.widgets = [];
    });
  },
});

export const widgetActions = {
  getUserWidgets,
  EditUserWidget,
};

export default widgetSlice.reducer;
