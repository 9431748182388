import React, { ReactNode, createContext, useContext, useState } from 'react';

import { Entry } from '../../model/entry/Entry';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';

interface EntriesContextType {
  entry: Entry | null;
  setEntry: ATVoidFunction<Entry>;
  //
  task: LeadTask | ContactTask | null;
  setTask: ATVoidFunction<LeadTask | ContactTask>;
  tasksReadVisible: boolean;
  setTasksReadVisible: ATVoidFunction<boolean>;
  //
  contactTaskUpdateVisible: boolean;
  setContactTaskUpdateVisible: ATVoidFunction<boolean>;
  //
  leadTaskUpdateVisibile: boolean;
  setLeadTaskUpdateVisible: ATVoidFunction<boolean>;
  leadTaskCreateVisible: boolean;
  setLeadTaskCreateVisible: ATVoidFunction<boolean>;
  //
  deleteTaskVisible: boolean;
  setDeleteTaskVisible: ATVoidFunction<boolean>;
  //
  taskDoneVisible: boolean;
  setTaskDoneVisible: ATVoidFunction<boolean>
}

const EntriesContext = createContext<EntriesContextType | undefined>(undefined);

export const useEntriesContext = () => {
  const context = useContext(EntriesContext);

  if (!context) {
    throw new Error('useEntriesContext must be used within a EntriesProvider');
  }

  return context;
};

interface EntriesContextProviderProps {
  children: ReactNode;
}

export const EntriesContextProvider: React.FC<EntriesContextProviderProps> = ({
  children,
}) => {
  const [entry, setEntry] = useState<Entry | null>(null);
  //
  const [task, setTask] = useState<LeadTask | ContactTask | null>(null);
  //
  const [contactTaskUpdateVisible, setContactTaskUpdateVisible] =
    useState(false);
  const [tasksReadVisible, setTasksReadVisible] = useState(false);
  const [leadTaskUpdateVisibile, setLeadTaskUpdateVisible] = useState(false);
  const [leadTaskCreateVisible, setLeadTaskCreateVisible] = useState(false);
  const [deleteTaskVisible, setDeleteTaskVisible] = useState(false);
  const [taskDoneVisible, setTaskDoneVisible] = useState(false)

  return (
    <EntriesContext.Provider
      value={{
        entry,
        setEntry,
        //
        task,
        setTask,
        //
        contactTaskUpdateVisible,
        setContactTaskUpdateVisible,
        tasksReadVisible,
        setTasksReadVisible,
        leadTaskUpdateVisibile,
        setLeadTaskUpdateVisible,
        leadTaskCreateVisible,
        setLeadTaskCreateVisible,
        deleteTaskVisible,
        setDeleteTaskVisible,
        taskDoneVisible,
        setTaskDoneVisible
      }}
    >
      {children}
    </EntriesContext.Provider>
  );
};
