import { DatePicker, DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { Flex } from './Flex';
import {
  LabelDirection,
  TitleAlignment,
} from './shared/types/SharedLabelProps';

const StyledDatePicker = styled(DatePicker)`
  border: 1px solid ${({ theme }) => theme.lightgray};
  height: 2.3rem;

  &:focus {
    border: 1px solid ${({ theme }) => theme.lightgray};
    box-shadow: none;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.lightgray};
  }
`;

type AtiraDatePickerProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  value: number | null | Date | Dayjs;
  onChange: (date: Date) => void;
  titleAlign?: keyof typeof TitleAlignment;
  labelDirection?: keyof typeof LabelDirection;
};

export const AtiraDatePicker: React.FC<AtiraDatePickerProps> = (props) => {
  const theme = useTheme();

  return (
    <Flex
      width={'100%'}
      gap="s"
      flexDirection={
        props.labelDirection === LabelDirection.HORIZONTAL ? 'row' : 'column'
      }
    >
      {props.title ? (
        <label
          htmlFor={props.id}
          style={{
            color: theme.black,
            cursor: 'pointer',
            textAlign: props.titleAlign,
            width: 'fit-content',
            alignSelf: props.titleAlign || 'flex-start',
            flex: 1,
            margin:
              props.labelDirection === LabelDirection.HORIZONTAL ? 'auto' : '0',
          }}
        >
          {props.title}
        </label>
      ) : null}
      <StyledDatePicker
        {...(props as any)}
        value={props.value ? dayjs(props.value) : null}
        onChange={props.onChange as any}
        format={'DD/MM/YYYY'}
      />
    </Flex>
  );
};
