import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const taskState = (state: RootState) => state.task;

const selectLeadTasks = createSelector(
  taskState,
  (state) => state.leadTasks || [],
);
const selectLeadTasksLoading = createSelector(
  taskState,
  (state) => state.leadTasksLoading,
);

const selectContactTasks = createSelector(
  taskState,
  (state) => state.contactTasks || [],
);
const selectContactTasksLoading = createSelector(
  taskState,
  (state) => state.contactTasksLoading,
);

const selectKanbanTasks = createSelector(
  taskState,
  (state) => state.kanbanTasks || [],
);
const selectKanbanTasksLoading = createSelector(
  taskState,
  (state) => state.kanbanTasksLoading,
);

const selectTasksSettings = createSelector(
  taskState,
  (state) => state.tasksSettings,
);

const selectAllTasks = createSelector(
  taskState,
  (state) => state.allTasks || [],
);

const selectAllTasksLoading = createSelector(
  taskState,
  (state) => state.allTasksLoading,
);

const selectAllTasksMeta = createSelector(
  taskState,
  (state) => state.allTasksMeta,
);

const selectTasksPage = createSelector(
  taskState,
  (state) => state.tasksPage || 1,
);

const selectTasksPageSize = createSelector(
  taskState,
  (state) => state.tasksPageSize || 10,
);

export const taskSliceSelectors = {
  selectLeadTasks,
  selectLeadTasksLoading,
  selectContactTasks,
  selectContactTasksLoading,
  selectKanbanTasks,
  selectKanbanTasksLoading,
  selectTasksSettings,
  selectAllTasks,
  selectAllTasksLoading,
  selectAllTasksMeta,
  selectTasksPage,
  selectTasksPageSize,
};
