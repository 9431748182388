import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Checkbox, CheckboxOptionType, GetProp, Tooltip } from 'antd';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraCheckBoxGroup } from '../../../../components/AtiraCheckBoxGroup';
import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Spinner } from '../../../../components/Spinner';
import { Text } from '../../../../components/Text';
import { Entry } from '../../../../model/entry/Entry';
import { PageMeta } from '../../../../model/meta/PageMeta';
import { LeadTask } from '../../../../model/task/LeadTask';
import { TaskKind } from '../../../../model/task/types/TaskKind.enum';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { taskSliceSelectors } from '../../../../redux/task/task.selector';
import { taskActions } from '../../../../redux/task/task.slice';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { Spacing } from '../../../../theme/Spacing';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { standardDate } from '../../../../utils/Date';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  padding: ${Spacing.s};
  margin: 0;
`;
const StyledAtiraCheckBoxGroup = styled(AtiraCheckBoxGroup)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.red};
`;

interface LeadTaskDetailsProps {
  leadTask: LeadTask;
  setTask: ATVoidFunction<LeadTask>;
  setLeadTaskReadVisible: ATVoidFunction<boolean>;
  setLeadTaskUpdateVisible: ATVoidFunction<boolean>;
  setDeleteVisible: ATVoidFunction<boolean>;
  //
  setEntry?: ATVoidFunction<Entry>;
  //
  setTaskDoneVisible: ATVoidFunction<boolean>;
}

export const LeadTaskDetails: React.FC<LeadTaskDetailsProps> = ({
  leadTask,
  setTask,
  setLeadTaskReadVisible,
  setLeadTaskUpdateVisible,
  setDeleteVisible,
  setEntry,
  setTaskDoneVisible,
}) => {
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const taskSettings = useAppSelector(taskSliceSelectors.selectTasksSettings);
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onOpenUpdateDrawer = () => {
    setLeadTaskReadVisible(false);
    setTask(leadTask);
    setLeadTaskUpdateVisible(true);
  };

  const onDeleteTask = () => {
    setTask(leadTask);
    setDeleteVisible(true);
  };

  const onTaskDone = () => {
    setTask(leadTask);
    setTaskDoneVisible(true);
  };

  const getLeadTaskAttemptsOptions = () => {
    return Array.from(
      {
        length: Math.max(
          taskSettings?.settings.LEAD.contactAttempts || 3,
          leadTask.contactAttempts!,
        ),
      },
      (_, i) => i + 1,
    ).map((item) => ({
      id: `${leadTask._id}-${item}`,
      label: (
        <Flex gap="s" alignItems="center">
          <Text
            style={{ whiteSpace: 'nowrap' }}
          >{`${t('common.attempt')} ${item}`}</Text>
          {checkLoading && checkedBoxes.length === item ? (
            <Spinner color="main" size="1rem" />
          ) : null}
        </Flex>
      ),
      value: String(item),
      disabled:
        checkLoading ||
        checkedBoxes.includes(String(item)) ||
        item - (leadTask.contactAttempts || 0) !== 1,
    })) as CheckboxOptionType[];
  };

  const getCheckedValues = (contactAttempts: number) => {
    if (contactAttempts === 0) {
      return [];
    }

    return Array.from({ length: contactAttempts }, (_, i) => i).map((item) =>
      String(item + 1),
    );
  };

  const incrementLeadTaskAttempt: GetProp<
    typeof Checkbox.Group,
    'onChange'
  > = async (checkedValues) => {
    try {
      setCheckLoading(true);
      setCheckedBoxes((prev) => [...prev, `${checkedValues.length - 1}`]);

      await dispatch(
        entryActions.incrementLeadTaskAttempt({
          taskId: leadTask._id!,
          userId,
        }),
      ).unwrap();

      if (pathname.includes('/task')) {
        if (leadTask.kind === TaskKind.CONTACT) {
          await dispatch(
            taskActions.getContactTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
        } else if (leadTask.kind === TaskKind.KANBAN) {
          await dispatch(
            taskActions.getKanbanTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
        } else if (leadTask.kind === TaskKind.LEAD) {
          const tasks = await dispatch(
            taskActions.getLeadTasks({ userId, meta: PageMeta.create() }),
          ).unwrap();
          const updatedTask = tasks.data.find((t) => t._id === leadTask._id)!;
          if (updatedTask) {
            setTask(updatedTask as LeadTask);
          }
        }
      } else {
        const entry = await dispatch(
          entryActions.getEntryById({ entryId: leadTask.entryId!, userId }),
        ).unwrap();
        setEntry?.(entry);
      }

      AtiraToast.success(t('entries.contact_attempt.increment.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
      setCheckedBoxes((prev) => prev.slice(0, -1));
    } finally {
      setCheckLoading(false);
    }
  };

  useEffect(() => {
    setCheckedBoxes(getCheckedValues(leadTask?.contactAttempts!));
  }, [leadTask?.contactAttempts]);

  return (
    <Flex flex={1} flexDirection="column" gap="m">
      <StyledFlex>
        <BoldText>{t('task.read.name')}</BoldText>
        <ValueText>{leadTask.name}</ValueText>
      </StyledFlex>

      {leadTask.reminder ? (
        <StyledFlex>
          <Flex alignItems="center" gap="s">
            <BoldText>{t('common.remind_date')}: </BoldText>
          </Flex>

          <ValueText>{standardDate(leadTask.reminder)}</ValueText>
        </StyledFlex>
      ) : null}

      {leadTask.description ? (
        <Flex gap="s" flexWrap="wrap">
          <BoldText>{t('common.description')}:</BoldText>
          <Flex>
            <Text wordBreak="break-word">{leadTask.description}</Text>
          </Flex>
        </Flex>
      ) : null}

      {!isNil(leadTask.contactAttempts) ? (
        <Flex flexDirection="column" gap="s">
          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('entries.details.contact_attempts')}: </BoldText>
            </Flex>

            <Text fontSize="s" color="gray">
              {t('entries.details.contact_attempts.description')}
            </Text>

            <Text fontSize="s" color="gray">
              {t('entries.details.contact_attempts.update-description')}
            </Text>
          </StyledFlex>

          <StyledAtiraCheckBoxGroup
            onChange={incrementLeadTaskAttempt}
            options={getLeadTaskAttemptsOptions()}
            value={checkedBoxes}
            fontSize="1rem"
            key={leadTask._id}
          />
        </Flex>
      ) : null}

      <Flex justifyContent="space-between" alignItems="center" marginTop="m">
        <Flex gap="s" alignItems="center">
          <StyledButton
            onClick={onOpenUpdateDrawer}
            disabled={leadTask.done === true}
            width="5rem"
          >
            {t('common.edit')}
          </StyledButton>

          {!leadTask.done ? (
            <Tooltip title={t('common.mark-done')}>
              <StyledButton
                icon={faCheck}
                iconWidth="lg"
                onClick={onTaskDone}
              />
            </Tooltip>
          ) : (
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCheck} size="lg" color="green" />
              <Text color="green" fontWeight={'bold'}>
                {t('common.done')}
              </Text>
            </Flex>
          )}
        </Flex>

        <Tooltip title={t('common.delete')}>
          <DeleteButton
            disabled={!isNil(leadTask.contactAttempts)}
            icon={faTrash}
            iconWidth="2x"
            onClick={onDeleteTask}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
