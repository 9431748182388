import { createSlice } from '@reduxjs/toolkit';

interface AppReducer {
  sideMenuCollapsed?: boolean;
}

const initialState = Object.freeze<AppReducer>({
  sideMenuCollapsed: false,
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSideMenuCollapsed: (state, action) => {
      state.sideMenuCollapsed = action.payload;
    },
  },
});

export const appActions = { ...appSlice.actions };

export default appSlice.reducer;
