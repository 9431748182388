import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { useMultiLangNavigate } from '../../../hooks/useMultiLangNavigate';
import { ForgotPasswordNewPasswordDto } from '../../../model/user/dto/ForgotPasswordNewPasswordDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;
  margin-top: ${Spacing.m};

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2.3rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: 0.5rem;
  transition: border 0.2s;
  font-size: 1rem;

  &:focus {
    border: 1px solid #86b7fe !important;
  }
`;

export const ForgotPasswordNewPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordNewPasswordDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useMultiLangNavigate();

  const onNext = async () => {
    try {
      setLoading(true);

      await dispatch(
        userActions.forgotPasswordNewPassword({
          newPassword: getValues('newPassword'),
          token: getValues('token'),
        }),
      ).unwrap();
      AtiraToast.success(t('resetpassword.newPass.success'));
      navigate('/login');
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex justifyContent="center" flexDirection="column" width={'100%'} gap="s">
      <Text color="gray" align="center">
        {t('resetpassword.newPass.title')}
      </Text>
      <Controller
        name="newPassword"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('resetpassword.newPass.input.placeholder')}
            value={value}
            onChange={onChange}
            type="password"
            id="newpassword"
            valid={isEmpty(errors.newPassword)}
            placeholder={t('resetpassword.newPass.input.placeholder')}
          />
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={loading}>
        {t('resetpassword.newPass.button')}
      </StyledButton>
    </Flex>
  );
};
