import React, { Fragment, useState } from 'react';

import { TableHeader } from '../../../components/table/TableHeader';
import { TasksSettingsDrawer } from './TasksSettingsDrawer';

export const TaskTableHeader: React.FC = () => {
  const [tasksSettingsDrawerVisible, setTasksSettingsDrawerVisible] =
    useState(false);

  const onFetch = () => {};

  const onSearch = () => {};

  const onFilter = () => {};

  return (
    <Fragment>
      <TableHeader
        onFetch={onFetch}
        onSearch={onSearch}
        onSettings={() => setTasksSettingsDrawerVisible(true)}
      />
      <TasksSettingsDrawer
        open={tasksSettingsDrawerVisible}
        onClose={() => setTasksSettingsDrawerVisible(false)}
      />
    </Fragment>
  );
};
