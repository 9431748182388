import React from 'react';
import styled from 'styled-components';

import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { Spacing } from '../../theme/Spacing';
import { Flex } from '../Flex';
import { Text } from '../Text';

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${Spacing.m};
  box-shadow: ${Shadow.SM};
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
`;

interface StepperStepProps {
  title: string;
  children: React.ReactNode;
}

export const StepperStep: React.FC<StepperStepProps> = ({
  title = 'Step',
  children,
}) => {
  return (
    <Flex width="100%" flexDirection="column" gap="m" marginBottom="xl">
      <Text fontWeight="bold" fontSize="xm">
        {title}
      </Text>
      <Container>{children}</Container>
    </Flex>
  );
};
