import { Widget } from '../../model/widget/Widget';
import { EditUserWidgetDto } from '../../model/widget/dto/EditUserWidgetDto';
import { GetUserWidgetsDto } from '../../model/widget/dto/GetUserWidgetsDto';
import { AtiraApi } from '../AxiosClient';

export class WidgetAxios {
  static getUserWidgets(dto: GetUserWidgetsDto) {
    return AtiraApi.post<Widget[]>(`/widget`, dto).then(({ data }) => data);
  }
  static EditUserWidget(dto: EditUserWidgetDto) {
    return AtiraApi.post(`/widget/${dto.widgetId}/edit`, dto).then(
      ({ data }) => data,
    );
  }
}
