import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { TableProps } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { WarningModal } from '../../components/WarningModal';
import { Entry } from '../../model/entry/Entry';
import { GetLatestEntriesFilterDto } from '../../model/entry/dto/GetLatestEntriesFilterDto';
import { SearchEntryDto } from '../../model/entry/dto/SearchEntryDto';
import { EntryKind } from '../../model/entry/types/EntryKind.enum';
import { PageMeta } from '../../model/meta/PageMeta';
import { ContactTask } from '../../model/task/ContactTask';
import { LeadTask } from '../../model/task/LeadTask';
import { TaskKind } from '../../model/task/types/TaskKind.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { entryActions } from '../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { kanbanActions } from '../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { truncateString } from '../../utils/String';
import { KanbanCardCreateDrawer } from '../kanban/components/KanbanCardCreateDrawer';
import { EntryConvertModal } from './components/EntryConvertModal';
import { EntryCreateModal } from './components/EntryCreateModal';
import { EntryDeleteModal } from './components/EntryDeleteModal';
import { EntryDetailsDrawer } from './components/EntryDetailsDrawer';
import { EntryTableBulkActionsHeader } from './components/EntryTableBulkActionsHeader';
import { EntryTableHeader } from './components/EntryTableHeader';
import { EntryUpdateDrawer } from './components/EntryUpdateDrawer';
import { TasksReadDrawer } from './components/TasksReadDrawer';
import { ContactTaskCreateDrawer } from './components/contact-tasks/ContactTaskCreateDrawer';
import { ContactTaskUpdateDrawer } from './components/contact-tasks/ContactTaskUpdateDrawer';
import { LeadTaskCreateDrawer } from './components/lead-tasks/LeadTaskCreateDrawer';
import { LeadTaskUpdateDrawer } from './components/lead-tasks/LeadTaskUpdateDrawer';
import { EntiresTableWithDND } from './components/table/EntriesTableWithDND';
import { useEntriesContext } from './entries-context';
import { createEntriesTableColumns } from './utils/createEntriesTableColumns';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 1rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const EntriesRoute: React.FC = () => {
  const formMethods = useForm<GetLatestEntriesFilterDto | SearchEntryDto>({
    defaultValues: {
      formId: undefined,
      kind: undefined,
      range: undefined,
      keyword: '',
    },
  });
  const keyword = formMethods.watch('keyword');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const [entryDeleteLoading, setEntryDeleteLoading] = useState(false);
  const [entryDeleteModalVisible, setEntryDeleteModalVisible] = useState(false);
  const [entryConvertModalVisible, setEntryConvertModalVisible] =
    useState(false);
  const [entryDetailsDrawerVisible, setEntryDetailsDrawerVisible] =
    useState(false);
  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);
  const [kanbanCardCreateDrawerVisible, setKanbanCardCreateDrawerVisible] =
    useState(false);
  const [entryUpdateDrawerVisible, setEntryUpdateDrawerVisible] =
    useState(false);
  const [contactTaskCreateDrawerVisible, setContactTaskCreateDrawerVisible] =
    useState(false);
  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false);
  const [taskDoneLoading, setTaskDoneLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

  const {
    entry: currentEntry,
    setEntry,
    task,
    contactTaskUpdateVisible,
    setContactTaskUpdateVisible,
    tasksReadVisible,
    setTasksReadVisible,
    leadTaskUpdateVisibile,
    setLeadTaskUpdateVisible,
    leadTaskCreateVisible,
    setLeadTaskCreateVisible,
    deleteTaskVisible,
    setDeleteTaskVisible,
    taskDoneVisible,
    setTaskDoneVisible,
  } = useEntriesContext();

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const entries = useAppSelector(entrySliceSelectors.selectLatestEntries);
  const searchEntriesLoading = useAppSelector(
    entrySliceSelectors.selectSearchEntriesLoading,
  );
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const entriesMeta = useAppSelector(
    entrySliceSelectors.selectLatestEntriesMeta,
  );
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );
  const entriesPage = useAppSelector(
    entrySliceSelectors.selectEntriesTablePage,
  );
  const entriesPageSize = useAppSelector(
    entrySliceSelectors.selectEntriesTablePageSize,
  );

  const deleteEntryWarning = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryDeleteModalVisible(true);
    },
    [setEntry],
  );

  const convertEntryWarning = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryConvertModalVisible(true);
    },
    [setEntry],
  );

  const onShowEntryDetails = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryDetailsDrawerVisible(true);
    },
    [setEntry],
  );

  const onCreateDeal = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setKanbanCardCreateDrawerVisible(true);
    },
    [setEntry],
  );

  const updateEntry = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setEntryUpdateDrawerVisible(true);
    },
    [setEntry],
  );

  const createContactTask = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setContactTaskCreateDrawerVisible(true);
    },
    [setEntry],
  );

  const createLeadTask = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setLeadTaskCreateVisible(true);
    },
    [setEntry, setLeadTaskCreateVisible],
  );

  const readTasks = useCallback(
    (entry: Entry) => {
      setEntry(entry);
      setTasksReadVisible(true);
      dispatch(entryActions.getEntryById({ entryId: entry._id, userId }));
    },
    [dispatch, setEntry, setTasksReadVisible, userId],
  );

  const deleteEntry = async (instantDelete: boolean) => {
    try {
      setEntryDeleteLoading(true);
      await dispatch(
        entryActions.deleteEntry({
          userId: userId,
          entryId: currentEntry?._id!,
          instantDelete,
        }),
      ).unwrap();

      setEntryDeleteModalVisible(false);

      if (keyword) {
        await dispatch(
          entryActions.searchEntries({
            keyword,
            userId,
          }),
        ).unwrap();
      } else {
        await dispatch(
          entryActions.getLatestEntries({
            userId,
            meta: {
              count: entriesPageSize,
              page: entriesPage - 1,
            },
          }),
        ).unwrap();
      }

      AtiraToast.success(t('entries.delete.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEntryDeleteLoading(false);
    }
  };

  const deleteTask = async () => {
    try {
      setDeleteTaskLoading(true);

      if (task?.kind === TaskKind.LEAD) {
        await dispatch(
          entryActions.deleteLeadTask({ taskId: task?._id!, userId }),
        ).unwrap();
      } else {
        await dispatch(
          entryActions.deleteContactTask({ taskId: task?._id!, userId }),
        ).unwrap();
      }

      const updatedEntry = await dispatch(
        entryActions.getEntryById({ entryId: currentEntry?._id!, userId }),
      ).unwrap();

      setEntry(updatedEntry);

      setDeleteTaskVisible(false);

      AtiraToast.success(t('tasks.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDeleteTaskLoading(false);
    }
  };

  const onPageChange = async (page: number, pageSize: number) => {
    dispatch(entryActions.setEntriesTablePage(page));
  };

  const onTaskDone = async () => {
    try {
      setTaskDoneLoading(true);

      if (task?.kind === TaskKind.LEAD) {
        await dispatch(
          entryActions.updateLeadTask({
            userId,
            ownerId: userId,
            taskId: task._id,
            done: true,
          }),
        ).unwrap();
      } else {
        await dispatch(
          entryActions.updateContactTask({
            userId,
            ownerId: userId,
            taskId: task!._id,
            done: true,
          }),
        ).unwrap();
      }

      const updatedEntry = await dispatch(
        entryActions.getEntryById({ entryId: currentEntry?._id!, userId }),
      ).unwrap();

      setEntry(updatedEntry);

      setTaskDoneVisible(false);

      AtiraToast.success(t('tasks.done.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setTaskDoneLoading(false);
    }
  };

  const onCloseContactTaskUpdateReadDrawer = () => {
    setContactTaskUpdateVisible(false);
    setTasksReadVisible(true);
  };

  const onCloseLeadTaskUpdateReadDrawer = async () => {
    try {
      const entry = await dispatch(
        entryActions.getEntryById({ entryId: task?.entryId!, userId }),
      ).unwrap();
      setEntry(entry);
      setLeadTaskUpdateVisible(false);
      setTasksReadVisible(true);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    }
  };

  const onTableShowSize = (curr: number, newSize: number) => {
    dispatch(entryActions.setEntriesTablePageSize(newSize));
    onPageChange(1, newSize);
  };

  const tableColumns = createEntriesTableColumns(tableSettings?.columns || [], {
    delete: deleteEntryWarning,
    convert: convertEntryWarning,
    details: onShowEntryDetails,
    deal: onCreateDeal,
    createContactTask,
    createLeadTask,
    readTasks,
    t,
    update: updateEntry,
  }) as TableProps['columns'];

  useEffect(() => {
    dispatch(entryActions.getUserTableSettings({ userId }));
    dispatch(kanbanActions.getUserDefaultKanban({ userId }));
  }, [userId, dispatch]);

  useEffect(() => {
    if (state?.useFilter) {
      const form = state.useFilter;
      formMethods.setValue('formId', form?._id);
    }
    dispatch(
      entryActions.getLatestEntries({
        userId,
        ...omitBy(formMethods.getValues(), isNil),
        meta: PageMeta.create({
          count: entriesPageSize,
          page: entriesPage - 1,
        }),
      }),
    );
  }, [dispatch, entriesPage, entriesPageSize, formMethods, state, userId]);

  return (
    <FormProvider {...formMethods}>
      <Flex flexDirection="column" height={'100%'}>
        <SubHeader
          title={t('common.entries')}
          icon={faPlus}
          onClick={() => setEntryCreateModalVisible(true)}
          buttonTitle={t('common.create')}
        />

        <Wrapper>
          {selectedRows.length ? (
            <EntryTableBulkActionsHeader
              setSelectedEntriesIds={setSelectedRows}
              selctedEntriesIds={selectedRows}
            />
          ) : (
            <EntryTableHeader />
          )}

          <EntiresTableWithDND
            key={JSON.stringify(tableColumns)}
            columns={tableColumns}
            data={entries}
            pagination={{
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              pageSize: entriesPageSize,
              current: entriesPage,
              total: entriesMeta.total,
              onChange: onPageChange,
              showSizeChanger: true,
              onShowSizeChange: onTableShowSize,
            }}
            loading={entriesLoading || searchEntriesLoading}
            selectedRowKeys={selectedRows}
            onSelectedRowKeysChange={setSelectedRows}
          />

          <EntryDeleteModal
            isOpen={entryDeleteModalVisible}
            onConfirm={deleteEntry}
            loading={entryDeleteLoading}
            onClose={() => setEntryDeleteModalVisible(false)}
          />

          <EntryConvertModal
            key={currentEntry?._id}
            isOpen={entryConvertModalVisible}
            onClose={() => setEntryConvertModalVisible(false)}
            entry={currentEntry}
          />

          <EntryCreateModal
            isOpen={entryCreateModalVisible}
            onClose={() => setEntryCreateModalVisible(false)}
            showSelect={forms.length > 0}
            key={currentForm?._id}
          />

          <EntryDetailsDrawer
            key={currentEntry?._id}
            isOpen={entryDetailsDrawerVisible}
            onClose={() => setEntryDetailsDrawerVisible(false)}
            entry={currentEntry}
          />

          <EntryUpdateDrawer
            isOpen={entryUpdateDrawerVisible}
            entry={currentEntry}
            onClose={() => setEntryUpdateDrawerVisible(false)}
          />

          {currentEntry?.kind === EntryKind.CONTACT ? (
            <Flex>
              <ContactTaskCreateDrawer
                isOpen={contactTaskCreateDrawerVisible}
                entry={currentEntry}
                onClose={() => setContactTaskCreateDrawerVisible(false)}
              />

              <KanbanCardCreateDrawer
                entry={currentEntry}
                isOpen={kanbanCardCreateDrawerVisible}
                onClose={() => setKanbanCardCreateDrawerVisible(false)}
              />
            </Flex>
          ) : null}

          {currentEntry?.kind === EntryKind.LEAD ? (
            <LeadTaskCreateDrawer
              entry={currentEntry}
              isOpen={leadTaskCreateVisible}
              onClose={() => setLeadTaskCreateVisible(false)}
            />
          ) : null}

          <LeadTaskUpdateDrawer
            leadTask={task as LeadTask}
            isOpen={leadTaskUpdateVisibile}
            onClose={onCloseLeadTaskUpdateReadDrawer}
          />

          <ContactTaskUpdateDrawer
            contactTask={task as ContactTask}
            isOpen={contactTaskUpdateVisible}
            onClose={onCloseContactTaskUpdateReadDrawer}
          />

          <TasksReadDrawer
            entry={currentEntry}
            isOpen={tasksReadVisible}
            onClose={() => setTasksReadVisible(false)}
          />

          <WarningModal
            isOpen={deleteTaskVisible}
            loading={deleteTaskLoading}
            onConfirm={deleteTask}
            onClose={() => setDeleteTaskVisible(false)}
            title={t('tasks.delete.warning.title', {
              task_name: truncateString(task?.name || '', 26),
            })}
            description={t('tasks.delete.warning.description')}
          />

          <WarningModal
            isOpen={taskDoneVisible}
            onClose={() => setTaskDoneVisible(false)}
            title={t('tasks.done.modal.title')}
            description={t('tasks.done.modal.description')}
            onConfirm={onTaskDone}
            loading={taskDoneLoading}
          />
        </Wrapper>
      </Flex>
    </FormProvider>
  );
};
