import { GetUserCurrentThresholdsDto } from '../../model/threshold/dto/GetUserCurrentThresholdsDto';
import { GetUserCurrentThresholdsResponseDto } from '../../model/threshold/dto/GetUserCurrentThresholdsResponseDto';
import { AtiraApi } from '../AxiosClient';

export class ThresholdAxios {
  static getThreshold(dto: GetUserCurrentThresholdsDto) {
    return AtiraApi.post<GetUserCurrentThresholdsResponseDto>(
      `/threshold/${dto.userId}`,
    ).then(({ data }) => data);
  }
}
