import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface StepContextProps {
  currentStep: number;
  onNext: () => void;
  onPrev: () => void;
  registerValidation: (step: number, validate: () => boolean) => void;
  onGoToStep: (step: number) => void;
  validations: Record<number, () => boolean>;
}

const StepContext = createContext<StepContextProps | undefined>(undefined);

export const useStepContext = () => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error('useStepContext must be used within a StepProvider');
  }
  return context;
};

export const StepProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [validations, setValidations] = useState<Record<number, () => boolean>>(
    {},
  );

  const onNext = useCallback(() => {
    if (validations[currentStep] && !validations[currentStep]()) {
      return;
    }
    setCurrentStep((prev) => prev + 1);

  }, [currentStep, validations]);

  const onPrev = () => setCurrentStep((prev) => prev - 1);

  const onGoToStep = (step: number) => setCurrentStep(step);

  const registerValidation = useCallback(
    (step: number, validate: () => boolean) => {
      setValidations((prev) => ({ ...prev, [step]: validate }));
    },
    [],
  );

  useEffect(() => {
    const outletWrapper = document.getElementById('outlet-wrapper')
    
    outletWrapper?.scrollTo(0, 0)
  }, [currentStep])

  return (
    <StepContext.Provider
      value={{
        currentStep,
        onNext,
        onPrev,
        registerValidation,
        onGoToStep,
        validations,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
