export enum TitleAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum LabelDirection {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}
